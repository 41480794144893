import React from "react";
import styles from "scss/components/promotion.module.scss";
import {useSelector} from "react-redux";

const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};


// const text ={border: "1px solid red" , width:"800px"}
const Promotion = ({}) => {
    const isMobile = useSelector((state) => state.isMobile);

    return(
        <>
            <div className={styles.promotion}>
                <div className={styles.promtInner}>
                    <img
                        src={require("assets/img/Landing/promotion1.png")}
                        alt="promotion"
                    />
                </div>
                <div className={styles.ld_btn}>
                    <ul>
                        <li>
                            <button className={styles.apply}
                                    onClick={() => {
                                        openInNewTab("https://exitomakers.com/exitoapp/?idx=68");
                                    }}
                                    type="button"
                            >
                               신청하기
                            </button>
                        </li>

                    </ul>
                </div>
                <div className={styles.promtInner}>
                    <img
                        src={require("assets/img/Landing/promotion2.png")}
                        alt="promotion"
                    />
                </div>
                <div className={styles.ld_btn}>
                    <ul>
                        <li>
                            <button className={styles.apply}
                                    onClick={() => {
                                        openInNewTab("https://exitomakers.com/exitoapp/?idx=68");
                                    }}
                                    type="button"
                            >
                                신청하기
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            {isMobile && (
                <style>
                    {
                        "\
                              #wrap > div[class^='Header']{\
                                display:flex;\
                              }\
                            "
                    }
                </style>
            )}
        </>
        );
    };

export default Promotion;