import React, { useState, useEffect } from "react";
import HashtagStyle from "scss/components/Hashtag.module.scss";
import * as api from "../../lib/api";
import event from "../home/Event";
import {Link, useNavigate, useParams} from "react-router-dom";



const Hashtag = ({
                     searchValSet,
                     setSearchValSet,
                     Energy,
                     setEnergy,
                     Environment,
                     setEnvironment,
                     Manufacturing,
                     setManufacturing,
                     factory,
                     setfactory,
                     ESG,
                     setESG,
                     RD,
                     setRD,
                     ICT,
                     setICT,
                     Bio,
                     setBio,
                     hashState,
                     setHashState,
                 }) => {



    // const hashArr = ["에너지", "환경", "제조", "스마트공장", "ESG" , "ICT", "바이오"];

    const [Click, setClick] = useState('');
    const [url, setUrl] = useState("");
    const navigate = useNavigate();
    const {corp_nm} = useParams();



    return(
        <>
            {hashState &&
                <div className={HashtagStyle.hashtagWrap}>
                    <ul className={HashtagStyle.hashtagCon}>


                        {/*{hashArr.map((word, idx) => {*/}
                        {/*    return(*/}
                        {/*        <li key={idx}*/}
                        {/*            */}
                        {/*            onClick={() => {*/}
                        {/*                setSearchValSet('')*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=${word}`}>*/}
                        {/*                #{word}*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*        )*/}
                        {/*})}*/}


                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=에너지`}
                                  style={{ color: Energy ? "#0B8EC5" : "#000" }}
                                  value={Energy}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(true)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(false)
                                      setICT(false)
                                      setBio(false)

                                      setClick('에너지')
                                  }}
                            >
                                #에너지
                            </Link>
                        </li>


                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=환경`}
                                  style={{ color: Environment ? "#0B8EC5" : "#000" }}
                                  value={Environment}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(true)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(false)
                                      setICT(false)
                                      setBio(false)

                                      setClick('환경')
                                  }}
                            >
                                #환경
                            </Link>
                        </li>



                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=제조`}
                                  style={{ color: Manufacturing ? "#0B8EC5" : "#000" }}
                                  value={Manufacturing}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(true)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(false)
                                      setICT(false)
                                      setBio(false)

                                      setClick('제조')
                                  }}
                            >
                                #제조
                            </Link>
                        </li>

                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=스마트공장`}
                                  style={{ color: factory ? "#0B8EC5" : "#000" }}
                                  value={factory}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(true)
                                      setESG(false)
                                      setRD(false)
                                      setICT(false)
                                      setBio(false)

                                      setClick('스마트공장')
                                  }}
                            >
                                #스마트공장
                            </Link>
                        </li>


                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=ESG`}
                                  style={{ color: ESG ? "#0B8EC5" : "#000" }}
                                  value={ESG}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(true)
                                      setRD(false)
                                      setICT(false)
                                      setBio(false)

                                      setClick('ESG')
                                  }}
                            >
                                #ESG
                            </Link>
                        </li>


                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=R&D`}
                                  style={{ color: RD ? "#0B8EC5" : "#000" }}
                                  value={RD}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(true)
                                      setICT(false)
                                      setBio(false)

                                      setClick('R&D')
                                  }}
                            >
                                #R&D
                            </Link>
                        </li>

                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=ICT`}
                                  style={{ color: ICT ? "#0B8EC5" : "#000" }}
                                  value={ICT}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(false)
                                      setICT(true)
                                      setBio(false)

                                      setClick('ICT')
                                  }}
                            >
                                #ICT
                            </Link>
                        </li>


                        <li>
                            <Link to={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=바이오`}
                                  style={{ color: Bio ? "#0B8EC5" : "#000" }}
                                  value={Bio}
                                  onClick={() => {

                                      setSearchValSet('')

                                      setEnergy(false)
                                      setEnvironment(false)
                                      setManufacturing(false)
                                      setfactory(false)
                                      setESG(false)
                                      setRD(false)
                                      setICT(false)
                                      setBio(true)

                                      setClick('바이오')
                                  }}
                            >
                                #바이오
                            </Link>
                        </li>



                    </ul>
                </div>
            }

        </>
    )
};


export default Hashtag;