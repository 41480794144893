import React, { useState, useEffect } from "react";
import styles from "scss/pages/Community.module.scss";
import MyPostItem from "./MyPostItem";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination";
import * as api from '../../lib/api';

const MyPost = ({ page, postLimit }) => {
  const searchParams = new URLSearchParams(window.location);
  const [posts, setPosts] = useState([]);
  const [modalOn] = useState({ current: false, type: "", id: "" });
  const [controlBox, setControlBox] = useState({ id: "" });
  const userInfo = useSelector((state) => state.userInfo);
  const setScrollStorage = (value) => {
    sessionStorage.setItem("cOffset", value);
  };
  const getMyPost = () => {
    api.getMyComm(userInfo.id).then((res) => {
      setPosts(res.data);
    })
  };

  useEffect(() => {
    getMyPost();
  }, [userInfo]);

  return (
      <>
        <div className={styles.listCont}>
          {posts.length > 0 ? (
              <ul className="commonListItemWrap">
                {posts
                    .slice((page - 1) * postLimit, page * postLimit)
                    .map((post, i) => {
                      let modalInform;
                      modalOn.id == post.id
                          ? (modalInform = modalOn)
                          : (modalInform = {});
                      let controlBoxOpen;
                      controlBox.id == post.id
                          ? (controlBoxOpen = true)
                          : (controlBoxOpen = false);
                      return (
                          <MyPostItem
                              key={i}
                              setScrollStorage={setScrollStorage}
                              post={post}
                              controlBox={controlBox}
                              setControlBox={setControlBox}
                              controlBoxOpen={controlBoxOpen}
                              getMyPost={getMyPost}
                          />
                      );
                    })}
              </ul>
          ) : (
              <div className="empty">
                <p className="empty_tit">커뮤니티 게시글이 없습니다.</p>
                <p className="empty_para">첫번째 게시글을 작성해보세요!</p>
              </div>
          )}
        </div>
        <Pagination
            total={posts.length}
            postLimit={postLimit}
            numLimit={5}
            page={parseInt(page)}
            searchParams={searchParams}
        />
      </>
  );
};
export default MyPost;
