import React, { useEffect, useState } from 'react';
import FirstLoginCompanyStyle from "scss/components/FirstLoginCompany.module.scss";
import * as api from "../../lib/api";
import {useDispatch, useSelector} from "react-redux";
import MainFirstConditions from "./MainFirstConditions";
import {
    marketingChkY, modalOverflow,
    setAppleInform,
    setCompanyInfo,
    setKakaoInform,
    setMarketingCheck,
    setUserInfo,
    signIn
} from "../../redux/store";



const FirstLoginCompany = ({
                               FirstLoginCompanyM,
                               setFirstLoginCompanyM,
                               FirstLoginPersonM,
                               setFirstLoginPersonM,
                               conditions,
                               setConditions,
                               phone,
                               nickname,

                               setLastCheck,
                               kakaoInform,
                               appleInform,
                               isFirst
                          }) => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const supportItem = useSelector((state) => state.supportItem);
    const supportItemReady = useSelector((state) => state.supportItemReady);
    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const isChkMarketing = useSelector((state) => state.isChkMarketing);
    const [allAgree , setAllAgree] = useState(false); //전체동의
    const osCheck = useSelector((state) => state.osCheck);

    //style
    const validation = {color:"#30d6c2" , fontSize: '14px', marginTop:'5px'}
    const reserveVStyle = {color:'#FF0000', marginLeft:'30px', fontSize:'14px',lineHeight:'24px'}
    const flex = {display:'flex'}

    const [guArr, setGuArr] = useState([]);
    const [companyLocDo, setCompanyLocDo] = useState("서울특별시"); // 기업소재지(도)
    const [companyLocGu, setCompanyLocGu] = useState("강남구"); // 기업소재지(시, 구)

    //value값
    const [Entrepreneur , setEntrepreneur] = useState('사업자');
    const [reserve , setReserve] = useState('예비창업자');
    const [Form , setForm] = useState('사업자');
    const [CompanyName , setCompanyName] = useState(''); // 기업명
    const [Representative , setRepresentative] = useState(''); // 대표자명
    const [occupation , setOccupation] = useState('제조업'); // 업태
    const [category , setCategory] = useState(''); // 제품/서비스(업종)
    const [Year , setYear] = useState(''); // 기업설립년도

    //벨리데이션
    const [CompanyNameV , setCompanyNameV] = useState(false);// 기업명
    const [RepresentativeV , setRepresentativeV] = useState(false); //대표자명
    const [categoryV , setCategoryV] = useState(false); // 제품,서비스(업종)
    const [YearV , setYearV] = useState(false); // 기업설립년도
    const [reserveV , setReserveV] = useState(false);


    const [requireObj, setRequireObj] = useState({
        servicePush : false,
        collectPush : false
    })

    const [alarmObj, setAlarmObj] = useState({
        marketingPush: false, // 마케팅 정보 수신 동의
        personalInfoAgree: false, // 개인 및 기업정보 제 3자 제공 동의
    });

    let headers = {};
    if(isFirst){
        if (kakaoInform.state) {
            headers = { ...kakaoInform.datas };
            sessionStorage.setItem("oAuthType", "kakao");
        }
        if (appleInform.state) {
            headers = { userid: appleInform.datas.userid };
            sessionStorage.setItem("oAuthType", "apple");
        }
    }

    useEffect(() => {
        if(supportItemReady){
            const e = {target : {value : "서울특별시,01"}};
            doChange(e);
        }
    }, [supportItemReady]);



    useEffect(() => {
        setAllAgree(!Object.values(alarmObj).some((item) => item == false) && !Object.values(requireObj).some((item) => item == false));
    }, [alarmObj, requireObj]);

    const checkAllChange = (e) => {
        const { target: { checked } } = e;
        let copy = { ...alarmObj };
        let copy2 = {...requireObj};
        if (checked) {
            for (let key in copy) copy[key] = true;
            for (let key in copy2) copy2[key] = true;
        } else {
            for (let key in copy) copy[key] = false;
            for (let key in copy2) copy2[key] = false;

        }
        setAlarmObj(copy);
        setRequireObj(copy2);

        setAllAgree(checked);
    };


    const exitoStartBtn = async () => {

        if(CompanyName != '' && Representative != '' && category != '' && Year != '' && requireObj.servicePush == true && requireObj.collectPush == true){

            //담당자정보(전화번호,닉네임) API
            let paramUrlPersonInfo = "";
            let paramsPersonInfo = {
                usernickname : nickname,
                userhp : phone,
                isNew : true
            }

            for (let key in paramsPersonInfo) {
                if (paramsPersonInfo[key] == "" || paramsPersonInfo[key] == undefined || paramsPersonInfo[key] == null) continue;
                paramUrlPersonInfo += `${key}=${paramsPersonInfo[key]}&`;
            }


            //약관동의(필수값) API
            var push = {
                marketingPush: boolToYN(alarmObj.marketingPush),
                personalInfoAgree: boolToYN(alarmObj.personalInfoAgree)
            }


            //기업정보 API
            let paramUrl = "";
            let params = {
                cpType : Form , //사업자형태
                companyName : CompanyName, //기업명
                repName : Representative, //대표자명
                business : occupation, //업태
                product : category, //제품/서비스(업종)
                companyYear : Year, //기업설립연도
                companyLocDo : companyLocDo, // 기업소재지(도)
                companyLocGu: companyLocGu, // 기업소재지(시, 구)
                updtType :'bizInfo'
            }



            // for (let key in params) {
            //     if (params[key] == "" || params[key] == undefined || params[key] == null) continue;
            //     paramUrl += `${key}=${params[key]}&`;
            // }



            for (let key in params) {
                if (params[key] == "" || params[key] == undefined || params[key] == null) continue;
                paramUrl += `${key}=${params[key]}&`;
            }


            if(isFirst){
                if (kakaoInform.state || appleInform.state) {
                    headers.isfirst = 'true';
                    headers.mos = osCheck === '' ? 'WEB' : osCheck;
                }

                headers.username = encodeURI(nickname);

                api.kakaoLogin(headers).then(() => {
                    api.updateUserInfo(paramUrlPersonInfo, headers.userid).then(() => {
                        api.getUserInfo(headers.userid).then((res4) => {
                            const data = res4.data;
                            const userId = data.id;
                            sessionStorage.setItem("userId", userId);
                            dispatch(signIn(data));
                            dispatch(setUserInfo(data));
                        }).then(() => {


                            // let data = {
                            //     spReceivePush: "Y",
                            //     spKeywordPush: "Y",
                            //     spBookmarkPush: "Y",
                            //     spRecommentPush: "Y",
                            //     spCommentlikePush: "Y",
                            //     spContentCommentPush: "Y",
                            //     spCommunityCommentlikePush: "Y",
                            //     spCommunityRecommentPush: "Y",
                            //     marketingPush: boolToYN(alarmObj.marketingPush),
                            //     personalInfoAgree: boolToYN(alarmObj.personalInfoAgree),
                            // }


                            push.spReceivePush = "Y";
                            push.spKeywordPush = "Y";
                            push.spBookmarkPush = "Y";
                            push.spRecommentPush = "Y";
                            push.spCommentlikePush = "Y";
                            push.spContentCommentPush = "Y";
                            push.spCommunityCommentlikePush = "Y";
                            push.spCommunityRecommentPush = "Y";
                            push.spCommunityAdminPush = "Y";  //커뮤니티 관리자 알림
                            push.spSurveyPush = "Y";  //설문조사 참여 알림

                            for (let key in push){
                                if(push.marketingPush == "Y"){
                                    if(key != 'personalInfoAgree') push[key] = push.marketingPush == "Y" ? "Y" : "N";
                                }
                            }

                            api.updatePushSetting(push, headers.userid).then(() => {
                                // api.updateCompanyInfo(paramUrl, headers.userid).then(() => {
                                api.updateCompanyInfo(params, headers.userid).then(() => {
                                    if (kakaoInform.state) dispatch(setKakaoInform({ state: false, datas: {} }));
                                    if (appleInform.state) dispatch(setAppleInform({ state: false, datas: {} }));

                                    if(isFirst) setLastCheck(false);

                                    setCompanyNameV(false)
                                    setRepresentativeV(false)
                                    setCategoryV(false)
                                    setYearV(false)
                                    setFirstLoginPersonM(false);
                                    setFirstLoginCompanyM(false);
                                    setConditions(false);


                                    getUserCompany();
                                    getUser(headers.userid);
                                    dispatch(setMarketingCheck(false));
                                    dispatch(marketingChkY());

                                    alert('정보를 입력해 주셔서 감사합니다. 더욱 편리한 서비스를 제공하겠습니다.')


                                })
                            })
                        })
                    });
                }).catch(err => console.log(err));
            }else{

                let params = {
                    cpType : Form , //사업자형태
                    companyName : CompanyName, //기업명
                    repName : Representative, //대표자명
                    business : occupation, //업태
                    product : category, //제품/서비스(업종)
                    companyYear : Year, //기업설립연도
                    companyLocDo : companyLocDo, // 기업소재지(도)
                    companyLocGu: companyLocGu, // 기업소재지(시, 구)
                    updtType :'bizInfo'
                }

                let res = await api.updateCompanyInfo(params, userInfo.id);
                let personInfo = await api.updateUserInfo(paramUrlPersonInfo, userInfo.id);


                let comRes = await api.updatePushSetting(push, userInfo.id);

                if(res.data == 'success' && personInfo.data == 'success' && comRes.data == 'success') {
                    //벨리데이션 끄기
                    setCompanyNameV(false)
                    setRepresentativeV(false)
                    setCategoryV(false)
                    setYearV(false)
                    setFirstLoginPersonM(false);
                    setFirstLoginCompanyM(false);
                    setConditions(false);

                    getUserCompany();
                    getUser(userInfo.id);
                    dispatch(setMarketingCheck(false));
                    dispatch(marketingChkY());

                    alert('정보를 입력해 주셔서 감사합니다. 더욱 편리한 서비스를 제공하겠습니다.')
                }
            }



        }else{

            if(!requireObj.servicePush || !requireObj.collectPush){
                alert('필수 약관 동의를 체크해주세요')
            }

            //기업명
            if(CompanyName == ''){
                setCompanyNameV(true)
            }else{
                setCompanyNameV(false)
            }

            //대표자명
            if(Representative == ''){
                setRepresentativeV(true)
            }else{
                setRepresentativeV(false)
            }

            // 제품,서비스(업종)
            if(category == ''){
                setCategoryV(true)
            }else{
                setCategoryV(false)
            }

            if(Year == ''){
                setYearV(true)
            }else{
                setYearV(false)
            }
        }
    };

    const boolToYN = (bool) => { return bool ? "Y" : "N"; }

    const getUser = async (id) => {
        let res = await api.getUserInfo(id);
        dispatch(setUserInfo(res.data));
    };

    const getUserCompany = async () => {
        let id = isFirst ? headers.userid : userInfo.id;
        let res = await api.getCompanyInfo(id);
        dispatch(setCompanyInfo(res.data));
    };






    // const doChange = (e) => {
    //
    //     const {target : {value}} = e;
    //
    //     let code_nm;
    //     let code;
    //
    //     if(value == null || value == undefined){
    //         code_nm = '서울특별시'
    //         code = '01'
    //     }else{
    //         code_nm = value.split(",")[0]
    //         code = value.split(",")[1]
    //     }
    //
    //     console.log('value>>>>',value)
    //     console.log('code_nm',code_nm)
    //     console.log('code',code)
    //
    //     let arr = supportItem.district_cd.filter((v,i) => v.etc_code1 == code);
    //     setGuArr(arr);
    //     setCompanyLocDo(code_nm)
    // }




    const doChange = (e) => {
        const {target : {value}} = e;
        let [code_nm, code] = value.split(",");

        let arr = supportItem.district_cd.filter((v,i) => v.etc_code1 == code);
        setGuArr(arr);
        setCompanyLocDo(code_nm)
    }



    const guChange = (e) => {
        const {target : {value}} = e;
        setCompanyLocGu(value);
    }

    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
            e.preventDefault();
        }
    }






    return (
        <>
            {FirstLoginCompanyM &&
                <div className={FirstLoginCompanyStyle.wrap}>
                    <div className={FirstLoginCompanyStyle.modal}>
                        <section className={FirstLoginCompanyStyle.top}>
                            <div>
                                <img
                                    src={require("assets/img/global/ico/ico_policy.png")}
                                    alt=""
                                />
                                <p>엑시토 서비스 이용을 위한 마지막 단계입니다</p>
                            </div>

                            <p>
                                <img
                                    className={FirstLoginCompanyStyle.X}
                                    src={require("assets/img/global/btn/btn_close_black.png")}
                                    alt="닫기"
                                    onClick={() => {
                                        if (window.confirm("정보 미입력 시 엑시토 서비스를 이용하실 수 없습니다. 창을 닫으시겠습니까?")) {
                                            setFirstLoginCompanyM(false);
                                            setFirstLoginPersonM(false);
                                            setConditions(false);
                                            dispatch(setMarketingCheck(false));
                                            if(isFirst) setLastCheck(false);
                                        }
                                    }}
                                />
                            </p>
                        </section>
                        <div className={FirstLoginCompanyStyle.contents}>
                            <h1>기업정보</h1>
                            <section className={FirstLoginCompanyStyle.con1}>
                                <p className={FirstLoginCompanyStyle.title}>사업자 형태</p>

                                <div style={flex}>
                                    <input
                                        className={FirstLoginCompanyStyle.radios}
                                        type="radio"
                                        name='Form'
                                        defaultChecked
                                        id='Entrepreneur'
                                        value={Entrepreneur}
                                        onChange={() => {
                                            setForm('사업자')
                                        }}
                                        onClick={() => {
                                            setReserveV(false)
                                        }}
                                    />
                                    <label htmlFor="Entrepreneur">사업자</label>
                                    <input
                                        className={FirstLoginCompanyStyle.radios}
                                        type="radio"
                                        name='Form'
                                        id='reserve'
                                        value={reserve}
                                        onChange={() => {
                                            setForm('예비창업자')
                                        }}
                                        onClick={() => {
                                            setReserveV(true)
                                        }}
                                    />
                                    <label htmlFor="reserve">예비창업자</label>
                                    {reserveV && <p style={reserveVStyle}>* 예정 정보를 입력하세요</p>}
                                </div>

                            </section>

                            <section className={FirstLoginCompanyStyle.con2}>
                                <div>
                                    <p className={FirstLoginCompanyStyle.title}>기업명</p>
                                    <input
                                        type="text"
                                        placeholder={'기업명을 입력해주세요'}
                                        value={CompanyName}
                                        onChange={(e) => {
                                            //한글만
                                            // const korReg = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
                                            // let v = e.currentTarget.value;
                                            // v = v.replace(korReg, '');
                                            // setCompanyName(v);

                                            setCompanyName(e.target.value)
                                        }}
                                    />
                                    {CompanyNameV && <p style={validation}>* 기업명을 입력해주세요</p>}
                                </div>
                                <div>
                                    <p className={FirstLoginCompanyStyle.title}>대표자명</p>
                                    <input
                                        type="text"
                                        placeholder={'대표자명을 입력해주세요'}
                                        value={Representative}
                                        onChange={(e) => {
                                            // setRepresentative(e.target.value)
                                            //한글+영어만
                                            const textReg = /[0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
                                            let v = e.currentTarget.value;
                                            v = v.replace(textReg, '');
                                            setRepresentative(v);
                                        }}
                                    />
                                    {RepresentativeV && <p style={validation}>* 대표자명을 입력해주세요</p>}
                                </div>
                            </section>

                            <section className={FirstLoginCompanyStyle.con3}>
                                <p className={FirstLoginCompanyStyle.title}>업태</p>
                                <select
                                    name="occupation"
                                    id="occupation"
                                    value={occupation}
                                    onChange={(e) => {
                                        setOccupation(e.target.value)
                                    }}
                                >
                                    <option value="제조업">제조업</option>
                                    <option value="정보통신업">정보통신업</option>
                                    <option value="교육서비스업">교육서비스업</option>
                                    <option value="숙박 및 음식점업">숙박 및 음식점업</option>
                                    <option value="도매 및 소매업">도매 및 소매업</option>
                                    <option value="부동산업">부동산업</option>
                                    <option value="금융 및 보험업">금융 및 보험업</option>
                                    <option value="수도,하수 및 폐기물 처리,원료 재생업">수도,하수 및 폐기물 처리,원료 재생업</option>
                                    <option value="농업,임업 및 어업">농업,임업 및 어업</option>
                                    <option value="운수 및 창고업">운수 및 창고업</option>
                                    <option value="전기,가스,증기 및 공기 조절 공급업">전기,가스,증기 및 공기 조절 공급업</option>
                                    <option value="서비스업">서비스업</option>
                                    <option value="건설업">건설업</option>
                                    <option value="기타">기타</option>
                                </select>
                            </section>

                            <section className={FirstLoginCompanyStyle.con4}>
                                <p className={FirstLoginCompanyStyle.title}>제품/서비스(업종)</p>
                                <input
                                    minlength = {2}
                                    type="text"
                                    placeholder={'Ex_응용 소프트웨어 개발 및 공급업'}
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value)
                                    }}
                                />
                                {categoryV && <p style={validation}>* 제품/서비스(업종) 입력해주세요</p>}
                            </section>
                            <section className={FirstLoginCompanyStyle.con5}>

                                <div className={FirstLoginCompanyStyle.content1}>
                                    <p className={FirstLoginCompanyStyle.title}>기업설립연도</p>
                                    <input
                                        onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength)
                                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                                        }}
                                        maxlength={4}
                                        type="number"
                                        placeholder={'Ex_2002'}
                                        value={Year}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => {
                                            setYear(e.target.value)
                                        }}
                                    />
                                    {YearV && <p style={validation}>* 기업설립년도를 입력해주세요</p>}
                                </div>

                                <div className={FirstLoginCompanyStyle.content2}>
                                    <p className={FirstLoginCompanyStyle.title}>기업 소재지</p>
                                    <div>
                                        <select onChange={doChange}>
                                            {supportItemReady && ( supportItem.city_cd.map((item,idx) => {
                                                return (
                                                    <option key={item.code}
                                                        value={`${item.code_nm},${item.code}`}
                                                        selected={companyLocDo == item.code_nm}
                                                    >
                                                        {item.code_nm}
                                                    </option>
                                                )
                                            }) ) }
                                        </select>
                                        <select onChange={guChange}>
                                            {supportItemReady && ( guArr.map((item,idx) => {
                                                return (
                                                    <option key={item.code}
                                                        value={item.code_nm}
                                                        selected={companyLocGu == item.code_nm}
                                                    >
                                                        {item.code_nm}
                                                    </option>
                                                )
                                            }) )}
                                        </select>
                                    </div>
                                </div>
                            </section>
                            <section className={FirstLoginCompanyStyle.Conditions}>
                                <div>
                                    <input
                                        type="checkbox"
                                        id='allAgree'
                                        onChange={checkAllChange}
                                        checked={allAgree}
                                    />
                                    <label htmlFor="allAgree">전체동의</label>
                                    <p
                                        onClick={() => {
                                            // setFirstLoginCompanyM(false);
                                            setConditions(true);
                                        }}
                                    >약관보기</p>
                                </div>
                                <p>서비스 이용약관(필수), 개인정보 수집 및 이용동의(필수), 마케팅정보수신동의(선택), 개인정보 및 기업정보 제 3자 제공 동의(선택)</p>
                            </section>
                        </div>

                        <div className={FirstLoginCompanyStyle.btns}>
                            <button
                                onClick={() => {
                                    setFirstLoginCompanyM(false)
                                    setFirstLoginPersonM(true)
                                }}
                            >이전</button>
                            <button
                                onClick={exitoStartBtn}
                            >엑시토 서비스 시작하기</button>
                        </div>
                    </div>

                </div>
            }
            {conditions && <MainFirstConditions
                conditions={conditions}
                setConditions={setConditions}
                FirstLoginCompanyM = {FirstLoginCompanyM}
                setFirstLoginCompanyM = {setFirstLoginCompanyM}
                FirstLoginPersonM = {FirstLoginPersonM}
                setFirstLoginPersonM = {setFirstLoginPersonM}

                allAgree={allAgree}
                setAllAgree={setAllAgree}
                requireObj={requireObj}
                setRequireObj={setRequireObj}
                alarmObj={alarmObj}
                setAlarmObj={setAlarmObj}
            />}
        </>
    );
};

export default FirstLoginCompany;
