import React, { useState, useEffect } from "react";
import styles from "scss/pages/MyPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {setCompanyInfo, setUserInfo} from "redux/store";
import * as api from '../../lib/api'
import FirstConditions from "../home/FirstConditions";
const MyCompany = () => {
  const dispatch = useDispatch();
  const [resize, setResize] = useState(window.innerWidth);
  const userCompany = useSelector((state) => state.userCompany);
  const supportItem = useSelector((state) => state.supportItem);
  const supportItemReady = useSelector((state) => state.supportItemReady);

  const [cpType, setCpType] = useState({value : ""}); // 사업자 형태
  const [companyName, setCompanyName] = useState(""); // 기업명
  const [ownerName, setOwnerName] = useState(""); // 대표자명
  const [business, setBusiness] = useState({value : ""}); //업태
  const [product, setProduct] = useState(""); // 제품/서비스
  const [companyYear, setCompanyYear] = useState(""); // 기업설립년도
  const [companyLocDo, setCompanyLocDo] = useState(""); // 기업소재지(도)
  const [companyLocGu, setCompanyLocGu] = useState(""); // 기업소재지(시, 구)
  const [userhp, setUserhp] = useState(""); //  핸드폰번호
  const [authEmail, setAuthEmail] = useState(""); // 회사 이메일



  //벨리데이션
  const [PhoneValidation, setPhoneValidation] = useState(false); //전화번호
  const [userhpValidation, setUserhpValidation] = useState(false); //기업명
  const [ownerNameValidation, setOwnerNameValidation] = useState(false); //대표자명
  const [productValidation, setProductValidation] = useState(false); // 제품,서비스(업종)
  const [companyYearValidation, setCompanyYearValidation] = useState(false); //기업설립년도





  const [companyNameV, setCompanyNameV] = useState(false);







  const [guArr, setGuArr] = useState([]);
  const [phone , setPhone] = useState('')
  const userInfo = useSelector((state) => state.userInfo);

  const [Conditions1 , setConditions1] = useState(false);

  const [allAgree , setAllAgree] = useState(false); //전체동의


  const [requireObj, setRequireObj] = useState({
    servicePush : true,
    collectPush : true
  })

  const [reserveV , setReserveV] = useState(false);
  const reserveVStyle = {color:'#FF0000', marginLeft:'30px', fontSize:'14px',lineHeight:'24px'}

  const [alarmObj, setAlarmObj] = useState({
    marketingPush: false, // 마케팅 정보 수신 동의
    personalInfoAgree: false, // 개인 및 기업정보 제 3자 제공 동의
  });





  console.log('companyName>>>>',companyName)
  console.log('product>>>>',product)
  console.log('companyYear>>>>',companyYear)






  useEffect(() => {
    if(supportItemReady){
      const e = {target : {value : "서울특별시,01"}};
      doChange(e);
    }
  }, [supportItemReady]);



  const [isMain, setIsMain] = useState(false); // 메인인지 확인



  const companySubmit = async () => {

    const number = document.getElementById('number').value

    // 전화번호 형식
    const phoneReg = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    const numberChecked = phoneReg.test(number)

    if(numberChecked == false){
      setPhoneValidation(true)
      return;
    }else{
      setPhoneValidation(false)
    }


    //핸드폰 특수문자 감지
    var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
    if(special_pattern.test(number) == true){
      setPhoneValidation(true)
      return;
    }else{
      setPhoneValidation(false)
    }


    if(userhp == null || companyName == null || ownerName == null || product == null || companyYear == null || userhp == '' || companyName == '' || ownerName == '' || product == '' || companyYear == ''){
      alert('회원정보를 입력해주세요')
      return false;
    }

      let paramUrl = "";
      const obj = {
        cpType: cpType.value,
        companyName: companyName,
        repName: ownerName,
        business : business.value,
        product : product,
        companyYear : companyYear,
        companyLocDo : companyLocDo,
        companyLocGu : companyLocGu,
        updtType :'bizInfo'
      };


      for (let key in obj) {
        if (obj[key] == "" || obj[key] == undefined || obj[key] == null) {
          continue;
        }
        paramUrl += `${key}=${obj[key]}&`;
      }

      //알림설정 업데이트
      let data = {
        marketingPush: boolToYN(alarmObj.marketingPush),
        personalInfoAgree: boolToYN(alarmObj.personalInfoAgree),
      }

      //정보동의 업데이트
      let pushRes = await api.updatePushSetting(data, userInfo.id);


      //기본정보 업데이트
      let params = "userhp=" + userhp;
      let userRes = await api.updateUserInfo(params, userInfo.id);


      //기업정보 업데이트
      let comRes = await api.updateCompanyInfo(obj, userInfo.id);
      // let comRes = await api.updateCompanyInfo(paramUrl, userInfo.id);



    // var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    // const Checked = reg.test(companyName)

    // console.log('Checked>>>>>vbbbbbbbbb',Checked)



      if(pushRes.data == 'success' && userRes.data == 'success' && comRes.data == 'success'){
        getUserCompany();
        // navigate("/support/supportList");
        getUser(userInfo.id);
        getPolicy();
        alert('회원정보가 저장되었습니다.');
      }

  };



  const getUser = async (id) => {
    let res = await api.getUserInfo(id);
    dispatch(setUserInfo(res.data));
  };

  const getUserCompany = async () => {
    let res = await api.getCompanyInfo(userInfo.id);
    dispatch(setCompanyInfo(res.data));
  };

  const getPolicy = () => {
    api.getPushSetting(userInfo.id).then(res => {
      const resObj = res.data;
      let currentObj = { ...alarmObj };
      currentObj.personalInfoAgree = YNToBool(resObj.personal_info_agree);
      currentObj.marketingPush = YNToBool(resObj.new_marketing_push);
      setAlarmObj(currentObj);

    }).catch(err => console.log(err));
  };



  const chkChange = (e) => {
    const { target: { value, name } } = e;
    let copy = { ...alarmObj };
    name == 'personalInfoAgree' ? copy.personalInfoAgree = JSON.parse(value) : copy.marketingPush = JSON.parse(value);
    setAlarmObj(copy);
  };

  const checkAllChange = (e) => {
    const { target: { checked } } = e;

    let copy = { ...alarmObj };
    if (checked) {
      for (let key in copy) copy[key] = true;
    } else {
      for (let key in copy) copy[key] = false;
    }
    setAlarmObj(copy);
    setAllAgree(checked);
  };

  const YNToBool = (YN) => { return YN == "Y" ? true : false; }
  const boolToYN = (bool) => { return bool ? "Y" : "N"; }


  const checkPhonenumber = (e) => {
    // '-' 입력 시
    // var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/

    // 숫자만 입력시
    var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/


    setPhone(regExp.test(e.target.value));
    setUserhp(e.currentTarget.value);
    // console.log('핸드폰 유효성검사>>>>>> ',phone )
  }




  useEffect(() => {
    setCpType({value : userCompany.cp_type});
    setCompanyName(userCompany.company_name);
    setOwnerName(userCompany.rep_name);
    setBusiness({value:userCompany.business});
    setProduct(userCompany.product);
    setCompanyYear(userCompany.company_year);
    setCompanyLocDo(userCompany.company_loc_do);


    // console.log('그럼이건뭔데',companyLocGu)


    if(supportItemReady){
      // console.log("!");
      let cityObj = supportItem.city_cd.filter(v => v.code_nm == userCompany.company_loc_do)[0];
      if(cityObj != undefined){
        let e = {target : {value : `${cityObj.code_nm},${cityObj.code}`}}
        doChange(e);
      }
    }

    setCompanyLocGu(userCompany.company_loc_gu);

  }, [userCompany]);

  // console.log('userCompany>>>>>',userCompany)



  useEffect(() => {
    if (userInfo.userhp != null) setUserhp(userInfo.userhp);
    if (userInfo.auth_email != null) setAuthEmail(userInfo.auth_email);

    getPolicy();
  }, [userInfo]);

  useEffect(() => {
    setAllAgree(!Object.values(alarmObj).some((item) => item == false));
  }, [alarmObj]);

  const cpTypeList = [
    {value : "사업자"},
    {value : "예비창업자"}
  ]

  const policyList = [
    {value : true},
    {value : false}
  ]

  const businessList = [
    {value : "제조업"},
    {value : "정보통신업"},
    {value : "교육서비스업"},
    {value : "숙박 및 음식점업"},
    {value : "도매 및 소매업"},
    {value : "부동산업"},
    {value : "금융 및 보험업"},
    {value : "수도,하수 및 폐기물 처리,원료 재생업"},
    {value : "농업,임업 및 어업"},
    {value : "운수 및 창고업"},
    {value : "전기,가스,증기 및 공기 조절 공급업"},
    {value : "서비스업"},
    {value : "건설업"},
    {value : "기타"}
  ]

  const cpChange = (e) => {
    const {target : {value}} = e;
    value == '예비창업자' ? setReserveV(true) :setReserveV(false);
    setCpType({ value: value });
  }
  const bizChange = (e) => {
    const {target : {value}} = e;
    setBusiness({value:value});
  }





  const doChange = (e) => {
    const {target : {value}} = e;
    let [code_nm, code] = value.split(",");
    let arr = supportItem.district_cd.filter((v,i) => v.etc_code1 == code);

    setGuArr(arr);
    setCompanyLocDo(code_nm);

    setCompanyLocGu(arr[0].code_nm)

  }






  const guChange = (e) => {

    const {target : {value}} = e;

    // console.log('진짜 뭔디>>>',value)

    setCompanyLocGu(value);

    // console.log('??????????????>>>>',companyLocGu)
  }


  // console.log('제대로나오나??',companyLocDo,'',companyLocGu)



  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
      <>
        <div className={styles.myPageForm}>
          <div className={styles.conWrap}>

            <div className={styles.conLeft}>
              <h1>담당자 정보</h1>
              <section className={styles.LeftCon1}>
                <h3>회사 이메일
                  <span className={styles.validation}>*이메일은 수정이 불가능합니다.</span>
                </h3>
                <input type="text" placeholder={'이메일을 입력해주세요'} value={authEmail} readOnly/>
              </section>

              <section className={styles.LeftCon2}>
                <h3>전화번호
                  {PhoneValidation && <span className={styles.validation}>*전화번호를 정확히 입력해주세요.</span>}
                </h3>
                <input
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    maxlength={11}
                    id='number'
                    type="number"
                    placeholder={'(-)없이 숫자만 입력'}
                    value={userhp || ''}
                    // onChange={(e) => {
                    //   //전화번호
                    //   const phoneReg = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
                    //   let v = e.currentTarget.value;
                    //   setUserhp(v.replace(phoneReg, ''));
                    //   // setUserhp(e.currentTarget.value);
                    // }}

                    onChange={(e) => {
                      //전화번호
                      setUserhp(e.target.value);
                    }}

                />
              </section>

              {resize == undefined || resize > 1023 ?
                  <section className={styles.LeftCon3}>
                    <div className={styles.LeftCon3Div1}>
                      <div>
                        <input
                            type="checkbox"
                            id='allAgree'
                            onChange={checkAllChange}
                            checked={allAgree}
                        />
                        <label htmlFor="allAgree">약관 전체 동의</label>
                      </div>
                      <p
                          onClick={() => {
                            setConditions1(true)
                          }}
                      >약관보기</p>
                    </div>

                    <div className={styles.LeftCon3Div2}>
                      <section>
                        <p>마케팅 정보 수신 동의(선택)</p>
                        <div>
                          {policyList.map((v,i) => {
                            const num = ("00" + i).slice(-2);
                            return (
                                <section key={i}>
                                  <label htmlFor={`marketing_${num}`}>
                                    <input
                                        type="radio"
                                        value={v.value}
                                        name="marketingPush"
                                        id={`marketing_${num}`}
                                        onChange={chkChange}
                                        checked={alarmObj.marketingPush == v.value}
                                    />
                                    <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                  </label>
                                </section>
                            )
                          })}
                        </div>
                      </section>
                      <p>*마케팅정보수신동의 시, 엑시토에서 제공하는 무료 특강 안내, 지원사업 안내, 금액적 혜택 등 다양한 정보를 편하게 받아보실 수 있습니다.</p>
                    </div>

                    <div className={styles.LeftCon3Div3}>
                      <p>개인 및 기업정보 제 3자 제공 동의(선택)</p>
                      <div>
                        {policyList.map((v,i) => {
                          const num = ("00" + i).slice(-2);
                          return (

                              <label key={i}  htmlFor={`personal_${num}`}>
                                <input
                                    type="radio"
                                    value={v.value}
                                    id={`personal_${num}`}
                                    name="personalInfoAgree"
                                    onChange={chkChange}
                                    checked={alarmObj.personalInfoAgree == v.value}
                                />
                                <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                              </label>
                          )
                        })}
                      </div>
                    </div>
                  </section>
                  : null}
            </div>
            <div className={styles.conRight}>
              <h1>기업정보</h1>
              <section className={styles.Entrepreneur}>
                <h3>사업자 형태</h3>




                <div style={{display:'flex'}}>
                  {cpTypeList.map((v,i) => {
                    const num = ("00" + i).slice(-2);
                    return (
                        <label key={i} htmlFor={`cpType_${num}`}>
                          <input
                              type="radio"
                              value={v.value}
                              name="cpType"
                              id={`cpType_${num}`}
                              onChange={cpChange}
                              checked={cpType.value == v.value}
                          />
                          <span>{v.value}</span>
                        </label>
                    )
                  })}
                  {reserveV && <p style={reserveVStyle}>* 예정 정보를 입력하세요</p>}
                </div>



              </section>

              <section className={styles.con1}>
                <div>
                  <h3>기업명{userhpValidation && <span className={styles.validation}>*기업명을 입력해주세요</span>}</h3>
                  <input
                      type="text"
                      value={companyName || ''}
                      placeholder={'주식회사 씨티엔에스'}
                      onChange={(e) => {

                        // //한글만
                        // const korReg = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
                        // let v = e.currentTarget.value;
                        // v = v.replace(korReg, '');
                        // setCompanyName(v);

                        // setCompanyName(JSON.parse(e.target.value))
                        setCompanyName(e.target.value)

                      }}
                  />
                  {companyNameV && <p>*특수문자는 넣을 수 없습니다</p>}
                </div>
                <div>
                  <h3>대표자명{ownerNameValidation && <span className={styles.validation}>*대표자명을 입력해주세요</span>}</h3>
                  <input
                      type="text"
                      value={ownerName || ''}
                      placeholder={'홍길동'}
                      onChange={(e) => {
                        //한글+영어만
                        const textReg = /[0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;
                        let v = e.currentTarget.value;
                        v = v.replace(textReg, '');
                        setOwnerName(v);
                      }}
                  />
                </div>
              </section>

              <section className={styles.con2}>
                <h3>업태</h3>
                <select onChange={bizChange}>
                  {businessList.map((v,i) => {
                    return(
                        <option
                            value={v.value}
                            selected={business.value == v.value}
                        >
                          {v.value}
                        </option>
                    )
                  })}
                </select>
              </section>

              <section className={styles.con3}>
                <h3>제품/서비스(업종){productValidation && <span className={styles.validation}>*제품/서비스(업종)을 입력해주세요</span>}</h3>
                <input
                    type="text"
                    minLength={2}
                    placeholder={'배터리팩 제조'}
                    value={product || ''}
                    onChange={(e) => {
                      setProduct(e.currentTarget.value);
                    }}
                />
              </section>

              <section className={styles.con4}>
                <div className={styles.contents1}>
                  <h3>기업설립년도{companyYearValidation && <span className={styles.validation}>*기업설립년도를 입력해주세요</span>}</h3>
                  <input
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      maxlength={4}
                      type="number"
                      placeholder={'2017'}
                      value={companyYear || ''}
                      onChange={(e) => {
                        setCompanyYear(e.currentTarget.value);
                      }}
                  />
                </div>
                <div className={styles.contents2}>
                  <h3>기업 소재지</h3>
                  <div>


                    <select
                        onChange={(e) => {
                          doChange(e)
                        }}
                    >
                      {supportItemReady && ( supportItem.city_cd.map((item,idx) => {
                        return (
                            <option key={item.code}
                                value={`${item.code_nm},${item.code}`}
                                selected={companyLocDo == item.code_nm}
                            >
                              {item.code_nm}
                            </option>
                        )
                      }) )}
                    </select>
                    <select onChange={guChange}>
                      {supportItemReady && ( guArr.map((item,idx) => {
                        return (
                            <option key={item.code}
                                value={item.code_nm}
                                selected={companyLocGu == item.code_nm}
                            >
                              {item.code_nm}
                            </option>
                        )
                      }))}
                    </select>



                  </div>

                </div>
              </section>

            </div>


            {/*반응형*/}
            {resize <= 1023 &&
                <section className={styles.LeftCon3}>
                  <div className={styles.LeftCon3Div1}>
                    <div>
                      <input
                          type="checkbox"
                          id='checkAll'
                          onChange={checkAllChange}
                          checked={allAgree}
                      />
                      <label htmlFor="checkAll">약관 전체 동의</label>
                    </div>
                    <p
                        onClick={() => {
                          setConditions1(true)
                        }}
                    >약관보기</p>
                  </div>

                  <div className={styles.LeftCon3Div2}>
                    <section>
                      <p>마케팅 정보 수신 동의(선택)</p>
                      <div>
                        {policyList.map((v,i) => {
                          const num = ("00" + i).slice(-2);
                          return (
                              <section key={i}>
                                <label htmlFor={`marketing_${num}`}>
                                  <input
                                      type="radio"
                                      value={v.value}
                                      name="marketingPush"
                                      id={`marketing_${num}`}
                                      onChange={chkChange}
                                      checked={alarmObj.marketingPush == v.value}
                                  />
                                  <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                </label>
                              </section>
                          )
                        })}
                      </div>
                    </section>
                  </div>

                  <div className={styles.LeftCon3Div3}>
                    <p>개인 및 기업정보 제 3자 제공 동의(선택)</p>
                    <div>
                      {policyList.map((v,i) => {
                        const num = ("00" + i).slice(-2);
                        return (
                            <section>
                              <label key={i}  htmlFor={`personal_${num}`}>
                                <input
                                    type="radio"
                                    value={v.value}
                                    id={`personal_${num}`}
                                    name="personalInfoAgree"
                                    onChange={chkChange}
                                    checked={alarmObj.personalInfoAgree == v.value}
                                />
                                <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                              </label>
                            </section>
                        )
                      })}
                    </div>
                  </div>
                </section>
            }
            {Conditions1 &&
                <FirstConditions
                    Conditions1={Conditions1}
                    setConditions={setConditions1}
                    isMain={isMain}

                    requireObj={requireObj}
                    setRequireObj={setRequireObj}
                    allAgree={allAgree}
                    setAllAgree={setAllAgree}
                    alarmObj={alarmObj}
                    setAlarmObj={setAlarmObj}
                />}
          </div>

          <div className={styles.btnDiv}>
            <button className={styles.btn}  onClick={companySubmit}>회원정보 저장하기</button>
          </div>


        </div>

      </>

  );
};
export default MyCompany;
