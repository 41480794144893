import { useEffect } from "react";
import { ReactComponent as AppleLogo } from "assets/img/global/ico/ico_apple_black.svg";
import {
  APPLE_CLIENT_ID,
  APPLE_REDIRECT_URI,
  APPLE_RESPONSE_TYPE,
  APPLE_RESPONSE_MODE,
  APPLE_STATE, APPLE_SCOPE,
} from "pages/login/LoginData";
import {useDispatch, useSelector} from "react-redux";
import {setOsCheck} from "../../redux/store";
const AppleLoginBtn = () => {

  const isWebApp = useSelector((state) => state.isWebApp);
  const dispatch = useDispatch();

  const btnClick = () => {
    const config = {
      client_id: APPLE_CLIENT_ID,
      redirect_uri: APPLE_REDIRECT_URI,
      response_type: APPLE_RESPONSE_TYPE,
      state: APPLE_STATE,
      response_mode: APPLE_RESPONSE_MODE,
      scope : APPLE_SCOPE
    };

    const queryString = Object.entries(config)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if(isWebApp){
        if (varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 || varUA.indexOf("mac") > -1 ||  varUA.indexOf("ios") > -1 ) {
          if (window && window.flutter_inappwebview) window.flutter_inappwebview.callHandler("loginF", "apple", varUA.indexOf("ipad"));
        }else{
          dispatch(setOsCheck('Android'));
          window.location = `https://appleid.apple.com/auth/authorize?${queryString}`;
        }
    }else{
      window.location = `https://appleid.apple.com/auth/authorize?${queryString}`;
    }

  };
  useEffect(() => {
    sessionStorage.setItem("appleRedirectPath", window.location.pathname);
  }, []);
  return (
    <>
      <button onClick={btnClick} className="btnLogin btnAppleLogin">
        <AppleLogo width="20" height="100%" fill="#fff" />
        <span>Apple로 로그인</span>
      </button>
    </>
  );
};

export default AppleLoginBtn;
