import React from "react";
import axios from "axios";
import styles from "scss/pages/Home.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import HomeCommunityItem from "./HomeCommunityItem";
import { useSelector } from "react-redux";
import * as api from '../../lib/api';

const HomeCommunity = ({}) => {

  const [community, setCommunity] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);
  let cancel;
  const CancelToken = axios.CancelToken;

  const getCommunity = () => {
    api.getCommPopular(userInfo.id ?? "", new CancelToken(function executor(c) { cancel = c })).then((res) => {
      setCommunity(res.data);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getCommunity();
//    return () => { cancel();  };
  }, []);

  return (
    <>
      <div className={styles.HomeCommunity}>
          <div className={styles.contArea}>
            <Swiper
              direction={"vertical"}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              className={styles.mySwiper}
              modules={[Autoplay]}
            >
              {community.map((item, i) => {
                return (
                  <SwiperSlide className={styles.swiperSlide} key={item.id}>
                    <HomeCommunityItem item={item} styles={styles}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
    </>
  );
};
export default HomeCommunity;
