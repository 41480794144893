import React from "react";
import styles from "scss/pages/Support.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SupportRecent = ({ savedBook }) => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const navigate = useNavigate();

  const openInNewTab = (url, idx) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };


    function addComma(numb) {
        return numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



  return (
      <div className={styles.SupportRecent}>
        <>
          <h4>최근 본 지원사업</h4>
          {isLoggedIn ? (
              <>
                <ul>
                  {savedBook.map((item, idx) => {
                    const offset = 1000 * 60 * 60 * 9;
                    const timeStamp = new Date(item.si_end_dt - offset);
                    const YYMMDD = timeStamp.toISOString().split("T")[0].split("-");
                    const MM = YYMMDD[1];
                    const DD = YYMMDD[2];
                    const week = ["일", "월", "화", "수", "목", "금", "토"];
                    const day = week[timeStamp.getDay()];
                    const endTime = `${MM}.${DD} (${day}) 마감`;
                      let supportFund;
                      let price;



                    // console.log('item>>>',item)
                    //   console.log('total_cost>>>>',item.total_cost)

                      // 총 사업비(total_cost)
                      if(item.total_cost == undefined || item.total_cost == null || item.total_cost == 0){
                          price = '공고확인'
                      }else if(item.total_cost > 0){
                          price = addComma(item.total_cost) + "원";
                      }



                      // 기업당 지원금(target_cost_value)
                      if(item.target_cost_value == undefined || item.target_cost_value == null || item.target_cost_value == 0){
                          supportFund = '공고확인'
                      }else if(item.target_cost_value > 0){
                          supportFund = addComma(item.target_cost_value) + "원";
                      }



                    // if (
                    //     item.target_cost_value > 0 &&
                    //     typeof item.target_cost_value == "number"
                    // ) {
                    //   price = item.target_cost_value
                    //       .toString()
                    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    // } else {
                    //   price = 0;
                    // }


                    return (
                        <li key={idx}>
                          <button
                              type="button"
                              onClick={() => {
                                openInNewTab(item.mobile_url, item.si_idx);
                              }}
                          >
                            <h5 className={styles.tit}>{item.si_title}</h5>
                            <p>
                              <span className={styles.dueDate}>{endTime}</span>
                              {/*{item.target_cost_value > 0 &&*/}
                              {/*    typeof item.target_cost_value == "number" && (*/}
                                      <>
                                        {/*<span className={styles.slash}>/</span>*/}
                                          <div className={styles.moneyAmountDIV}>
                                              <span className={styles.moneyAmountText}>총 사업비</span>
                                              <span className={styles.moneyAmount}>{price}</span>
                                          </div>

                                          <div className={styles.moneyAmountDIV}>
                                              <span className={styles.moneyAmountText}>기업당지원금</span>
                                              <span className={styles.moneyAmount}>{supportFund}</span>
                                          </div>

                                      </>
                                  {/*)}*/}
                            </p>
                          </button>
                        </li>
                    );
                  })}
                </ul>
                <button
                    className={styles.showAll}
                    onClick={() => {
                      navigate("/saved?cate=recent");
                    }}
                >
                  <span>전체보기</span>
                </button>
              </>
          ) : (
              <p className="empty">로그인이 필요합니다.</p>
          )}
        </>
      </div>
  );
};
export default SupportRecent;
