import React, { useState, useEffect } from "react";
import styles from "scss/pages/H_list.module.scss";
import H_SupportContent from "components/Hyundai/H_SupportContent";
import { useSelector } from "react-redux";

const H_list = ({
                    getSupportCont,
                    getDoughnutList,
                    ord,
                    setIsCheckingBox,
                    page,
                    setPage,
                    keyword,
                    setKeyword,
                    doughnutList,
                    cate
}) => {
    const supportData = useSelector((state) => state.supportData);
    const [total, setTotal] = useState(0);
    const [count, setCount] = useState(10);
    const allSupportCache = isTrue(sessionStorage.getItem("allSupportCache") ?? true );
    const [allSupport, setAllSupport] = useState(allSupportCache);
    // const [keyword, setKeyword] = useState("");
    const [supportFilterCont, setSupportFilterCont] = useState([]);
    function isTrue(target) { return target == "true"; };
    const setScrollStorage = (value) => { sessionStorage.setItem("sOffset", value); };

    useEffect(() => {
        if (keyword == "") {
            setTotal(supportFilterCont.length);
        } else {
            setTotal(supportData.length);
        }
    }, [keyword, supportData, supportFilterCont]);

    return (
        <>
            <div className={styles.SupportListH}>
                {/*반응형 주석*/}
                {/*<MobileTitle title={"지원사업"} />*/}
                <div>
                    <div className={styles.contArea}>
                        <div className={styles.listArea}>
                            {/*{isMobile && <p className={styles.total}>전체 {total}개</p>}*/}
                            <H_SupportContent
                                setIsCheckingBox={setIsCheckingBox}
                                getDoughnutList={getDoughnutList}
                                getSupportCont={getSupportCont}
                                count={count}
                                setCount={setCount}
                                allSupport={allSupport}
                                setScrollStorage={setScrollStorage}
                                keyword={keyword}
                                ord={ord}
                                setKeyword={setKeyword}
                                page={page}
                                setPage={setPage}
                                total={total}
                                setTotal={setTotal}
                                supportFilterCont={supportFilterCont}
                                setSupportFilterCont={setSupportFilterCont}
                                doughnutList={doughnutList}
                                cate={cate}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {
                    "\
                  body{\
                    background-color:#fff;\
                  }\
                "
                }
            </style>
        </>
    );
};

export default H_list;
