export const KAKAO_REST_API_KEY = "77d36012366dbdc783d9759eb4ee07a3"; // 엑시토
export const KAKAO_REDIRECT_URI = "https://exitobiz.co.kr/KakaoLogin";
export const KAKAO_CLIENT_SECRET = "rChYQ2C41s1NR7PbAXtcU6G8oCzSQ1nc";
export const KAKAO_LOGOUT_REDIRECT_URI =
    "https://exitobiz.co.kr/KakaoLogOut";

export const APPLE_CLIENT_ID = "kr.co.exitobiz";
export const APPLE_REDIRECT_URI = "https://api.exitobiz.co.kr/mainpage/AppleLogin";
export const APPLE_RESPONSE_TYPE = "code id_token";
export const APPLE_RESPONSE_MODE = "form_post";
export const APPLE_SCOPE = "email name";
export const APPLE_STATE = "origin:web";
