import React, { useState, useEffect } from "react";
import styles from "scss/pages/Support.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as api from  '../../lib/api';
import { setLoginCheck } from "redux/store";

const RecentItem = ({
                      item,
                      getRecentItems,
                      ord,
                      getDoughnutList,
                      getBarList,
                      getTotalCount,
                    }) => {
  const dispatch = useDispatch();
  const isWebApp = useSelector((state) => state.isWebApp);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  const supportItem = useSelector((state) => state.supportItem);
  const supportItemReady = useSelector((state) => state.supportItemReady);
  const endDateSource = item.si_end_dt;
  const today = new Date();
  const isEnd = endDateSource - today.getTime() < 0;
  const title = item.si_title;
  const cateCode = item.target_cat_name;
  const [cateName, setCateName] = useState("");
  const locCode = item.loc_code;
  const locName = supportItem.loc_cd.find((item) => item.code == locCode).code_nm;
  const targetName = item.target_name;
  const [endDate, endDay] = stringTimeToISO(item.si_end_dt, "MMDD");
  const [readDate, readDay] = [
    getYMD(item.tl_cret_dt),
    getDay(item.tl_cret_dt),
  ];
  function getYMD(dateSource) {
    const dateSource1 = new Date(dateSource);
    const year = dateSource1.getFullYear();
    const month = ("00" + (Number(dateSource1.getMonth()) + 1)).slice(-2);
    const date = ("00" + dateSource1.getDate()).slice(-2);
    return `${year}-${month}-${date}`;
  }

  function getDay(date) {
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    return week[new Date(date).getDay()];
  }

  let cost = item.target_cost_value;
  let costComma;
  typeof cost == "number" ? (costComma = addComma(item.target_cost_value) + '원') : (costComma = 0);


  let totalCost;
  totalCost = item.total_cost;

  if(totalCost == null || totalCost == 0 || totalCost == undefined) {
    totalCost = '공고확인'
  }else{
    totalCost = addComma(item.total_cost) +'원'
  }

  const margin = {marginRight:"10px"}

  if(cost == null || cost == undefined || cost == 0){
    costComma = '공고확인';
  }





  useEffect(() => {
    if (supportItemReady) setCateName(supportItem.spt_cd.find((x) => x.code == cateCode).code_nm);
  }, [supportItemReady]);

  useEffect(() => {
    window.addEventListener("jjimListener", (event) => {
      getRecentItems(ord);
      getDoughnutList();
      getBarList();
      getTotalCount();
    });
  },[]);

  const isZzim = item.mb_save_yn == "Y";
  const viewCount = item.view_cnt;
  function stringTimeToISO(stringDate, type) {
    const offset = 1000 * 60 * 60 * 9;
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const timeStamp = new Date(stringDate + offset);
    const date = timeStamp.toISOString().split("T")[0];
    const day = week[timeStamp.getDay()];
    if (type == "all")  return [date, day];
    if (type == "MMDD") {
      const MM = date.split("-")[1];
      const DD = date.split("-")[2];
      return [`${MM}.${DD}`, day];
    }
  }

  function addComma(numb) {
    return numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const zzimClick = (idx, mb_save_yn) => {
    let data = { mb_addidx: idx, mb_save_yn: mb_save_yn }
    api.updateZzimStatus(data, userInfo.id).then(() => {
      getRecentItems(ord);
      getDoughnutList();
      getBarList();
      getTotalCount();
    }).catch(err => console.log(err));
  };

  const openInNewTab = (url, idx) => {
    if(isWebApp){
      if (window && window.flutter_inappwebview)  window.flutter_inappwebview.callHandler("supportDtlF", idx);
    }else{
      window.open(url, "_blank", "noopener,noreferrer");
    }

    if (Object.keys(userInfo).length > 0) {
      let data = {
        "tl_page_type" : "지원사업",
        "tl_page_depth" : "3",
        "tl_page_name" : "지원사업-웹뷰",
        "tl_button_name" : "화면진입",
        "tl_type_cd" : idx.toString(),
        "tl_event" : "진입완료",
        "tl_memo" : "-"
      };
      api.insertTimeline(data, userInfo.id);
    }
    api.updateSupportViewCnt(idx).then(() => {
      getRecentItems(ord);
    })

  };
  return (
      <li className={styles.SupportItem}>
        <div className={styles.leftArea}>
          <div className={styles.itemTop}>
            <ol>
              <li>{cateName}</li>
              <li>{locName}</li>
              <li>{targetName}</li>
            </ol>
            <p>
              {readDate} ({readDay}) 읽음
            </p>
          </div>
          <div className={styles.itemInfo}>
            <h4>
              <button
                  onClick={() => {
                    openInNewTab(item.mobile_url, item.si_idx);
                  }}
              >
                {title}
              </button>
            </h4>
            <p>
              {/*{cost > 0 && (*/}
                  <>
                    {/*<span className={styles.moneyTit}>지원금</span>*/}
                    {/*<span className={styles.moneyAmount}>{costComma}원</span>*/}



                    <span className={styles.moneyTit}>총 사업비</span>
                    <span style={margin} className={styles.moneyAmount}>{totalCost}</span>

                    <span className={styles.moneyTit}>기업당 지원금</span>
                    <span className={styles.moneyAmount}>{costComma}</span>
                  </>
              {/*)}*/}
            </p>


            <p style={{color:'#959292'}}>등록일<span>{item.si_cret_dt}</span></p>



          </div>
        </div>
        <div className={styles.rightArea}>
          <ul>
            <li className={`${styles.dueDate} ` + (isEnd ? styles.end : "")}>
              {isEnd ? (
                  <span>마감</span>
              ) : (
                  <>
                    <img
                        priority="true"
                        src={require("assets/img/global/ico/ico_date.png")}
                        alt="마감일"
                    />
                    <span>
                  {endDate} ({endDay})
                </span>
                  </>
              )}
            </li>
            <li>
              <img
                  src={require("assets/img/global/ico/ico_view_black.png")}
                  alt="조회수"
              />
              <span>{viewCount} 회</span>
            </li>
            <li
                className={`${styles.btnZzim} ` + (isZzim ? styles.isZzim : null)}
            >
              <button
                  type="button"
                  onClick={() => {
                    if (!isLoggedIn) {
                      dispatch(setLoginCheck(true));
                      return false;
                    }
                    zzimClick(item.si_idx, item.mb_save_yn);
                  }}
              >
                <img
                    priority="true"
                    src={
                      isZzim
                          ? require("assets/img/global/ico/ico_zzim.png")
                          : require("assets/img/global/ico/ico_zzim_black.png")
                    }
                    alt={`찜 : ${isZzim}`}
                />
                <span>찜</span>
              </button>
            </li>
          </ul>
        </div>
      </li>
  );
};
export default RecentItem;
