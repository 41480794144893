import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as api from '../lib/api';

const SearchBox = ({
                     styles,
                     popularKeyword,
                     myKeyword,
                     getMyKeyword,
                     sessionKeyword,
                     setSessionKeyword,
                       setMobileSearchOpen,
                   }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isMobile = useSelector((state) => state.isMobile);

  const removeMyKeyword = (word) => {
    if (!window.confirm(`최근 검색어(${word})를 삭제하시겠습니까?`))  return false;
    api.delMyRecentKeyword({ tl_event: word }, userInfo.id).then(() => {
        getMyKeyword();
    }).catch(err => console.log(err));
  };


  const removeAll = () => {
    if (!window.confirm(`최근 검색어를 전체 삭제하시겠습니까?`))  return false;
    api.delAllMyRecentKeyword(userInfo.id).then(() => {
        getMyKeyword();
    })
  };


  const removeSessionKeyword = (word) => {
    setSessionKeyword((prev) => [...prev].filter((x) => x != word));
  };




  return (
      <div className={styles.searchBox}>
        <div className={styles.box}>
          <p className={styles.tit}>인기 검색어</p>
          {popularKeyword.length > 0 ? (
              <ul className={styles.keywordPopular}>
                {popularKeyword.map((word, idx) => (
                    <li key={idx}>
                      <Link to={`/support/supportList?keyword=${encodeURIComponent(word.tl_event)}`}
                      onClick={()=>{if(isMobile) setMobileSearchOpen(false);}}>
                        #{word.tl_event}
                      </Link>
                    </li>
                ))}
              </ul>
          ) : (
              <p className={styles.empty}>인기 검색어가 없습니다.</p>
          )}
        </div>
        <div className={styles.box}>
          <p className={styles.tit}>
            최근 검색어
            {myKeyword.length > 0 && (
                <button
                    className={styles.removeAll}
                    onClick={removeAll}
                    type="button"
                >
                  전체삭제
                </button>
            )}
          </p>
          {isLoggedIn ? (
              myKeyword.length > 0 ? (
                  <ul className={styles.keywordMy}>
                    {myKeyword.map((word, idx) => {
                      return (
                          <li key={idx}
                              onClick={() => {
                                  window.scrollTo(0, 0);
                              }}
                          >
                            <Link to={`/support/supportList?keyword=${encodeURIComponent(word.tl_event)}`}
                            onClick={()=>{
                                if(isMobile) setMobileSearchOpen(false);
                            }}>
                              {word.tl_event}
                            </Link>
                            <button
                                type="button"
                                className={styles.removeMyKeyword}
                                onClick={() => {
                                  removeMyKeyword(word.tl_event);
                                }}
                            >
                              <img
                                  src={
                                    isMobile
                                        ? require("assets/img/global/btn/btn_close_white_small.png")
                                        : require("assets/img/global/btn/btn_close_black_small.png")
                                  }
                                  alt="삭제"
                              />
                            </button>
                          </li>
                      );
                    })}
                  </ul>
              ) : (
                  <p className={styles.empty}>검색어를 입력하세요.</p>
              )
          ) : sessionKeyword.length > 0 ? (
              <ul className={styles.keywordMy}>
                {sessionKeyword.map((word, idx) => {
                  return (
                      <li key={idx}
                          onClick={() => {
                              window.scrollTo(0, 0);
                          }}
                      >
                        <Link to={`/support/supportList?keyword=${word}`}
                        onClick={()=>{if(isMobile) setMobileSearchOpen(false);}}>
                          {word}
                        </Link>
                        <button
                            type="button"
                            className={styles.removeMyKeyword}
                            onClick={() => {
                              removeSessionKeyword(word);
                            }}
                        >
                          <img
                              src={
                                isMobile
                                    ? require("assets/img/global/btn/btn_close_white_small.png")
                                    : require("assets/img/global/btn/btn_close_black_small.png")
                              }
                              alt="삭제"
                          />
                        </button>
                      </li>
                  );
                })}
              </ul>
          ) : (
              <p className={styles.empty}>검색어를 입력하세요.</p>
          )}
        </div>
      </div>
  );
};
export default SearchBox;
