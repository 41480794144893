import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginCheck } from "redux/store";
import CommunityModalReport from "components/community/CommunityModalReport";
import * as api from '../../lib/api';

const CommunityViewReReplyItem = ({ styles, item, getReply, getReReply }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const dispatch = useDispatch();
  const writerId = item.user_id;
  const isWriter = userInfo.id == writerId;
  const cmtId = item.id;
  const [controlBoxOpen, setControlBoxOpen] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);
  const [currentReply, setCurrentReply] = useState(item.description);
  const [modalOn, setModalOn] = useState(false);

  // 대댓글 삭제기능 완료
  const btnDelete = (e) => {
    const { currentTarget: { value } } = e;
    if (!window.confirm("댓글을 삭제하시겠습니까?")) return false;
    api.delCommReply({ id: parseInt(value) }).then(() => {
      controlEnd();
      alert("삭제되었습니다.");
    })
  };

  // 대댓글 수정기능 완료
  const btnModify = () => {
    setModifyOpen((prev) => !prev);
  };

  const modifyReplySubmit = () => {
    if (currentReply.replaceAll(" ", "").replaceAll("\n", "") == "") {
      alert("내용을 입력해주세요."); // CHECK : 메시지 확인
      return false;
    }
    if (!window.confirm("댓글을 수정하시겠습니까?")) return false;
    let data = {
       id: cmtId,
       description: currentReply,
     };
    api.updateCommReply(data).then(() => {
      setModifyOpen((prev) => !prev);
      controlEnd();
      alert("수정되었습니다.");
    })
  };

  const btnReport = () => {
    if (!isLoggedIn) {
      dispatch(setLoginCheck(true));
      return false;
    }
    setModalOn((prev) => !prev);
  };

  // 대댓글 차단기능 완료
  const btnBlock = () => {
    if (!isLoggedIn) {
      dispatch(setLoginCheck(true));
      return false;
    }
    if (!window.confirm(`${item.usernickname}님을 차단하시겠습니까?`))
      return false;
    let headers = {
      user_id: userInfo.id,
      target_id: writerId
    }
    api.insertCommBlock(headers).then(() => {
      controlEnd();
      alert(`${item.usernickname}님을 차단했습니다.`);
    })
  };

  const controlEnd = () => {
    getReply();
    getReReply();
    setControlBoxOpen(false);
  };

  // 대댓글 좋아요기능
  const btnCmtLike = () => {
    if (!isLoggedIn) {
      dispatch(setLoginCheck(true));
      return false;
    }
      api.insertCommReplyLike({ comment_id: cmtId }, userInfo.id).then(() => {
        getReReply();
        if(!item.thumb_up && writerId != userInfo.id){
          //대댓글 좋아요
          let data = {
            userId: writerId,
            idx : cmtId+'',
            title : '[커뮤니티 대댓글 좋아요 +1 알림]',
            body :  `${userInfo.usernickname}님이 내대댓글에 좋아요❤️를 눌렀습니다`,
            keyId : "like"
          };
          api.pushComm(data);
        }
      }).catch(err => console.log(err));
  };

  return (
      <>
        <li
            className={ `${styles.replyItem} ${styles.reReply} ` +  (isWriter ? styles.isWriter : "") }
        >
          <div className={styles.reReplyIco}>
            <img
                src={require("assets/img/global/ico/ico_reReply.png")}
                alt="rereply icon"
            />
          </div>
          <div className={styles.reReplyContWrap}>
            {modifyOpen ? (
                <div className={styles.replyModify}>
              <textarea
                  rows="5"
                  value={currentReply}
                  onChange={(e) => {
                    setCurrentReply((prev) => e.target.value);
                  }}
              ></textarea>
                  <button type="button" onClick={modifyReplySubmit}>
                    댓글수정
                  </button>
                </div>
            ) : (
                <>
                  <div className={styles.writeInform}>
                    <p className={styles.writer}>{item.usernickname}</p>
                    <span className={styles.createTime}>
                  {item.cret_dt.slice(0, -3)}
                </span>
                  </div>
                  <div className={`${styles.replyCont} ${styles.reReplyCont}`}>
                    <div className={styles.leftArea}>{item.description}</div>
                    <div className={styles.rightArea}>
                      <div className={styles.likeArea}>
                        <button className={styles.likeArea} onClick={btnCmtLike}>
                          <img
                              src={
                                item.thumb_up == true
                                    ? require("assets/img/global/ico/ico_like_selected.png")
                                    : require("assets/img/global/ico/ico_like.png")
                              }
                              alt={item.thumb_up == true ? "좋아요 ON" : "좋아요 OFF"}
                          />
                          <span>{item.like_count}</span>
                        </button>
                      </div>
                      <div className="controlBoxWrap">
                        <button
                            type="button"
                            onClick={() => {
                              setControlBoxOpen((prev) => !prev);
                            }}
                        >
                          <img
                              src={require("assets/img/global/ico/ico_more.png")}
                              alt="댓글 관리"
                          />
                        </button>
                        {controlBoxOpen &&
                            (isWriter ? (
                                <ul className="controlBox">
                                  <li>
                                    <button
                                        type="button"
                                        name="modify"
                                        onClick={btnModify}
                                    >
                                      수정
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                        type="button"
                                        name="delete"
                                        value={cmtId}
                                        onClick={btnDelete}
                                    >
                                      삭제
                                    </button>
                                  </li>
                                </ul>
                            ) : (
                                <ul className="controlBox">
                                  <li>
                                    <button
                                        type="button"
                                        name="report"
                                        onClick={btnReport}
                                    >
                                      신고
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                        type="button"
                                        name="block"
                                        onClick={btnBlock}
                                    >
                                      차단
                                    </button>
                                  </li>
                                </ul>
                            ))}
                      </div>
                    </div>
                  </div>
                </>
            )}
          </div>
        </li>
        {modalOn && (
            <CommunityModalReport
                item={item}
                setModalOn={setModalOn}
                category={"커뮤니티-댓글"}
            />
        )}
      </>
  );
};
export default CommunityViewReReplyItem;
