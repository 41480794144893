import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {setLoginCheck, setMarketingCheck} from "redux/store";
import styles from "scss/components/MobileNavigation.module.scss";
//머터리얼 아이콘
// import SvgIcon from "@mui/material/SvgIcon";
// import { SvgIconComponent } from "@mui/icons-material";

const MobileNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);

  const [communityBtnShow, setCommunityBtnShow] = useState(false);
  useEffect(() => {
    const path = location.pathname;
    path.includes("communityList")
      ? setCommunityBtnShow(true)
      : setCommunityBtnShow(false);
  }, [location]);

  const [shareBtnShow, setshareBtnShow] = useState(false);
  useEffect(() => {
    const path = location.pathname;

    path.includes("communityView")
        ? setshareBtnShow(true)
        : setshareBtnShow(false);
  }, [location]);

  const shareBtn = () => {

    const path = location.pathname;

    if(window && window.flutter_inappwebview)  window.flutter_inappwebview.callHandler('shareF', '', "https://exitobiz.co.kr" + path);
  }

  return (
    <>
      <ul className={styles.MobileNavigation}>
        <li className={styles.mobileBtns}>
          {communityBtnShow && (
            <button
              onClick={(e) => {
                if (!isLoggedIn) {
                  dispatch(setLoginCheck(true));
                  return false;
                }
                navigate("/community/communityWrite");
              }}
              className={styles.btnWrite}
            >
              커뮤니티 글 작성
            </button>
          )}
          <button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className={styles.toTop}
          >
            To Top
          </button>
          {shareBtnShow && (
              < button
            onClick={() => {
            shareBtn();
          }}
            className={styles.share}
            >
            공유하기
            </button>
            )}
        </li>
        <li>
          <Link to={"/"}>
            <img src={require("assets/img/global/ico/mNavHome.png")} alt="홈" />
            <span>홈</span>
          </Link>
        </li>



        <li>
          <Link
            to={"/saved?cate=save"}
            onClick={(e) => {
              if (!isLoggedIn) {
                e.preventDefault();
                dispatch(setLoginCheck(true));
              }
              if(isChkMarketing && isLoggedIn){
                e.preventDefault();
                dispatch(setMarketingCheck(true));
                return false;
              }
            }}
          >
            <img
              src={require("assets/img/global/ico/mNavSaved.png")}
              alt="찜"
            />
            <span>찜</span>
          </Link>
        </li>찜



        <li>
          <Link
              to={"/support/supportList"}
              onClick={(e) => {
                if (!isLoggedIn) {
                  e.preventDefault();
                  dispatch(setLoginCheck(true));
                }
                if(isChkMarketing && isLoggedIn){
                  e.preventDefault();
                  dispatch(setMarketingCheck(true));
                  return false;
                }
              }}

          >
            <img
              src={require("assets/img/global/ico/mNavSupport.png")}
              alt="지원사업"
            />
            <span>지원사업</span>
          </Link>
        </li>
        <li>
          <Link
              to={"/community/communityList"}
              onClick={(e) => {
                if (!isLoggedIn) {
                  e.preventDefault();
                  dispatch(setLoginCheck(true));
                }
                if(isChkMarketing && isLoggedIn){
                  e.preventDefault();
                  dispatch(setMarketingCheck(true));
                  return false;
                }
              }}
          >
            <img
              src={require("assets/img/global/ico/mNavComm.png")}
              alt="커뮤니티"
            />
            <span>커뮤니티</span>
          </Link>
        </li>
      </ul>
    </>
  );
};
export default MobileNavigation;
