import "scss/reset.scss";
import "scss/global.scss";
import {
  signIn,
  setSupportInfoDefault,
  setUserInfo,
  setSupportItemReady,
  setKakaoInform,
  setAppleInform,
  signOut,
  removeUserInfo,
  setLoginCheck,
  marketingChkY,
  marketingChkN,
  setOsCheck,
  setMarketingCheck
} from "redux/store";
import AppRouter from "components/Router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as api from '../lib/api';

import {
  setSupportItem,
  setCompanyInfo,
  setSupportInfoModal,
  setIsMobile,
  setIsAdmin,
  setIsWebApp,
} from "redux/store";
import { useMediaQuery } from "react-responsive";
import {useNavigate} from "react-router-dom";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: "1023px" });
  const isWebApp = useSelector((state) => state.isWebApp);
  const isAdmin = useSelector((state) => state.isAdmin);
  const marketingCheck = useSelector((state) => state.marketingCheck);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  const supportItem = useSelector((state) => state.supportItem);
  const userCompany = useSelector((state) => state.userCompany);
  const getFilterData = (code, custom) => {
    let param = custom ? `ctgCd=${code}&etcCd1=${custom}` : `ctgCd=${code}`;
    api.getCodeDtlList(param).then((res) => {
      dispatch(setSupportItem({ cate: code, arr: res.data }));
    })
  };

  useEffect(() => {

    getFilterData("bizp_type_cd"); // 사업자형태
    getFilterData("prd_cd"); // 창업기간
    getFilterData("biz_type_cd"); // 기업형태
    getFilterData("spt_cd"); // 지원분야
    // getFilterData("biz_cd"); // 사업분야
    // getFilterData("tech_cd"); // 기술분야
    getFilterData("loc_cd"); // 지역
    getFilterData("city_cd"); // 시코드
    getFilterData("district_cd"); // 구코드
    if (sessionStorage.getItem("isLoggedIn") && !sessionStorage.getItem("corpNm")) {
        const userId = JSON.parse(sessionStorage.getItem("userId"));
        setIsAdmin(sessionStorage.getItem("isAdmin"));
        defaultSignIn(userId);
        dispatch(signIn());

        api.chkMarketPush(userId).then((res) => {
          if(res.data){
            dispatch(setMarketingCheck(true));
            dispatch(marketingChkN());
          }else{
            dispatch(setMarketingCheck(false));
            dispatch(marketingChkY());
          }
        });
    }


  }, []);

  const callFlutter = async ( fData ) => {
    if(fData.userid == 'guest') {
      dispatch(signOut());
      dispatch(removeUserInfo());
    }else{
      api.getUserInfo(fData.userid).then((res4) => {
        const data = res4.data;

        if(data != null){
          const userId = data.id;
          if(data.usernickname != "탈퇴회원" && data != null){
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("oAuthType", fData.checkOS);

            // if(fData.checkOS =='kakao') sessionStorage.setItem("kakaoToken", fData.token);
            if(fData.checkOS =='kakao')  sessionStorage.removeItem("kakaoRedirectPath");
            else sessionStorage.removeItem("appleRedirectPath");

            dispatch(signIn(data));
            dispatch(setUserInfo(data));

            api.chkMarketPush(fData.userid).then((res) => {
              if(res.data){
                dispatch(setMarketingCheck(true));
                dispatch(marketingChkN());
              }else{
                dispatch(setMarketingCheck(false));
                dispatch(marketingChkY());
              }
            });

            if(data.admin_flag == 'Y')  dispatch(setIsAdmin(true));
          }

          if( fData.checkOS == 'kakao'){
            localStorage.setItem("kakaoToken", fData.token);
          }
        }


      })


    }
  };

  useEffect(() => {
    var listner = "";
    //세션유지
    window.addEventListener("sessionListener", (event) => {
      listner = 'session';
        let data = JSON.parse(event.detail);
      dispatch(setLoginCheck(false));
        callFlutter(data);
    });

    window.addEventListener('error', function(event){
      if (window && window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('errorF', listner, event.error);
      }
    })
  },[]);

  const loginF = (obj) => {
    if(obj.mos != null) dispatch(setOsCheck(obj.mos));
    dispatch(setLoginCheck(false));
    api.getUserInfo(obj.userid).then((result) => {
      const data = result.data;
      if( obj.checkOS == 'kakao')  localStorage.setItem("kakaoToken", obj.token);

      if (data === null) {
        // 최초 가입
        obj.checkOS == 'kakao' ? dispatch(setKakaoInform({ state: true, datas: obj })) : dispatch(setAppleInform({ state: true, datas: obj }));
        navigate("/");
      } else if (Object.keys(data).length > 0) {
        if (data.usernickname == "탈퇴회원") {
          // 탈퇴 회원
          obj.checkOS == 'kakao' ? dispatch(setKakaoInform({ state: true, datas: obj })) :  dispatch(setAppleInform({ state: true, datas: obj }));
          navigate("/");
        } else {

          // 기존 회원
          api.getUserInfo(obj.userid).then((res4) => {
            const data = res4.data;
            const userId = data.id;
            sessionStorage.setItem("userId", userId);
            sessionStorage.setItem("oAuthType", obj.checkOS);
            if(data.admin_flag == 'Y')  dispatch(setIsAdmin(true));

            dispatch(signIn(data));
            dispatch(setUserInfo(data));
            dispatch(setLoginCheck(false));

            api.chkMarketPush(obj.userid).then((res) => {
              if(res.data){
                dispatch(setMarketingCheck(true));
                dispatch(marketingChkN());
              }else{
                dispatch(setMarketingCheck(false));
                dispatch(marketingChkY());
              }
            });


            // let rePath = obj.checkOS = 'kakao' ?  sessionStorage.getItem("kakaoRedirectPath") ?? "/" :  sessionStorage.getItem("appleRedirectPath") ?? "/";
            // navigate(rePath);

            obj.checkOS = 'kakao' ? sessionStorage.removeItem("kakaoRedirectPath") : sessionStorage.removeItem("appleRedirectPath");
          })
        }
      }


    })
  }

  useEffect(() => {
    //카카오 데이터 호출
    window.addEventListener("kakaoLoginListener", (event) => {
      dispatch(setIsWebApp(true));
      let obj = JSON.parse(event.detail);
      obj.checkOS = 'kakao';
      loginF(obj);
    });

    //애플 데이터 호출
    window.addEventListener("appleLoginListener", (event) => {
      dispatch(setIsWebApp(true));
      let obj = JSON.parse(event.detail);
      obj.checkOS = 'apple';
      loginF(obj);
    });
  }, [isWebApp]);


  const defaultSignIn = (id) => {
    api.getUserInfo(id).then((res) => {
      const data = res.data;
      const id = data.id;
      const isAdmin = data.admin_flag == 'Y' ? true : false ;
      sessionStorage.setItem("userId", id);
      sessionStorage.setItem("isAdmin", isAdmin);
      dispatch(setUserInfo(data));
    })
  };
  const getUserCompany = () => {
    api.getCompanyInfo(userInfo.id).then((res) => {
      if (res.data == null) return false;
      dispatch(setCompanyInfo(res.data));
    })
  };


  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      getUserCompany(userInfo.id);
    }
  }, [userInfo]);
  useEffect(() => {
    let itemContain = 0;
    for (let key in supportItem) {
      if (supportItem[key].length > 0) {
        itemContain++;
      }
    }

    if (Object.keys(userCompany).length > 0 && itemContain >= 7) {
      const itemArr = {
        business_type: "bizp_type_cd",
        company_type: "biz_type_cd",
        start_period: "prd_cd",
        support_type: "spt_cd",
        // business_ctg: "biz_cd",
        // tech_ctg: "tech_cd",
        loc_ctg: "loc_cd",
      };
      const obj = { ...userCompany };
      for (let key in itemArr) {
        const name = itemArr[key];
        const origin = supportItem[name];
        const arr = obj[key].split(",");

        const newArr = arr.map((v) => {
          return origin.find((x) => x.code == v);
        });
        dispatch(
            setSupportInfoModal({
              name: name,
              datas: newArr,
            })
        );
      }
      // const business_typeArr = obj.business_type.split(",");
      // const business_typeList = business_typeArr;

      // {
      //     "business_type": "02",사업자형태
      //     "company_type": "01,02",기업형태1
      //     "start_period": "10",창업기간
      //     "support_type": "01,02",지원분야
      //     "business_ctg": "01,03",사업분야
      //     "tech_ctg": "01,03,04"기술분야
      //     "loc_ctg": "C82,C032,C033,C042",지역
      // }

      //bizp_type_cd:사업자형태
      //biz_type_cd:기업형태
      //prd_cd:창업기간
      //spt_cd:지원분야
      //biz_cd:사업분야
      //tech_cd:기술분야
      //loc_cd:지역
    }
  }, [userCompany]);

  useEffect(() => {
    if (Object.keys(supportItem).length == 7) {
      dispatch(setSupportItemReady(true));
    }
  }, [supportItem]);

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [isMobile]);
  useEffect(() => {
    dispatch(setIsAdmin(isAdmin));
  }, [isAdmin]);

  useEffect(() => {
    dispatch(setIsWebApp(isWebApp));
  }, [isWebApp]);

  useEffect(() => {

    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if ( varUA.indexOf("iswebapp") > -1) {
      dispatch(setIsWebApp(true));
    }

    if ( !(varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 || varUA.indexOf("mac") > -1 ||  varUA.indexOf("ios") > -1 )) {
      dispatch(setOsCheck('Android'));
    }

    window.addEventListener("isExitoWebApp", (event) => {

      var chk = JSON.parse(JSON.stringify(event.detail));
      if(chk == "isExitoWebApp") dispatch(setIsWebApp(true));

    });
  }, [isWebApp]);


  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
          setSupportInfoDefault({
            bizp_type_cd: {
              name: "사업자형태",
              multiply: false,
              require: true,
              datas: [
                {
                  code_nm: "전체",
                  code: "01",
                  ctg_cd: "bizp_type_cd",
                  ctg_nm: "사업자형태",
                },
              ],
            },
            biz_type_cd: {
              name: "기업형태",
              multiply: true,
              require: true,
              datas: [
                {
                  code_nm: "전체",
                  code: "01",
                  ctg_cd: "biz_type_cd",
                  ctg_nm: "기업형태",
                },
              ],
            },
            prd_cd: {
              name: "창업기간",
              multiply: false,
              require: true,
              datas: [
                {
                  code_nm: "전체",
                  code: "999",
                  ctg_cd: "prd_cd",
                  ctg_nm: "창업기간",
                },
              ],
            },
            spt_cd: {
              name: "지원분야",
              multiply: true,
              require: true,
              datas: [
                {
                  code_nm: "전체",
                  code: "01",
                  ctg_cd: "spt_cd",
                  ctg_nm: "지원분야",
                },
              ],
            },
            // biz_cd: {
            //   name: "사업분야",
            //   multiply: true,
            //   require: true,
            //   datas: [
            //     {
            //       code_nm: "전체",
            //       code: "01",
            //       ctg_cd: "biz_cd",
            //       ctg_nm: "사업분야",
            //     },
            //   ],
            // },
            // tech_cd: {
            //   name: "기술분야",
            //   multiply: true,
            //   require: true,
            //   datas: [
            //     {
            //       code_nm: "전체",
            //       code: "01",
            //       ctg_cd: "tech_cd",
            //       ctg_nm: "기술분야",
            //     },
            //   ],
            // },
            loc_cd: {
              name: "지역",
              multiply: true,
              require: true,
              datas: [
                {
                  code_nm: "전체",
                  code: "C99",
                  ctg_cd: "loc_cd",
                  ctg_nm: "지역",
                },
              ],
            },
            city_cd: {
              name: "시코드",
              multiply: false,
              require: false,
              datas: [
                {
                  code_nm: "서울시",
                  code: "01",
                  ctg_cd: "city_cd",
                  ctg_nm: "시코드",
                },
              ],
            },

            district_cd: {
              name: "구코드",
              multiply: false,
              require: false,
              datas: [
                {
                  code_nm: "강남구",
                  code: "01",
                  ctg_cd: "district_cd",
                  ctg_nm: "구코드",
                },
              ],
            },
          })
      );
    }
  }, [isLoggedIn]);
  return (
      <>
        <AppRouter />
      </>
  );
}

export default App;
