import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as api from 'lib/api';
import HomeboxStyle from "scss/components/NewHomebox.module.scss";
import Homebox from "scss/components/SearchForm.module.scss";
import SearchForm from "../SearchForm";
import {getCorpSupportList} from "lib/api";
import {setLoginCheck, setMarketingCheck} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import searchFormStyle from "../../scss/components/SearchForm.module.scss";


const NewHomebox = ({

                    }) => {

    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const isChkMarketing = useSelector((state) => state.isChkMarketing);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
    const isMobile = useSelector((state) => state.isMobile);

    const arr = [
        ['교육,멘토링,컨설팅', 'icon_Education.png', '교육'],
        ['마케팅,홍보,전시,박람회', 'icon_Marketing.png', '마케팅'],
        ['수출,해외,글로벌', 'icon_Export.png', '수출'],
        ['스타트업', 'icon_StartUp.png', '스타트업'],
        ['입주,공간', 'icon_MoveIn.png', '입주'],
        ['청년', 'icon_Youth.png', '청년'],
        ['투자,데모데이', 'icon_Investment.png', '투자'],
        ['연구,R&D', 'icon_R&D.png', 'R&D'],
    ]


    const click = (e) => {
        if (!isLoggedIn) {
            e.preventDefault();
            dispatch(setLoginCheck(true));
            return false;
        }

        if(isChkMarketing && isLoggedIn){
            e.preventDefault();
            dispatch(setMarketingCheck(true));
            return false;
        }
    };


    return (
        <>
            <div className={HomeboxStyle.homeboxWrap}>
                <div className={HomeboxStyle.homeboxLeft}>
                    <SearchForm
                        styles={Homebox}
                        Homeboxscss = {true}

                        // mobileSearchOpen={mobileSearchOpen}
                        // setMobileSearchOpen={setMobileSearchOpen}
                    />

                    <ul className={HomeboxStyle.homeboxLeftIconBox}>

                        {/*{arr.map((word,idx) => {*/}
                        {/*    return(*/}
                        {/*        <li>*/}
                        {/*            <Link*/}
                        {/*                onClick={(e) => {*/}
                        {/*                    click(e);*/}
                        {/*                }}*/}
                        {/*                to={`/support/supportList?keyword=${idx}`}>*/}
                        {/*                <img*/}
                        {/*                    src={require("assets/img/global/ico/icon_Education.png")}*/}
                        {/*                    alt="교육 아이콘"*/}
                        {/*                />*/}
                        {/*                <span>교육</span>*/}
                        {/*            </Link>*/}
                        {/*        </li>*/}
                        {/*    )*/}
                        {/*})}*/}







                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=교육,멘토링,컨설팅`}>
                                <img
                                    src={require("assets/img/global/ico/icon_Education.png")}
                                    alt="교육 아이콘"
                                />
                                <span>교육</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=마케팅,홍보,전시,박람회`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_Marketing.png")}
                                    alt="마케팅 아이콘"
                                />
                                <span>마케팅</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=수출,해외,글로벌`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_Export.png")}
                                    alt="수출 아이콘"
                                />
                                <span>수출</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=스타트업`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_StartUp.png")}
                                    alt="스타트업 아이콘"
                                />
                                <span>스타트업</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=입주,공간`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_MoveIn.png")}
                                    alt="입주 아이콘"
                                />
                                <span>입주</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=청년`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_Youth.png")}
                                    alt="청년 아이콘"
                                />
                                <span>청년</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=투자,데모데이`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_Investment.png")}
                                    alt="투자 아이콘"
                                />
                                <span>투자</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?keyword=연구,R%26D`}
                            >
                                <img
                                    src={require("assets/img/global/ico/icon_R&D.png")}
                                    alt="R&D 아이콘"
                                />
                                <span>R&D</span>
                            </Link>
                        </li>






                    </ul>
                </div>

                <div className={HomeboxStyle.homeboxRight}>
                    <h1>주요 기관 지원사업</h1>
                    <div className={HomeboxStyle.innerhomeboxRight}>
                        <section>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?target_name=K-Startup`}
                            >
                                <img
                                    src={require("assets/img/global/ico/kstartup.png")}
                                    alt="kstartup 아이콘"
                                />
                            </Link>
                        </section>
                        <section>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?target_name=기업마당`}
                            >
                                <img
                                    src={require("assets/img/global/ico/Institution1.png")}
                                    alt="기업마당 아이콘"
                                />
                            </Link>
                        </section>
                        <section>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?target_name=한국테크노파크`}
                            >
                                <img
                                    src={require("assets/img/global/ico/Institution3.png")}
                                    alt="한국테크노파크 아이콘"
                                />
                            </Link>
                        </section>
                        <section>
                            <Link
                                onClick={(e) => {
                                    click(e);
                                }}
                                to={`/support/supportList?target_name=한국무역협회`}
                            >
                                <img
                                    src={require("assets/img/global/ico/Institution2.png")}
                                    alt="한국무역협회 아이콘"
                                />
                            </Link>
                        </section>
                    </div>
                </div>


            </div>
        </>
    );
};
export default NewHomebox;
