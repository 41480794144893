import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import FirstLoginPersonStyle from "scss/components/FirstLoginPerson.module.scss";
import {useParams} from "react-router-dom";
import * as api from "../../lib/api";
import {useDispatch, useSelector} from "react-redux";
import {marketingChkN, setIsAdmin, setLoginCheck, setMarketingCheck, setUserInfo, signIn} from "../../redux/store";
import FirstLoginCompany from "./FirstLoginCompany";
import MainFirstConditions from "./MainFirstConditions";

const FirstLoginPerson = ({ setLastCheck, kakaoInform, appleInform, isFirst }) => {


    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();

    //style
    const marginBottom = {marginBottom:'20px'}
    const marginTop = {marginTop:'0px'}
    const codeCheckColor = {backgroundColor:'#ccc'}
    const CertifiedColor = {backgroundColor:'#ccc'}
    const requestStyle = {marginTop:'10px'}
    const requestMailStyle = {textDecoration:'underline' , color:'#4949db',marginLeft:'5px',cursor: 'pointer'}


    // 이메일 벨리데이션
    const [emailValidation1 , setEmailValidation1] = useState(false) //필수값
    const [emailtype, setEmailtype] = useState(''); // 정규식
    const [emailTypeValidation, setEmailTypeValidation] = useState(false); // 이메일형식

    // 핸드폰 벨리데이션
    const [phonetype, setPhonetype] = useState('');// 정규식
    const [phoneTypeValidation, setPhoneTypeValidation] = useState(false); // 핸드폰형식

    // 닉네임 벨리데이션
    const [nicknameValidation1 , setNicknameValidation1] = useState(false) //필수값
    const [nicknameValidation2 , setNicknameValidation2] = useState(false)

    const [codeCheckValidation , setCodeCheckValidation] = useState(false)// 인증완료
    const [codeCheckClick , setcodeCheckClick] = useState(false)// 인증완료
    const [Authentication , setAuthentication] = useState(false)// 인증완료

    const [email ,setEmail] = useState('')
    const [codeCheckValue ,setCodeCheckValue] = useState('')
    const [codeCheckStyle , setCodeCheckStyle] = useState(true)
    const [Certified , setCertified] = useState(true)
    const [request, setRequest] = useState(false)

    const [nicknameCheck, setNicknameCheck] = useState('');
    const [CodeDisabled, setCodeDisabled] = useState(false);

    const [FirstLoginPersonM, setFirstLoginPersonM] = useState(true);
    const [FirstLoginCompanyM, setFirstLoginCompanyM] = useState(false);
    const [conditions , setConditions] = useState(false);
    const [phone ,setPhone] = useState('') //담당자정보 전화번호
    const [nickname ,setNickname] = useState('') //담당자정보 엑시네닉네임


    const [EmailCertifiedClick ,setEmailCertifiedClick] = useState(false) //인증요청 클릭했을때 코드확인 활성화 상태값




    const [requireObj, setRequireObj] = useState({
        servicePush : true,
        collectPush : true
    });

    const [alarmObj, setAlarmObj] = useState({
        marketingPush: false, // 마케팅 정보 수신 동의
        personalInfoAgree: false, // 개인 및 기업정보 제 3자 제공 동의
    });

    //최초가입 시, 아이디
    let userid = {};

    if(isFirst){
        if (kakaoInform.state) {
            userid = { ...kakaoInform.datas };
        }
        if (appleInform.state) {
            userid = { userid: appleInform.datas.userid };
        }
    }





    const checkPhonenumber = (e) => {

        var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
        setPhonetype(regExp.test(e.target.value))


        if(phone == '' || phonetype == false){
            setPhoneTypeValidation(true)
        }else{
            setPhoneTypeValidation(false)
        }
    }

    function encoding(string) {
        return encodeURI(string);
    };
    const nicknameCheckSubmit = (e) => {
        // e.preventDefault();
        if (nickname.replaceAll(" ", "") == "") {
            alert("닉네임을 입력해주세요.");
            return false;
        }
        if (nickname.slice(0, 1) == " ") {
            alert("첫 글자에는 공백이 들어갈 수 없습니다..");
            return false;
        }
        if (nickname == "탈퇴회원") {
            alert("해당 닉네임은 사용하실 수 없습니다.");
            return false;
        }

        api.checkNickname(encoding(nickname)).then((res) => {

            if(isFirst){
                if(res.data){
                    alert('사용가능한 닉네임 입니다.')
                    setNicknameValidation2(false)
                    setNicknameValidation1(false)
                }else if(!res.data){
                    alert('사용 불가능한 닉네임 입니다.')
                }
                setNicknameCheck(res.data);
            }else{
                if(userInfo.usernickname == nickname || res.data){
                    alert('사용가능한 닉네임 입니다.')
                    setNicknameValidation2(false)
                    setNicknameValidation1(false)
                    setNicknameCheck(true);
                }else if(!res.data){
                    alert('사용 불가능한 닉네임 입니다.')
                    setNicknameCheck(false);
                }
            }

        })
    };

    const nicknameChange = (e) => {

        const {
            currentTarget: { value, maxLength },
        } = e;
        const name = e.currentTarget.value;
        if (!(name.length > maxLength)) {
            setNickname(name);
        }
        setNicknameCheck("");
    };


    const NickName = (e) =>{
        // 닉네임 벨리데이션
        if(nickname == ''){
            setNicknameValidation2(true)
        }else if(nicknameCheck == false){
            setNicknameValidation1(true)
        }else{
            setNicknameValidation2(false)
            setNicknameValidation1(false)
        }
    };


    const codeCheck = async (e) => {


        if(EmailCertifiedClick){

            var data = {
                auth_nm : codeCheckValue
            }

            let headers = {
                user_id : isFirst ? userid.userid : userInfo.id
            }

            let res = await api.checkAuth(data, headers);

            // console.log('res>>>',res)

            if(res.data == 'success'){
                alert('인증이 완료되었습니다')
                setCodeCheckStyle(false)
                setRequest(false)

                setCodeDisabled(true)
                setAuthentication(true)
                setcodeCheckClick(true)
                setCodeCheckValidation(false)


            }else{
                alert('올바른 인증코드를 입력해 주세요')
                // setRequest(true)

            }

        }else{
            alert('이메일 인증요청을 해주세요')
        }






    };


    const checkEmail = (e) => {
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        setEmailtype(regExp.test(e.target.value))


        if(emailtype == false) {
            setEmailTypeValidation(true)
            setEmailValidation1(false)
        }

        if(email == ''){
            setEmailValidation1(true)
            setEmailTypeValidation(false)
        };

        if(emailtype == true) {
            setEmailTypeValidation(false)
            setEmailValidation1(false)
        }
    };


    const EmailCertified = async (type) => {

        setEmailCertifiedClick(true)

        if(email != '' && emailtype == true){
            setCertified(false) // 인증요청
            var data = {email : email}
            let Certified = await api.authEmail(data, isFirst ? userid.userid : userInfo.id);

            if(Certified.data == 'success'){


                setRequest(true)

                if(type == 'first') alert('인증코드가 전송되었습니다.');
                else alert("메일이 발송되었습니다. 메일을 또 다시 받지 못했을 경우 고객센터 (02-561-1898)로 문의 바랍니다");
            }else{
                alert('인증요청에 실패했습니다. 다시 한번 시도해 주세요')
            }
        }
    };


    const handleKeyDown = (e) => {
        if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
            e.preventDefault();
        }
    }



    const Next = async () => {

    if(email != '' && codeCheckValue != '' && phone != '' && phonetype != false && phone.length == 11 &&  nickname != '' && nicknameCheck == true && emailValidation1 == false && emailTypeValidation == false &&  phoneTypeValidation == false && nicknameValidation1 == false && codeCheckClick == true){

        // 모달창 다음으로 넘어가기
        setFirstLoginPersonM(false);
        setFirstLoginCompanyM(true);

    }else{

        //회사이메일 벨리데이션
        if(emailtype == false){
            setEmailTypeValidation(true)
            setEmailValidation1(false)
        };

        if(email == ''){
            setEmailValidation1(true)
            setEmailTypeValidation(false)
        }else{
            setEmailValidation1(false)
            setEmailTypeValidation(false)
        };

        if(emailtype == true){
            setEmailTypeValidation(false)
            setEmailValidation1(false)
        };


        if(codeCheckValue == ''){
            setCodeCheckValidation(true)
        }else if(codeCheckClick == false){
            alert('코드 확인을 진행해주세요')
        }else{
            setCodeCheckValidation(false)
        };


        //전화번호
        if(phone == '' || phonetype == false || phone.length != 11){
            setPhoneTypeValidation(true)
        }else{
            setPhoneTypeValidation(false)
        };


        //닉네임
        if(nickname == ''){

            //닉네임(input) 값이 빈값일때
            setNicknameValidation2(true) //닉네임을 입력해주세요
            setNicknameValidation1(false) //닉네임 중복확인을 해주세요


        }else if(nicknameCheck == ''){

            //닉네임 중복확인 안눌렀는데 다음으로 넘어가려고 할떄
            setNicknameValidation1(true) //닉네임 중복확인을 해주세요
            setNicknameValidation2(false) //닉네임을 입력해주세요

        }else{
            setNicknameValidation2(false)
            setNicknameValidation1(false)
        };

    }
    };


    return (
        <>
            {FirstLoginPersonM &&
                <div className={FirstLoginPersonStyle.wrap}>
                    <div className={FirstLoginPersonStyle.modal}>
                        <section className={FirstLoginPersonStyle.top}>
                            <div>
                                <img
                                    src={require("assets/img/global/ico/ico_policy.png")}
                                    alt=""
                                />
                                <p>엑시토 서비스 이용을 위한 마지막 단계입니다</p>
                            </div>

                            <p>
                                <img
                                className={FirstLoginPersonStyle.X}
                                src={require("assets/img/global/btn/btn_close_black.png")}
                                alt="닫기"
                                onClick={() => {
                                    if (window.confirm("정보 미입력 시 엑시토 서비스를 이용하실 수 없습니다. 창을 닫으시겠습니까?")) {
                                        setFirstLoginPersonM(false);
                                        setFirstLoginCompanyM(false);
                                        setConditions(false)
                                        dispatch(setMarketingCheck(false));
                                        dispatch(marketingChkN());
                                        if(isFirst)  setLastCheck(false);
                                    } else {};
                                }}
                                />
                            </p>
                        </section>
                        <div className={FirstLoginPersonStyle.contents}>
                            <h1>담당자 정보</h1>
                            <section style={marginBottom}>
                                <p>회사 이메일</p>
                                <div className={FirstLoginPersonStyle.innerCon}>
                                    <input
                                        autocomplete='off'
                                        type="text"
                                        id='code'
                                        disabled={CodeDisabled == true}
                                        placeholder={'회사 이메일을 입력해 주세요'}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                            checkEmail(e)
                                        }}
                                    />

                                    {Certified ?
                                        <button
                                            onClick={(e) => {
                                                EmailCertified("first");
                                                // getUser();
                                            }}
                                        >인증요청</button> :
                                        <button
                                            className={FirstLoginPersonStyle.MBtn}
                                            style={CertifiedColor}
                                        >인증요청</button>
                                    }

                                </div>
                                <p style={{color:'#9a9a9a'}}>*메일을 받지 못하셨다면 <span style={{fontWeight:'bold'}}>'스팸 메일함'</span>을 확인해 주세요.</p>
                                {emailValidation1 && <p className={FirstLoginPersonStyle.validation}>*이메일을 입력해주세요</p>}
                                {emailTypeValidation && <p className={FirstLoginPersonStyle.validation}>*이메일을 정확히 입력해주세요</p>}
                            </section>
                            <section style={marginTop} className={FirstLoginPersonStyle.Certified}>
                                <div>
                                    <div className={FirstLoginPersonStyle.innerDIV}>
                                        <input
                                            type="number"
                                            placeholder={'메일로 전송된 인증 코드를 입력해 주세요'}
                                            value={codeCheckValue}
                                            onChange={(e) => {
                                                setCodeCheckValue(e.target.value)
                                            }}
                                        />
                                        {Authentication && <span>인증완료</span>}
                                    </div>
                                    {codeCheckStyle ?
                                        <button className={FirstLoginPersonStyle.MBtn}
                                            onClick={(e) => {
                                                codeCheck();
                                            }}
                                        >코드확인</button> :
                                        <button
                                            className={FirstLoginPersonStyle.MBtn}
                                            style={codeCheckColor}
                                            onClick={(e) => {
                                                codeCheck();
                                            }}
                                        >확인완료</button>
                                    }
                                </div>
                                {request &&
                                    <p style={requestStyle}>*이메일을 받지 못하셨나요?
                                        <span
                                            style={requestMailStyle}
                                            onClick={(e) => {
                                                EmailCertified("second");
                                            }}
                                        >메일 재요청</span>
                                    </p>
                                }
                                {codeCheckValidation && <p className={FirstLoginPersonStyle.validation}>*인증코드를 입력해주세요</p>}
                            </section>
                            <section>
                                <p>전화번호</p>
                                <input
                                    onInput={(e) => {
                                        if (e.target.value.length > e.target.maxLength)
                                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                                    }}
                                    maxlength={11}
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    placeholder={'(-)없이 숫자만 입력'}
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                        checkPhonenumber(e)
                                    }}
                                />
                                {phoneTypeValidation && <p className={FirstLoginPersonStyle.validation}>*전화번호를 정확히 입력해주세요</p>}
                            </section>
                            <section>
                                <p>엑시토 닉네임</p>
                                <div className={FirstLoginPersonStyle.innerCon}>
                                    <input
                                        maxLength = '7'
                                        type="text"
                                        placeholder={'8자 이내'}
                                        value={nickname}
                                        onChange={(e) => {
                                            setNickname(e.target.value)
                                            // NickName(e)

                                            if(nickname == ''){
                                                // 닉네임 중복확인 -> 닉네임 다시 지우고 다시 중복확인
                                                setNicknameCheck(false)
                                            }
                                        }}
                                    />
                                    <button
                                        onClick={() => {
                                            nicknameCheckSubmit();
                                        }}
                                        onChange={nicknameChange}
                                    >중복확인</button>
                                </div>
                                {nicknameValidation1 ? <p className={FirstLoginPersonStyle.validation}>*닉네임 중복확인을 해주세요</p> : null}
                                {nicknameValidation2 ? <p className={FirstLoginPersonStyle.validation}>*닉네임을 입력해주세요</p> : null}
                            </section>
                        </div>
                        <button
                            onClick={() => {
                                Next();
                            }}
                        >다음</button>
                    </div>











                </div>
            }
            {FirstLoginCompanyM && <FirstLoginCompany
                phone={phone}
                nickname={nickname}

                FirstLoginCompanyM = {FirstLoginCompanyM}
                setFirstLoginCompanyM = {setFirstLoginCompanyM}
                FirstLoginPersonM = {FirstLoginPersonM}
                setFirstLoginPersonM = {setFirstLoginPersonM}
                conditions={conditions}
                setConditions={setConditions}

                setLastCheck={setLastCheck}
                kakaoInform={kakaoInform}
                appleInform={appleInform}
                isFirst={isFirst}
            />}


        </>
    );
};

export default FirstLoginPerson;
