import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import * as api from '../lib/api'
import styles from "scss/components//Banner.scss";
import {setLoginCheck, setMarketingCheck} from "../redux/store";

const ImageBanner = () => {

  const [banner, setBanner] = useState([]);
  const isWebApp = useSelector((state) => state.isWebApp);
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const dispatch = useDispatch();
  const getBannerData = () => {
    api.getBannerList({banner_type : 'web'}).then((res) => {
      setBanner(res.data);
    })
  };

  const swiperParam = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: "#pagination",
    },
  };

  useEffect(() => {
    getBannerData();
  }, []);

  const bannerBtn = (isNotice, img) => {

    let data = null;
    if(window.location.toString().indexOf("community") > 0){
      data = {
        "tl_page_type" : "커뮤니티",
        "tl_page_depth" : "2",
        "tl_page_name" : "광고배너",
        "tl_button_name" : "배너터치-O",
        "tl_type_cd" : "0",
        "tl_event" : "배너이동-"+img.banner_title,
        "tl_memo" : img.index
      }
    }else{
      data = {
        "tl_page_type" : "메인화면",
        "tl_page_depth" : "1",
        "tl_page_name" : "광고배너",
        "tl_button_name" : "배너터치-O",
        "tl_type_cd" : "0",
        "tl_event" : "배너이동-"+img.banner_title,
        "tl_memo" : img.index
      };
    }
    api.insertTimeline(data, Object.keys(userInfo).length > 0 ? userInfo.id : 'guest');

    if(isWebApp){
      if (window && window.flutter_inappwebview) window.flutter_inappwebview.callHandler("bannerLinkF", img.banner_ctg, img.banner_link, img.banner_noti_idx);
    }else{
      isNotice  ? window.open(`/notice/noticeView/${img.banner_noti_idx}`, "_blank" ) :
          img.banner_link != "" ? window.open(img.banner_link, "_blank") : window.open('/notice/noticeList');
    }
  }

  return (
      <>
        <div className="Banner">
          <Swiper
              modules={[Autoplay, Pagination]}
              autoplay={swiperParam.autoplay}
              pagination={swiperParam.pagination}
              className="swiper"
              loop={true}
              initialSlide={1}
          >
            {banner &&
                banner.map((img, idx) => {
                  const isNotice = img.banner_noti_idx != null;
                  return (
                      <SwiperSlide className="slide" key={idx}>
                        <button
                            onClick={(e) => {
                              if (!isLoggedIn) {
                                e.preventDefault();
                                dispatch(setLoginCheck(true));
                                return false;
                              }
                              if(isChkMarketing && isLoggedIn){
                                e.preventDefault();
                                dispatch(setMarketingCheck(true));
                                return false;
                              }
                              bannerBtn(isNotice,img);
                            }}
                        >
                          <img src={img.banner_img} alt={img.banner_title} />
                        </button>
                      </SwiperSlide>
                  );
                })}
          </Swiper>
          <div id="pagination" className="pagination"></div>
        </div>
      </>
  );
};
export default ImageBanner;
