import React, { useEffect } from "react";
import MyDeliver from "components/myPage/MyDeliver";
import styles from "scss/pages/Community.module.scss";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import MobileTitle from "components/MobileTitle";
const DeliverHst = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const postLimit = 30;
  useEffect(() => {
    const searchTxt = location.search;
    let searchObj = {};
    const searchArr = searchTxt.replace("?", "").split("&");
    let pageDummy = "";
    searchArr.forEach((v) => {
      const arrObj = v.split("=");
      searchObj[arrObj[0]] = decode(arrObj[1]);
    });
    if (searchObj.page == undefined) {
      setPage(1);
      pageDummy = 1;
    } else {
      setPage(parseInt(searchObj.page));
      pageDummy = parseInt(searchObj.page);
    }
    window.scrollTo(0, 0);
  }, [location]);

  function decode(txt) {
    return decodeURI(txt);
  }
  return (
    <>
      <div className={styles.CommunityList} id="communityList">
        <MobileTitle title={"이메일 정기배송 이력"} />
        <div className={`commonTitleWrap ${styles.titleArea}`}>
          <div className={`${styles.inner} inner`}>
            <div className={styles.leftArea}>
              <h3 className={`title ${styles.title}`}>이메일 정기배송 이력</h3>
              <p>이메일 정기배송 이력을 확인해 보세요.</p>
              <p>기업 정보는 마이페이지에서 변경할 수 있습니다.</p>
            </div>
          </div>
        </div>
        <div className={styles.CommunityListContent}>
          <div className="inner">
            <MyDeliver page={page} postLimit={postLimit} />
          </div>
        </div>
      </div>
    </>
  );
};
export default DeliverHst;
