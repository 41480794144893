import axios from 'axios';


// const url = process.env.REACT_APP_DEV_URL;
//TODO:data 없을 경우, null값 지정안하고 config를 넣을수 있는 방법 찾아보기
// const url = 'https://dev.exitobiz.co.kr:8443'
// const url = 'http://localhost:9090'
const url = 'https://api.exitobiz.co.kr'

// -------- 메인페이지 ---------
export const getPushList = (data, user_id, cancel_token) => {
    return axios.post(url + '/mainpage/getPushBookList', data, { headers: {user_id : user_id} , cancelToken : cancel_token});
}

export const getPopularList = (data, user_id, cancel_token) => {
    return axios.post(url + '/mainpage/getPopularList', data, {headers : {user_id : user_id}, cancelToken : cancel_token});
}

export const getSupportableCnt = (user_id) => {
    return axios.post(url + '/mainpage/getSupportableCnt', null, {headers : {userid: user_id}});
}

export const getBannerList = (data) => {
    return axios.post(url + '/mainpage/getBannerList', data, {headers : {"Access-Control-Allow-Origin": "strict-origin-when-cross-origin"}});
}

export const getKeyword = (user_id) => {
    return axios.post(url + '/mainpage/getKeyword', null, {headers : {user_id : user_id}});
}

export const insertKeyword = (data, user_id) => {
    return axios.post(url + '/mainpage/insertKeyword', data, {headers : {user_id : user_id}});
}

export const delKeyword = (data, user_id) => {
    return axios.post(url + '/mainpage/delKeyword', data, {headers: {user_id : user_id}});
}

export const getMyRecentKeyword = (user_id) => {
    return axios.post(url + '/mainpage/getMyRecentKeyword', null, {headers : {user_id : user_id}});
}

export const delMyRecentKeyword = (data, user_id) => {
    return axios.post(url + '/mainpage/delMyRecentKeyword', data, {headers : {user_id : user_id}});
}

export const delAllMyRecentKeyword = (user_id) => {
    return axios.post(url + '/mainpage/delMyAllRecentKeyword', null, {headers : {user_id : user_id}});
}

export const getSearchHotKeyword = () => {
    return axios.post(url + '/mainpage/getSearchHotKeyWord');
}

export const getMainTotalCnt = (cancel_token) => {
    return axios.post(url + '/mainpage/getTotalCount', null, {headers : {"Access-Control-Allow-Origin": "strict-origin-when-cross-origin"}, cancelToken : cancel_token});
}

export const getEmailDeliver = (user_id) => {
    return axios.post(url + '/mainpage/getEmailDeliver', null, {headers : {user_id : user_id}});
}

export const insertEmailDeliver = (data, user_id) => {
    return axios.post(url + '/mainpage/insertEmailDeliver', data, {headers : {user_id : user_id}});
}







export const authEmail = (data, user_id) => {
    return axios.post(url + '/mainpage/authEmail', data,{headers : {user_id : user_id}});
}

export const checkAuth = (data, headers) => {
    return axios.post(url + '/mainpage/checkAuth', data, {headers : headers});
}

export const chkMarketPush = (user_id) => {
    return axios.post(url + '/user/chkMarketPush', null, {headers: { userid : user_id }});
}








// -------- 커뮤니티 ---------
export const getCommList = (params, user_id) => {
    return axios.get(url + '/mobile/community/all' + params, {headers: {user_id: user_id}});
}

export const getCommPopular = (user_id, cancel_token) => {
    return axios.post(url + '/mobile/community/popularAll', null, {headers : {user_id : user_id}, cancelToken : cancel_token});
}

export const getCommTotalCnt = (param) => {
    return axios.post(url + '/mobile/community/totalCnt', param);
}

export const getCommDetail = (id, user_id) => {
    return axios.get(url + `/mobile/community/one?id=${id}`,  {headers : {user_id : user_id}});
}

export const updateCommViewCnt = (data) => {
    return axios.post(url + '/mobile/community/viewCntUp', data);
}

export const getCommReply = (data, user_id) => {
    return axios.post(url + '/mobile/community/comment', data,{headers: {user_id: user_id}});
}

export const getCommRereply = (data, user_id) => {
    return axios.post(url + '/mobile/community/recomment', data, {headers : {user_id:user_id}});
}

export const getCommFile = (data) => {
    return axios.post(url + '/mobile/community/getFile', data);
}

export const insertCommReply = (data, user_id) => {
    return axios.post(url + '/mobile/community/insertComment', data, {headers : {user_id: user_id}});
}

export const updateCommReply = (data) => {
    return axios.post(url + '/mobile/community/updateComment', data);
}

export const delCommReply = (data) => {
    return axios.post(url + '/mobile/community/delComment', data);
}

export const insertCommReplyLike = (data, user_id) => {
    return axios.post(url + '/mobile/community/insertCommentLike', data, {headers : {user_id : user_id}});
}

export const pushComm = (data) => {
    axios.post(url + '/push/sendOne', data);
}

export const updateLike = (data, user_id) => {
    return axios.post(url + '/mobile/community/like', data, {headers : {user_id : user_id}});
}

export const delComm = (data) => {
    return axios.post(url + '/mobile/community/delete', data, {headers: { "Content-Type": "application/json" }});
}

export const insertCommBlock = (headers) => {
    return axios.post(url + '/mobile/community/insertBlock', null, { headers : headers});
}

export const getCommBlockList = (user_id) => {
    return axios.post(url + '/mobile/community/blockAll', null, {headers : {user_id : user_id}});
}

export const delCommBlock = (data) => {
    return axios.post(url + '/mobile/community/delBlockUser', data);
}

export const delCommAllBlock = (user_id) => {
    return axios.post(url + '/mobile/community/delAllBlockUser', null,{headers : {user_id : user_id}});
}

export const insertReport = (data, headers) => {
    return axios.post(url + '/mobile/community/insertReport', data, {headers : headers});
}

export const insertComm = (formData) => {
    return axios.post(url + '/mobile/community/', formData, {headers : {"Content-Type": "multipart/form-data"}});
}

export const insertCommFile = (formData) => {
    return axios.post(url + '/mobile/community/uploadFile', formData, {headers: { "Content-Type": "multipart/form-data"}});
}

export const updateComm = (formData) => {
    return axios.put(url + '/mobile/community/edit', formData, {headers: {"Content-Type": "multipart/form-data"}});
}

export const getMyComm = (user_id) => {
    return axios.post(url + '/mobile/community/myContent', null, {headers : {user_id : user_id}});
}

export const getMyReply = (user_id) => {
    return axios.post(url + '/mobile/community/myComment', null, {headers : {user_id : user_id}});
}


// -------- 공지 ---------
export const getNoticeList = () => {
    return axios.get(url + '/cms/notice/api');
}


// -------- 지원사업 ---------
export const getSupportList = (data, user_id) => {
    return axios.post(url + '/support/getSupportInfoList', data, {headers : {user_id : user_id, "Access-Control-Allow-Origin" : "strict-origin-when-cross-origin"}});
}

export const updateSupportViewCnt = (id) => {
   return axios.post(url + `/mainpage/upViewCnt?si_idx=${id}`);
}


// -------- 찜 ---------
export const getZZimRecently = (data, user_id) => {
    return axios.post(url + '/saved/getRecentlyMySavedBook', data, {headers : {user_id : user_id}});
}

export const updateZzimStatus = (data, user_id) => {
    return axios.post(url + '/saved/isSavedMyBook', data, {headers : {user_id : user_id}});
}

export const getTotalCount = (user_id) => {
    return axios.post(url + '/saved/getTotalCountList', null, {headers: { user_id : user_id }});
}

export const getCtgList = (user_id) => {
    return axios.post(url + '/saved/getCatList', null, {headers : {user_id : user_id}});
}

export const getSavedTotalCnt = (user_id) => {
    return axios.post(url + '/saved/getSavedTotalCount', null, {headers: {user_id : user_id}});
}

export const getMySavedBook = (data, user_id) => {
    return axios.post(url + '/saved/getMySavedBook', data, {headers : {user_id : user_id}});
}

export const isDonSavedMyBook = (data, user_id)=> {
    return axios.post(url + '/saved/isDoneSavedMyBook', data, {headers : {user_id : user_id}});
}

export const isReqSavedMyBook = (data, user_id) => {
    return axios.post(url + '/saved/isReqSavedMyBook', data, {headers : {user_id : user_id}});
}

export const getUserNeed = (user_id) => {
    return axios.post(url + '/saved/getUserNeed', null, {headers:{user_id : user_id}});
}

export const updateUserNeed = (data, user_id) => {
    return axios.post(url + '/saved/updateUserNeed', data, {headers : {user_id : user_id}});
}


// -------- 마이페이지 ---------
export const getCompanyInfo = (user_id) => {
    return axios.post(url + '/user/getCompanyInfo', null, { headers : {userId : user_id}} );
}

export const updateCompanyInfo = (params, user_id) => {
    return axios.post(url + '/user/updateCompanyInfo' ,params,  {headers : {userId : user_id}});
}

export const checkNickname  = (nickname) => {
    return axios.post(url + '/user/checkNickname', null, {headers: {usernickname : nickname}});
}

export const getUserInfo = (user_id) => {
    return axios.post(url + '/user/getUserInfo', null, {headers : {userId: user_id}});
}

export const updateUserInfo = (params, user_id) => {
    return axios.post(url +  '/user/updateUserInfo?' ,params,  {headers: {userid : user_id}});
}

export const withdrawUserInfo = (user_id) => {
    return axios.post(url + '/user/withdraw', null, {headers : {userId : user_id}});
}

export const revokeApple = (appleToken) => {
    axios.post(url + '/mainpage/revokeApple', null, {headers : {access_token : appleToken, os : "web"}});
}

export const getPushSetting = (user_id) => {
    return axios.post(url + '/user/getPushSetting', null, {headers : {userId : user_id}});
}

export const updatePushSetting = (data, user_id) => {
    return axios.post(url + '/user/updatePushSetting', data, {headers : {userId : user_id}});
}

export const getMyEmailDeliver = (user_id) => {
    return axios.post(url + '/mainpage/getMyEmailDeliver', null, {headers:{user_id : user_id}})
}


// -------- 로그인 ---------
export const getAppleToken = (code) => {
    return axios.post(url + '/mainpage/getAppleToken', null, {headers : {code : code, os :"web"}});
}

export const kakaoLogin = (headers) => {
    return axios.post(url + '/kakao/login', null, {headers : headers});
}


// -------- 공통 ---------
export const insertTimeline = (data, user_id) => {
   return axios.post(url + '/mainpage/insertTimeLine', data, {headers : {user_id : user_id}});
}

export const getCodeDtlList = (param) => {
    return axios.post(url + '/common/codeDtlList?' + param);
}

export const getCommonCode = (code_nm) => {
    return axios.post(url + `/common/getCommonCode?codeNm=${code_nm}`);
}

// -------- 현대 ---------

export const getCorpSearchHotKeyword = (data) => {
    return axios.post(url + '/corp/getSearchHotKeyWord', data);
}

export const insertCorpTimeLine = (data, user_id) => {
    return axios.post(url + '/corp/insertTimeLine', data, {headers : {user_id :user_id}});
}

export const getCorpSupportList = (data, headers) => {
    return axios.post(url + '/corp/getSupportInfoList', data, {headers : headers });
}

export const updateCorpViewCnt = (idx) => {
    return axios.post(url + `/corp/upViewCnt?si_idx=${idx}`);
}

export const getCorpTotalCnt = (data, cancel_token) => {
    return axios.post(url + '/corp/getTotalCount', data, {cancelToken : cancel_token});
}

export const updateCorpCompanyInfo = (data, user_id) => {
    return axios.post(url + '/corp/updateCorpCompanyInfo', data, {headers: {user_id : user_id}});
}

export const getCorpCompanyInfo = (data, user_id) => {
    return axios.post(url + '/corp/getCorpCompanyInfo', data, {headers:{user_id : user_id}});
}

export const getCorpContentslist = (data) => {
    return axios.post(url + '/corp/getContentInfo', data );
}

export const getCorpMyRecentKeyword = (data,user_id) => {
    return axios.post(url + '/corp/getMyRecentKeyword', data, {headers : {user_id : user_id}});
}

//찜 사업 리스트
export const getCorpMySavedBook = (data, headers) => {
    return axios.post(url + '/corp/saved/getMySavedBook', data, {headers : headers});
}

//찜 분류 개수 리스트
export const getCorpCtgList = (headers) => {
    return axios.post(url + '/corp/saved/getCatList', null , {headers:headers});
}

//찜 플래그 변경
export const getIsSavedMyBook = (data, headers) => {
    return axios.post(url + '/corp/saved/isSavedMyBook', data, {headers : headers});
}

export const insertUserLog = (data,user_id) => {
    return axios.post(url + '/corp/insertUserLog', data, {headers : {user_id : user_id}});
}

export const saveRegist = (data, headers) => {
    return axios.post(url + '/corp/edu/saveRegist', data, {headers : headers});
}