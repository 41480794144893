import React, {useEffect} from "react";
import H_details from "scss/components/Hyundai_detail.module.scss";


function H_detail(props){
    return(
        <div className={H_details.H_detail_wrap}>
            <section>
                <h1>2022년 3차 신재생 에너지산업 해외진출지원사업 지원계획 공고</h1>
                <table>

                </table>
            </section>
            <section>
                <h1>[사업개요]</h1>
                <p>경북 그린바이오산업 대표 분야인 식품 헴프관련 분야를 취업을 희망하시는 청년(구직)과 연구개발을 희망하는 기업(구인)의 지역정착 및 제조기술 강화를 위하여 연구원 내 공유랩 , 공유오피스를 활용한 [연구기관 연계형 R&D청년 일자리 지원사업] 참여기업을 아래와 같이 모집합니다.</p>
            </section>
            <section>
                <h1>[지원자격]</h1>
                <ul>
                    <li>경상북도 그린바이오 [농,식품 / 햄프(HEMP) 분야의 중소기업]</li>
                    <li>경북 북부권 및 참여청년 즉시채용 가능 기업 우선지원</li>
                </ul>
            </section>
            <section>
                <h1>[지원내용]</h1>
                <table>

                </table>
                <dl>
                   <dt>참여청년 인센티브 지급: 아래의 조건을 모두 충족시 분기별 금 250만원(1년간 최대 1,000만원)</dt>
                    <dd>- 지역 내 본 사업에 참여하는 사업장에서 2년 근무 후 정규직 유지(전환) 시</dd>
                    <dd>- (사업종료 후 3개월 내) 청년이 경북지역 내에서 정규직 유지(전환) 및 창업 시</dd>
                    <dd>- 단, 청년내일채움공제 정부지원금 대상자는 인센티브 중복지원 불가</dd>
                </dl>
            </section>
            <section>
                <h1>[신청방법 및 서류]</h1>
                <dl>
                    <dt>신청방법: 이메일 접수(), 우편 또는 방문 접수</dt>
                    <dd>(경북 안동시 풍산옴 산업단지길 88, 기업지원팀)</dd>
                    <dt>접수 및 문의처: 경북바이오산업연구원 기업지원팀(054-850-6813,6849)</dt>
                </dl>
            </section>
            <section>
                <h1>[문의처]</h1>
                <p>경북바이오산업연구원 기업지원팀 / 054-850-6813 / www.gib.re.kr</p>
            </section>
            <button>기관 공고문 보기</button>
        </div>
    )
}

export default H_detail;