import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BoxListItemHome from "components/home/BoxListItemHome";
import styles from "scss/pages/Home.module.scss";
import * as api from '../../lib/api';

const HomeSupport = ({}) => {

  let cancel;
  const CancelToken = axios.CancelToken;
  const userInfo = useSelector((state) => state.userInfo);
  const [homeSupport, setHomeSupport] = useState([
    {
      category: "엑시토추천",
      item: [],
      url: "https://api.exitobiz.co.kr" + "/mainpage/getPushBookList",
      cat_name: "엑시토추천",
    },
    {
      category: "찜인기",
      item: [],
      url: "https://api.exitobiz.co.kr" + "/mainpage/getPopularList",
      cat_name: "찜",
    },
    {
      category: "실시간인기",
      item: [],
      url: "https://api.exitobiz.co.kr" + "/mainpage/getPopularList",
      cat_name: "실시간",
    },
    {
      category: "예비창업자 대상",
      item: [],
      url: "https://api.exitobiz.co.kr" + "/mainpage/getPopularList",
      cat_name: "예비창업자",
    },
  ]);

  const getHomeSupport = (category, url, cat_name) => {
    let data = {cat : cat_name};
    let result;
    if(category == '엑시토추천'){
      result = api.getPushList(data, userInfo.id, new CancelToken(function executor(c) {  cancel = c; }));
    }else{
     result = api.getPopularList(data, userInfo.id,  new CancelToken(function executor(c) {  cancel = c; }));
    }

    result.then((res) => {
      const data = res.data;
      const copy = [...homeSupport];
      copy.find((item) => item.category == category).item = data.map((v) => v);
      setHomeSupport(copy);
    })
  };

  useEffect(() => {
    homeSupport.forEach((v, i) => {
      getHomeSupport(v.category, v.url, v.cat_name);
    });
    return () => { cancel(); };
  }, []);

  return (
      <>
        <div className={styles.HomeSupport}>
          {homeSupport.map((item, idx) => {
            return (
                <div key={item.category} className={styles.supportBox}>
                  <h4 className={styles.supportCate}>{item.category}</h4>
                  <div className={styles.supportList}>
                    {item.item.slice(0, 4).map((list, idx) => (
                        <BoxListItemHome
                            getHomeSupport={getHomeSupport}
                            category={item}
                            key={idx}
                            item={list}
                            viewShow={true}
                            commentShow={false}
                            likeShow={false}
                            writerShow={false}
                        />
                    ))}
                  </div>
                </div>
            );
          })}
        </div>
      </>
  );
};
export default HomeSupport;
