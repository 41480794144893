import React, { useState } from "react";
import styles from "scss/components/Header.module.scss";
import searchFormStyle from "scss/components/SearchForm.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  signOut,
  removeUserInfo,
  setLoginCheck, setMarketingCheck,
} from "redux/store";

import { KAKAO_REST_API_KEY, KAKAO_LOGOUT_REDIRECT_URI } from "pages/login/LoginData";
import SearchForm from "components/SearchForm";

const statsIco = {position:"absolute" , left: "-70%", bottom:"-6px"};
const flex = {display:"flex" ,position: "relative",width:'40%'};

const Header = ({}) => {

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const isMobile = useSelector((state) => state.isMobile);
  const isWebApp = useSelector((state) => state.isWebApp);
  const isAdmin = useSelector((state) => state.isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const handleLogin = () => {
    dispatch(setLoginCheck(true));
  };

  const temporarysignOut = () => {
    dispatch(removeUserInfo());
    dispatch(signOut());
    sessionStorage.removeItem("isAdmin");
  };

  const showStats = () => {
    const oAuthType = sessionStorage.getItem("oAuthType");
    if (window && window.flutter_inappwebview) window.flutter_inappwebview.callHandler('goToAdminF', 'goToAdminF', oAuthType);
  }

  const handleLogout = () => {
    sessionStorage.setItem("kakaoRedirectPath", window.location.pathname);
    const oAuthType = sessionStorage.getItem("oAuthType");

    if (window.confirm("로그아웃 하시겠습니까?")) {
      if(window && window.flutter_inappwebview) window.flutter_inappwebview.callHandler('logoutF', 'logout', oAuthType);
      if (oAuthType == "kakao") {
        //카카오
        window.location.href = `https://kauth.kakao.com/oauth/logout?client_id=${KAKAO_REST_API_KEY}&logout_redirect_uri=${KAKAO_LOGOUT_REDIRECT_URI}`;
      } else if (oAuthType == "apple") {
        temporarysignOut();
        navigate("/");
      }
    }
  };

  return (
      <>
        <div className={styles.Header}>
          <h1 className={styles.headerLogo}>
            <Link to="/">
              <img src={require("assets/img/LOGO1.png")} alt="EXITO LOGO" />
            </Link>
          </h1>
          <nav>
            <ul>
              <li>
                <Link
                    to="/saved?cate=save"
                    onClick={(e) => {
                      if (!isLoggedIn) {
                        e.preventDefault();
                        dispatch(setLoginCheck(true));
                        return false;
                      }
                      if(isChkMarketing && isLoggedIn){
                        e.preventDefault();
                        dispatch(setMarketingCheck(true));
                        return false;
                      }
                      navigate(e.currentTarget.getAttribute("to"));
                    }}
                >
                  찜
                </Link>
              </li>
              <li>
                <Link
                    to="/support/supportList"
                    onClick={(e) => {
                      if (!isLoggedIn) {
                        e.preventDefault();
                        dispatch(setLoginCheck(true));
                        return false;
                      }
                      if(isChkMarketing && isLoggedIn){
                        e.preventDefault();
                        dispatch(setMarketingCheck(true));
                        return false;
                      }
                      navigate(e.currentTarget.getAttribute("to"));
                    }}
                >
                  지원사업
                </Link>
              </li>
              <li>
                <Link
                    to="/community/communityList"
                    onClick={(e) => {
                      if (!isLoggedIn) {
                        e.preventDefault();
                        dispatch(setLoginCheck(true));
                        return false;
                      }

                      if(isChkMarketing && isLoggedIn){
                        e.preventDefault();
                        dispatch(setMarketingCheck(true));
                        return false;
                      }
                      navigate(e.currentTarget.getAttribute("to"));
                    }}
                >
                  커뮤니티
                </Link>
              </li>
              <li>
                <Link
                    to="/notice/noticeList"
                    onClick={(e) => {
                      if (!isLoggedIn) {
                        e.preventDefault();
                        dispatch(setLoginCheck(true));
                        return false;
                      }
                        if(isChkMarketing && isLoggedIn){
                            e.preventDefault();
                            dispatch(setMarketingCheck(true));
                            return false;
                        }
                      navigate(e.currentTarget.getAttribute("to"));
                    }}
                >
                  공지사항
                </Link>
              </li>
            </ul>
          </nav>
          <div style={flex}>
          { isWebApp && isAdmin ? (
              <img style={statsIco}
                  src={require("assets/img/global/ico/ico_stats.png")}
                  alt="stats"
                  className={styles.myPageImg}
                  onClick={() =>showStats()}
              />
          ) : (null)
          }
          {isMobile && (
            <button
                  className={styles.btnMobileSearch}
                  onClick={() => {
                    setMobileSearchOpen((prev) => !prev);
                  }}
              >
                <img
                    src={require("assets/img/global/ico/ico_search_black.png")}
                    alt="모바일 서치폼 오픈"
                />
              </button>
          )}

          {!isMobile ? (
              <SearchForm styles={searchFormStyle} />
          ) : (
              mobileSearchOpen && (
                  <SearchForm
                      styles={searchFormStyle}
                      mobileSearchOpen={mobileSearchOpen}
                      setMobileSearchOpen={setMobileSearchOpen}
                  />
              )
          )}
          <div className={styles.loginArea}>
            {isLoggedIn ? (
                <>

                  <Link to="/myPage">
                    {isMobile ? (
                        <img
                            src={require("assets/img/global/ico/ico_user_black.png")}
                            alt="myPage"
                            className={styles.myPageImg}
                        />
                    ) : (
                        <img
                            src={require("assets/img/global/ico/ico_user.png")}
                            alt="myPage"
                            className={styles.myPageImg}
                        />
                    )}
                  </Link>
                  <button
                      type="button"
                      onClick={handleLogout}
                      className={styles.btnLogout}
                  >
                    로그아웃
                  </button>
                </>
            ) : (
                <>
                  <button onClick={handleLogin} className={styles.btnLogIn}>
                    로그인
                  </button>
                </>
            )}
          </div>
        </div>
        </div>
      </>
  );
};
export default Header;