import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import H_notice from "components/Hyundai/H_notice";
import H_Support from "../../components/Hyundai/H_Support";
import H_list from "components/Hyundai/H_list";
import H_SearchForm from "../../components/Hyundai/H_SearchForm";
import {corpSignIn, corpSignOut, loadingEnd, loadingStart, setCompanyInfo, setSupportInfoModal, signIn} from "../../redux/store";
import axios from "axios";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {setSupportData} from "redux/store";
import * as api from '../../lib/api';
import H_friends from "../../components/Hyundai/H_friends";
import {getCorpContentslist, getCorpCtgList, getCorpMySavedBook, getIsSavedMyBook} from "../../lib/api";
import styles from "../../scss/components/H_friendsList.module.scss";
import NoticeListItem from "../../components/notice/NoticeListItem";
import H_SavedChart from "../../components/Hyundai/H_SavedChart";
import Banner from "components/Hyundai/Banner";
import Hashtag from "../../components/Hyundai/Hashtag";



const contentDIV = {display:"flex",paddingTop:"20px",boxSizing: "border-box", width:"84%", margin: "0 auto"};
const contentMain = {width:"calc(80% - 50px)", marginLeft:"50px"};
const SupportFilterDiv = {boxSizing: "border-box",width:"20%"};



const Hyundai_p = ({

                   }) => {
    const [contentY, setContentY] = useState([]);
    const [interestCount, setInterestCount] = useState(0);


    const [searchValSet, setSearchValSet] = useState('');

    const [Energy, setEnergy] = useState(false);
    const [Environment, setEnvironment] = useState(false);
    const [Manufacturing, setManufacturing] = useState(false);
    const [factory, setfactory] = useState(false);
    const [ESG, setESG] = useState(false);
    const [RD, setRD] = useState(false);
    const [ICT, setICT] = useState(false);
    const [Bio, setBio] = useState(false);


    const [hashState , setHashState] = useState(true);


    let cancel;
    const CancelToken = axios.CancelToken;
    const supportItem = useSelector((state) => state.supportItem);
    const isCorpLogIn = useSelector((state) => state.isCorpLogIn);

    // const searchParams = new URLSearchParams(location.search);
    // let keywordParam = searchParams.get("keyword");

    const {user_id} = useParams();
    const {corp_nm} = useParams();

    const location = useLocation();
    const allSupportCache = isTrue(
        sessionStorage.getItem("allSupportCache") ?? true
    );
    const [allSupport, setAllSupport] = useState(allSupportCache);
    const path = window.location.toString().toLowerCase().indexOf("/corp/hyundai/");



    function isTrue(target) {
        return target == "true";
    }

    const [count, setCount] = useState({
        total_cnt: 0,
        week_cnt: 0,
        target_cnt: 0,
    });


    useEffect(() => {
        //회사명만 있을 경우 전체를 보여준다.
        if (corp_nm != null) sessionStorage.setItem("corpNm", corp_nm);

        //유저명이 있으면 filter 적용
        if(user_id != undefined){
            sessionStorage.setItem("corpId", user_id);
            dispatch(corpSignIn())
        }else{
            sessionStorage.setItem("corpId", "");
            dispatch(corpSignOut())
        }

    }, [location]);


    // 누적 사업공고, 이번주 사업공고 , 정보제공기관 API 호출
    const getCount = async () => {
        //TODO : 회사코드 API 한번만 호출해서 새로고침 시, 값 변경 적용될 수 있도록 로직 변경하기
        let res = await api.getCommonCode(corp_nm.toLowerCase());
        let params = {corp_cd: res.data.code};
        api.getCorpTotalCnt(params, new CancelToken(function executor(c) {
            cancel = c
        })).then((res) => {
            let data = res.data[0];
            setCount(data);
        })
    };

    useEffect(() => {
        getCount();

        // return () => { cancel(); };
    }, [corp_nm]);


    useEffect(() => {
        if (isCorpLogIn) {
            setAllSupport(false);
        } else {
            setAllSupport(true);
        }
    }, [isCorpLogIn]);

    useEffect(() => {
        sessionStorage.setItem("allSupportCache", allSupport);
    }, [allSupport]);
    useEffect(() => {
        setFirstMount(false);
        return () => {
            sessionStorage.removeItem("allSupportCache", allSupport);
        };
    }, []);

    const [totalCount, setTotalCount] = useState({});
    const userInfo = useSelector((state) => state.userInfo);

    const [count1, setCount1] = useState(0);
    const url = new URLSearchParams(location.search);
    const [cate, setCate] = useState( "");



    const [ord, setOrd] = useState("전체");
    const [keyword, setKeyword] = useState("");
    const [firstMount, setFirstMount] = useState(true);
    const [page, setPage] = useState(1);
    const [isCheckingBox, setIsCheckingBox] = useState(false);


    //H-friends

    const [startIndex, setStartIndex] = useState(0);
    const sectionsToShow = 4;
    const sectionCount = contentY.length;

    const getContentData = async () => {
        let res = await api.getCommonCode(corp_nm.toLowerCase());
        const contentRes = await api.getCorpContentslist({corp_cd: res.data.code});
        setContentY(contentRes.data);
    };

    useEffect(() => {
        setCate(url.get("cate") || "government");
        getContentData();
    }, []);


    const handleNextClick = () => {
        let newStartIndex = startIndex + 4;
        if (newStartIndex != 0 && (sectionCount / newStartIndex) <= 1) {
            newStartIndex = 0;
        }
        setStartIndex(newStartIndex);
    };

    const handlePrevClick = () => {
        let newStartIndex = startIndex - 4;
        if (newStartIndex < 0) {
            newStartIndex = Math.floor(sectionCount / 4) * 4;
        } else if (newStartIndex + 4 > sectionCount) {
            newStartIndex = sectionCount - 4;
        }
        setStartIndex(newStartIndex);
    };


    const handleNext = useCallback((startIndex, sectionCount) => {
        let newStartIndex = startIndex + 4;
        if (newStartIndex != 0 && (sectionCount / newStartIndex) <= 1) {
            newStartIndex = 0;
        }

        // const newStartIndex = (startIndex + 4) % sectionCount;
        setStartIndex(newStartIndex);

    }, []);

    const slide = document.querySelector(".innerWrap");


    useEffect(() => {
        if (sectionCount <= sectionsToShow) {
            return;
        }
        // 자동 슬라이드
        // const timer = setTimeout(() => {
        //     handleNext(startIndex, sectionCount);
        //     // slide.style.transform = "transform 2s ease-out"
        // }, 3000);
        // return () => clearTimeout(timer);
        // 자동 슬라이드 끝
    }, [startIndex, sectionCount, handleNext, sectionsToShow]);

    useEffect(() => {
        //데이터 반환
        const searchTxt = decodeURI(location.search);

        sessionStorage.setItem("s_currentSearch", searchTxt);
        let searchObj = {};
        const searchArr = searchTxt.replace("?", "").split("&");
        let ordDummy = "";
        let pageDummy = "";
        let countDummy = "";
        let keywordDummy = "";
        let viewDummy = "";
        let cateDummy = "";

        searchArr.forEach((v) => {
            const arrObj = v.split("=");
            searchObj[arrObj[0]] = arrObj[1];
        });
        if (searchObj.ord == undefined) {
            ordDummy = "전체";
        } else {
            ordDummy = searchObj.ord;
        }

        if(searchObj.cate == undefined){
            cateDummy = "government";
        }else{
            cateDummy = searchObj.cate;
        }

        if (searchObj.page == undefined) {
            pageDummy = 1;
        } else {
            pageDummy = parseInt(searchObj.page);
        }
        if (searchObj.view != undefined) {
            viewDummy = searchObj.view;
            // setCount(viewDummy);
        }
        if (searchObj.keyword == undefined) {
            keywordDummy = "";
        } else {
            keywordDummy = searchObj.keyword;
        }
        if (searchObj.count == undefined) {
            countDummy = 30;
        } else {
            countDummy = searchObj.count;
        }

        if (
            ordDummy != ord ||
            keywordDummy != keyword ||
            // firstMount ||
            searchTxt == "" ||
            cate
        ) {
             if(cate.length>0) getSupportCont(ordDummy, keywordDummy, cateDummy);
        }

        setOrd(ordDummy);
        setPage(pageDummy);
        setCate(cateDummy);
        setKeyword(keywordDummy);
    }, [location]);


    const dispatch = useDispatch();
    let axiosCount = 0;
    const [mobilePage, setMobilePage] = useState(1);
    const supportInfo = useSelector((state) => state.supportInfo);

    const getSupportCont = async (ord, keyword, cate, loading = true) => {
        const thisCount = axiosCount;
        axiosCount = axiosCount + 1;

        if (loading) dispatch(loadingStart());
        if (keyword == '' || keyword == undefined) {
            var obj = {
                company_type: supportInfo.biz_type_cd.datas.map((v) => v.code).toString(), //기업형태 biz_type_cd
                target_cat_name: supportInfo.spt_cd.datas.map((v) => v.code).toString(), //지원분야 spt_cd
                loc_code: supportInfo.loc_cd.datas.map((v) => v.code).toString(), // 지역 loc_cd
                ord: ord,
                end_dt: (!isCheckingBox).toString(),
                keyword: keyword
            };
        } else {
            var obj = {
                ord: ord,
                end_dt: (!isCheckingBox).toString(),
                keyword: keyword
            };
        }
        let res = await api.getCommonCode(corp_nm.toLowerCase());

        let savedParams = {ord : ord};
        let headers = {
            user_id : user_id,
            corp_cd : res.data.code
        }

        var result;

        if(cate == 'government' || cate == ''){
            result = await api.getCorpSupportList(obj, headers);
            dispatch(setSupportData(result.data));
        }else{
            result = await api.getCorpMySavedBook(savedParams, headers);
            setInterestCount(result.data.filter(v => v.mb_save_yn == "Y").length);
            dispatch(setSupportData(result.data));
        }



         // if (thisCount + 1 == axiosCount)

            dispatch(loadingEnd());

        setMobilePage(1);
        sessionStorage.removeItem("s_mo_page");

    };


    useEffect(() => {
        if(cate == "government"){
            setHashState(false)
        }
        if(cate == "interest"){
            setHashState(true)
        }
    }, []);


    const totalSectionsToShow = sectionCount;
    const navigate = useNavigate();
    const cateClick = (e) => {


        if(cate == "government"){
            setHashState(false)
        }
        if(cate == "interest"){
            setHashState(true)
            setEnergy(false)
            setEnvironment(false)
            setManufacturing(false)
            setfactory(false)
            setESG(false)
            setRD(false)
            setICT(false)
            setBio(false)

        }

        const {currentTarget: {value}} = e;
        setCate(value); // cate 상태 업데이트
        navigate(`?cate=${value}`);
    };

    if(path < 0){
        navigate("/");
    }

    let baseCatName = [
        { code: "01", name: "전체", order: 1, color: "#00d9a6" },
        { code: "02", name: "경영•사업화•창업", order: 2, color: "#7790fa" },
        { code: "07", name: "기술개발•R&D", order: 3, color: "#fed51f" },
        { code: "11", name: "판로•해외진출•수출", order: 4, color: "#ff6565" },
        { code: "04", name: "인력", order: 5, color: "#c0cbd5" },
        { code: "09", name: "융자•금융", order: 6, color: "#6633ff" },
        { code: "10", name: "기타", order: 7, color: "#0066cc" }
    ];

        const [doughnutList, setDoughnutList] = useState([]);

        const getDoughnutList = async () => {
            let res = await api.getCommonCode(corp_nm.toLowerCase());
            let headers = {
                user_id : user_id,
                corp_cd : res.data.code
            }

            let ctgData = await api.getCorpCtgList(headers);

            const datas = ctgData.data;

            let target = datas.find((x) => x.target_cat_name == "기타");
            if (target == undefined) {
                datas.push({
                    target_cat_name: "기타",
                    count: 0,
                });
                target = datas.find((x) => x.target_cat_name == "기타");
            }

            for (let i = datas.length - 1; i >= 0; i--) {

                if (!baseCatName.some((x) => x.code == datas[i].target_cat_name)) {
                    target.count += datas[i].count;
                    datas.splice(i, 1);
                }
            }
            setDoughnutList(dataOrder(datas));

        };

        function dataOrder(arr) {
            let newArr = [...arr].map((v, i) => {
                const name = v.target_cat_name;
                let obj = baseCatName.find((x) => x.code == name);
                v.order = obj.order;
                v.color = obj.color;
                v.name = obj.name;
                return v;
            });
            newArr = newArr.sort((a, b) => {
                return a.order - b.order;
            });
            return newArr;
        }

    return (
            <div className={styles.wrap} style={{margin: path > 0 ? "auto" : ""}}>
                <div className={styles.h_friends_wrap}>
                    <div className={styles.btn}>
                        <button className={styles.prev} onClick={handlePrevClick}
                                style={{display: sectionCount <= sectionsToShow ? "none" : ""}}>
                            <img
                                src={require("assets/img/global/btn/btn_prev.png")}
                                alt="prev button"
                            />
                        </button>
                        <button className={styles.next} onClick={handleNextClick}
                                style={{display: sectionCount <= sectionsToShow ? "none" : ""}}>
                            <img
                                src={require("assets/img/global/btn/btn_next.png")}
                                alt="next button"
                            />
                        </button>
                    </div>
                    <div>
                        <h1 className={styles.contTit}>추천 콘텐츠</h1>
                        <div className={styles.innerWrap}>
                            {[...contentY, ...contentY].slice(startIndex, totalSectionsToShow).map((item, idx) => {
                                return <H_friends
                                    item={item}
                                    contentY={contentY}
                                    key={idx}
                                />;
                            })}
                        </div>
                    </div>
                </div>
                <div className={styles.contWrap}>
                    <ul className={styles.SavedCategory}>
                        <li className={`${styles.cateItem} ` + (cate == "government" ? styles.current : "")}>
                            <button onClick={cateClick} value="government">
                                <span
                                    onClick={() => {
                                        setHashState(true)
                                    }}
                                    className={styles.cate}>정부지원사업</span>
                            </button>
                        </li>
                        <li className={`${styles.cateItem} ` + (cate == "interest" ? styles.current : "")}>
                            <button onClick={cateClick} value="interest">
                                <span
                                    className={styles.cate}>관심사업</span>
                            </button>
                        </li>
                    </ul>
                    {cate == "government" ?
                        <div className={styles.H_notice}>
                            <H_notice title={'누적 사업공고'} cnt={count.total_cnt}/>
                            <H_notice title={'이번주 사업공고'} cnt={count.week_cnt}/>
                            <H_notice title={'정보 제공기관'} cnt={count.target_cnt}/>
                        </div>
                        :
                        <div className={styles.H_notice2}>
                            <H_notice title={'관심사업'} cnt={interestCount} title2={'가 저장되었습니다. 마감 전 신청하세요!'}/>
                        </div>
                    }

                    <div className="contentDIV" style={contentDIV}>
                        <div style={SupportFilterDiv}>
                            {cate == "government" ?
                                < H_Support
                                    allSupport={allSupport}
                                    setAllSupport={setAllSupport}
                                    getSupportCont={getSupportCont}
                                    isCheckingBox={isCheckingBox}
                                    ord={ord}
                                    setIsCheckingBox={setIsCheckingBox}
                                    totalCount={totalCount}
                                    setTotalCount={setTotalCount}


                                    Energy={Energy}
                                    setEnergy={setEnergy}
                                    Environment={Environment}
                                    setEnvironment={setEnvironment}
                                    Manufacturing={Manufacturing}
                                    setManufacturing={setManufacturing}
                                    factory={factory}
                                    setfactory={setfactory}
                                    ESG={ESG}
                                    setESG={setESG}
                                    RD={RD}
                                    setRD={setRD}
                                    ICT={ICT}
                                    setICT={setICT}
                                    Bio={Bio}
                                    setBio={setBio}
                                />
                                :
                                < H_SavedChart
                                doughnutList={doughnutList}
                                setDoughnutList={setDoughnutList}
                                getDoughnutList={getDoughnutList}
                                />
                            }
                        </div>
                        <section className="contentMain" style={contentMain}>
                            {cate == "government" ?
                                <H_SearchForm
                                    searchValSet={searchValSet}
                                    setSearchValSet={setSearchValSet}

                                    Energy={Energy}
                                    setEnergy={setEnergy}
                                    Environment={Environment}
                                    setEnvironment={setEnvironment}
                                    Manufacturing={Manufacturing}
                                    setManufacturing={setManufacturing}
                                    factory={factory}
                                    setfactory={setfactory}
                                    ESG={ESG}
                                    setESG={setESG}
                                    RD={RD}
                                    setRD={setRD}
                                    ICT={ICT}
                                    setICT={setICT}
                                    Bio={Bio}
                                    setBio={setBio}


                                    initUrl={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=`}
                                /> : ""
                            }

                                <Hashtag
                                    searchValSet={searchValSet}
                                    setSearchValSet={setSearchValSet}

                                    Energy={Energy}
                                    setEnergy={setEnergy}
                                    Environment={Environment}
                                    setEnvironment={setEnvironment}
                                    Manufacturing={Manufacturing}
                                    setManufacturing={setManufacturing}
                                    factory={factory}
                                    setfactory={setfactory}
                                    ESG={ESG}
                                    setESG={setESG}
                                    RD={RD}
                                    setRD={setRD}
                                    ICT={ICT}
                                    setICT={setICT}
                                    Bio={Bio}
                                    setBio={setBio}

                                    hashState={hashState}
                                    setHashState={setHashState}

                                    initUrl={`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=`}
                                />
                                <H_list
                                    getDoughnutList={getDoughnutList}
                                    getSupportCont={getSupportCont}
                                    ord={ord}
                                    setIsCheckingBox={setIsCheckingBox}
                                    page={page}
                                    setPage={setPage}
                                    keyword={keyword}
                                    setKeyword={setKeyword}
                                    doughnutList={doughnutList}
                                    cate={cate}
                                />
                        </section>
                    </div>
                </div>
            </div> //end
        );
    };

export default Hyundai_p;
