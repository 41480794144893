import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "scss/pages/CommonView.module.scss";
import "@toast-ui/editor/dist/toastui-editor.css";
import MobileTitle from "components/MobileTitle";
import * as api from '../../lib/api';

const NoticeView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({});
  const [time, setTime] = useState("");
  const [cont, setCont] = useState("");

  const getContent = async () => {

    let res = await api.getNoticeList();
    const target = res.data.find(item => item.id == id);
    setPost(target);
    setTime(getTime(target.createAt));
    setCont(target.contents);

  };

  const getTime = (timeStamp) => {
    return timeStamp;
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
      <>
        <div className={`${styles.NoticeView} ${styles.CommonView}`}>
          <MobileTitle title={"공지사항"} />
          <div className={`inner ${styles.inner}`}>
            <div className={styles.btns}>
              <button
                  onClick={() => navigate("/notice/noticeList")}
                  className={styles.btnBack}
              >
                <img
                    src={require("assets/img/global/btn/btn_back.png")}
                    alt="전체 공지"
                />
                <span>전체 공지</span>
              </button>
            </div>
            <div className={styles.contWrap}>
              <div className={styles.contTop}>
                <div className={styles.titleArea}>
                  <h3 className={styles.title}>{post.title}</h3>
                </div>
                <div className={styles.writer}>
                  <p className={styles.time}>{time}</p>
                </div>
              </div>
              <div className="toastui-editor-contents">
                <div
                    className={styles.cont}
                    dangerouslySetInnerHTML={{ __html: cont }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
export default NoticeView;
