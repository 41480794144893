import React from "react";
import styles from "scss/pages/Deliver.module.scss";
import { Link } from "react-router-dom";
const MyDeliverItem = ({
                           email,
                      setScrollStorage
                    }) => {

  return (
      <li className={`commonListItem ${styles.DeliverListItem}`}>
        <div className={`leftArea ${styles.leftArea}`}>
          <Link
              to={`/deliver/deliverView/${email.idx}`}
              onClick={() => {
                setScrollStorage(window.scrollY);
              }}
          >
            <p className="content">{email.deliver_dt}</p>
              <p className="content">{email.title}</p>
          </Link>
        </div>
      </li>
  );
};
export default MyDeliverItem;
