import React, { useState } from "react";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "scss/pages/H_Saved.module.scss";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    ChartDataLabels
);

const H_SavedChart = ({
                        doughnutList,
                        getDoughnutList,
                    }) => {
    const userInfo = useSelector((state) => state.userInfo);
    const [doughnutTimer, setDoughnutTimer] = useState(false);
    const [doughnutdata, setDoughnutdata] = useState({});
    const [doughnutSum, setDoughnutSum] = useState(0);

    const doughnutOpts = {
        plugins: {
            legend: {
                display: false,
                position: "bottom",
                labels: {
                    boxWidth: 20,
                    boxHeight: 20,
                    padding: 10,
                    font: {
                        size: 14,
                        weight: 500,
                    },
                },
            },
            datalabels: {
                anchor: "center", //start , end
                align: "center", //top bottom middle 데이터 라벨 표시 위치
                color: "#fff",
                font: {
                    size: 16,
                    weight: 700,
                },
                formatter: function (value, context) {
                    return value;
                },
            },
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
    };

    useEffect(() => {
        setDoughnutSum(0);
        doughnutList.forEach((v) => {
            setDoughnutSum((prev) => (prev += v.count));
        });
        setDoughnutdata({
            labels: doughnutList.map((v) => v.name),
            datasets: [
                {
                    data: doughnutList.map((v) => v.count),
                    backgroundColor: doughnutList.map((v) => v.color),
                },
            ],
            borderWidth: 0,
        });
    }, [doughnutList]);

    useEffect(() => {
        setDoughnutTimer(true);
    }, [doughnutdata]);

    useEffect(() => {
        getDoughnutList();
    }, [userInfo]);

    return (
        <div className={styles.ChartWrap}>
            <div className={`${styles.chartArea} ${styles.doughtnut}`}>
                <p className={styles.title}>관심사업 분류</p>
                {doughnutSum == 0 ? (
                    <div className="empty">
                        <span>관심사업을 저장하면</span><br/>
                        <span>분류가 제공됩니다.</span>
                    </div>
                ) : (
                    doughnutTimer && (
                        <>
                            <div className={styles.canvasArea}>
                                <Doughnut data={doughnutdata} options={doughnutOpts} />
                            </div>
                            <div className={styles.legendArea}>
                                {doughnutList.map((item, idx) => {
                                    return (
                                        <p key={item.name}>
                                          <span
                                              className={styles.circle}
                                              style={{
                                                  backgroundColor: item.color,
                                              }}
                                          ></span>
                                            <span className={styles.name}>{item.name}</span>
                                        </p>
                                    );
                                })}
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
    );
};
export default H_SavedChart;
