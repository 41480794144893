import React, {useEffect,  useState} from 'react';
import FirstConditionsStyle from "scss/components/FirstConditions.module.scss";

const FirstConditions = ({
                             conditions,
                             setConditions,
                             Conditions1,
                             allAgree,
                            setAllAgree,
                             isMain,
                             requireObj,
                             setRequireObj,
                                alarmObj,
                                setAlarmObj
                           }) => {

    const [resize, setResize] = useState(window.innerWidth);
    const [cpAlarmObj, setCpAlarmObj] = useState(JSON.parse(JSON.stringify(alarmObj)));
    const [cpRequireObj, setCpRequireObj] = useState(JSON.parse(JSON.stringify(requireObj)));
    const [cpAllAgree, setCpAllAgree] = useState(JSON.parse(allAgree));



    // console.log('cpRequireObj>>>>',cpRequireObj)

    //TODO : MainFirstCondition | FirstConditions 하나로 쓸 수 있도록 리팩토링 필요

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setResize(window.innerWidth);
    };

    const policyPopList = [
        {value : true},
        {value : false}
    ];


    const policySubmit = () => {
        if (!cpRequireObj.servicePush || !cpRequireObj.collectPush) {
            alert('필수 약관에 동의하셔야 서비스를 이용하실 수 있습니다.');
            return false;
        }

        setAlarmObj(cpAlarmObj)
        setRequireObj(cpRequireObj);
        setAllAgree(cpAllAgree);
        setConditions(false);
    }



    const chkModalChange = (e) => {
        const { target: { value, name } } = e;
        let copy = {...cpAlarmObj};
        let copy2 = {...cpRequireObj};
        if(name == 'personalInfoAgreeMyPage' || name == 'marketingPushMyPage'){
            name == 'personalInfoAgreeMyPage' ? copy.personalInfoAgree = JSON.parse(value) : copy.marketingPush = JSON.parse(value);
            setCpAlarmObj(copy);
        }else{
            name == 'servicePush' ? copy2.servicePush = JSON.parse(value) : copy2.collectPush = JSON.parse(value);
            setCpRequireObj(copy2);
        }

    };

    useEffect(() => {
        setCpAllAgree(!Object.values(cpAlarmObj).some((item) => item == false) && !Object.values(cpRequireObj).some((item) => item == false));
    }, [cpAlarmObj, cpRequireObj]);

    const checkModalAllChange = (e) => {
        const { target: { checked } } = e;
        let copy = {...cpAlarmObj};
        let copy2 = {...cpRequireObj};
        if (checked) {
            for (let key in copy) copy[key] = true;
            if(isMain)  for (let key in copy2) copy2[key] = true;
        } else {
            for (let key in copy) copy[key] = false;
            if(isMain)  for (let key in copy2) copy2[key] = false;
        }

        setCpAlarmObj(copy);
        setCpRequireObj(copy2);
        setCpAllAgree(checked);
    };


    return (
        <>
            {conditions || Conditions1 ?
                <div className={FirstConditionsStyle.wrap}>
                    {resize > 1023 || resize == undefined ?
                        <div className={FirstConditionsStyle.modal}>
                            <section className={FirstConditionsStyle.top}>
                                <input
                                    type="checkbox"
                                    id='checkAll'
                                    onChange={checkModalAllChange}
                                    checked={cpAllAgree}
                                />
                                <label htmlFor="checkAll">전체동의</label>
                            </section>


                            <section className={FirstConditionsStyle.contents}>
                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>서비스 이용약관(필수)</h1>
                                        <section>
                                            {policyPopList.map((v,i) => {
                                                const num = ("00" + i).slice(-2);
                                                return (
                                                    <label htmlFor={`service_cp_${num}`} key={i}>
                                                        <input
                                                            type="radio"
                                                            value={v.value}
                                                            name="servicePush"
                                                            id={`service_cp_${num}`}
                                                            onChange={chkModalChange}
                                                            disabled={!isMain}
                                                            checked={cpRequireObj.servicePush == v.value }
                                                        />
                                                        <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                    </label>
                                                )
                                            })}
                                        </section>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure className={FirstConditionsStyle.txtCon}>
                                            <h2>제 1장 총칙</h2>
                                            <h3>제1조 목적</h3>
                                            <p>본 약관은 엑시토(EXITO 이하 “회사”라 한다)가 운영하는 웹/앱에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)의 이용 및 제공 등에 관한 제반 사항의 규정을 목적으로 합니다.</p>
                                        </figure>

                                        <figure className={FirstConditionsStyle.txtCon}>
                                            <h2>제2장 서비스 이용 및 제한</h2>
                                            <h3>제5조 회원가입</h3>
                                            <p>1. 이용자는 “회사”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>2. “회사”는 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</p>
                                                <span>1. 가입신청자가 이 약관 제6조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제6조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “회사”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.</span>
                                                <span>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</span>
                                                <span>3. 기타 회원으로 등록하는 것이 “회사”의 기술상 현저히 지장이 있다고 판단되는 경우</span>
                                            </figure>
                                            <p>3. 회원가입계약의 성립 시기는 “회사”의 승낙이 회원에게 도달한 시점으로 합니다.</p>
                                            <p>4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “회사”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제6조 회원 탈퇴 및 자격 상실 등</h3>
                                            <p>1 . 회원은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원탈퇴를 처리합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>2. 회원이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및 정지시킬 수 있습니다.</p>
                                                <span>1. 가입 신청 시에 허위 내용을 등록한 경우</span>
                                                <span>2. “회사”를 이용하여 구입한 재화 등의 대금, 기타 “회사”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</span>
                                                <span>3. 다른 사람의 “회사” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</span>
                                                <span>4. “회사”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span>
                                            </figure>
                                            <p>3. “회사”가 회원 자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “회사”는 회원자격을 상실시킬 수 있습니다.</p>
                                            <p>4. “회사”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제7조 서비스 이용</h3>
                                            <p>1. 이용자의 서비스 이용 신청은 본 약관에 대한 동의로 간주되며, 이용자가 그와 같이 서비스 이용을 신청한 때 본 약관에 따른 서비스 이용 계약이 성립됩니다. 단, 이용자는 언제든지 본 약관에 대한 동의를 철회할 수 있습니다.</p>
                                            <p>2. 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다.</p>
                                            <p>3. 회사는 정보통신 시스템 등의 점검, 보수, 교체, 고장, 통신두절, 본 약관에 위배한 행위 등으로 인하여 정상적인 서비스 제공이 곤란한 경우 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</p>
                                            <p>4. 제2항에 의한 서비스 중단의 경우, 회사는 서비스화면을 통하여 관련 내용을 이용자에게 게시합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스 중단으로 서비스화면을 통한 게시가 불가능한 경우에는 그러하지 아니합니다.</p>
                                            <p>. 회사에서 제공하는 서비스는 기본적으로 무료입니다. 단, 별도로 유료임을 명시한 서비스에 대해서는 그러하지 아니합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제8조 서비스 이용 제한</h3>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>1. 회사는 다음 각 호에 경우에는 서비스를 제공하지 아니할 수 있습니다.</p>
                                                <span>가. 이용자가 본인인증 시 다른 사람의 명의를 사용하거나 허위 정보를 등록하는 등 기타 부정한 수단을 이용하는 경우</span>
                                                <span>나. 이용자가 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</span>
                                                <span>다. 이용자가 법령위반 또는 공공질서 내지 미풍양속을 저해할 목적으로 서비스 이용 경우</span>
                                                <span>라. 이용자가 부정한 용도로 서비스를 이용하고자 하는 경우</span>
                                                <span>마. 이용자가 본 약관에 규정된 제반 사항을 위반하거나 기타 이용자의 귀책사유로 이용승낙이 곤란한 경우</span>
                                            </figure>
                                            <p>2. 본인인증 서비스의 경우 이용자가 본인인증을 위하여 일부 개인정보를 회사에 제공하여야만 그 이용이 가능합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제9조 개인정보의 수집 및 이용 등</h3>
                                            <p>1. 회사는 원칙적으로 이용자의 개인정보를 수집•이용하지 않으며, 본인인증 서비스를 제공하기 위해 필요한 최소한의 정보를 수집합니다. 다만, 회사는 이용자의 원활한 사이트 접속을 위하여 인터넷 접속정보파일(이른바 쿠키정보)을 자동으로 수집하는 장치를 운영할 수 있으며, 이용자는 자신이 이용하는 컴퓨터의 설정을 변경함으로써 회사의 인터넷 접속정보파일의 수집을 차단할 수 있습니다.</p>
                                            <p>2. 회사가 이용자의 개인정보를 수집하는 때에는 반드시 해당 이용자의 동의를 받습니다.</p>
                                            <p>3. 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 부담합니다.</p>
                                            <p>4. 기타 개인정보 관련 사항은 정보통신망 이용 촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령 및 회사의 서비스 화면에 게시하는 개인정보 취급방침에 의합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제10조 저작권의 귀속 및 이용제한</h3>
                                            <p>1. 회사가 제공하는 저작물에 대한 저작권 기타 지적 재산권은 회사에 귀속합니다.</p>
                                            <p>2. 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다</p>
                                        </figure>

                                        <figure>
                                            <h3>제11조 서비스 이용중지</h3>

                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>1. 회사는 이용자가 다음 사항에 해당하는 행위를 하였을 경우, 상당한 기간을 정하여 서비스 이용을 중지할 수 있습니다.</p>
                                                <span>가. 공공질서 내지 미풍양속에 반하는 행위를 한 경우</span>
                                                <span>나. 범죄적 행위에 관련되는 경우</span>
                                                <span>다. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획하거나 실행할 경우</span>
                                                <span>라. 타인의 본인인증 정보 등 개인정보를 도용한 경우</span>
                                                <span>마. 타인의 명예를 손상시키거나 불이익을 주는 경우</span>
                                                <span>바. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우</span>
                                                <span>사. 기타 관련 법령이나 본 약관에 위배되는 경우</span>
                                            </figure>
                                            <p>2. 회사는 서비스 이용을 중지하고자 하는 경우 그 사유, 일시 및 기간을 정하여 서면 또는 전화 등의 방법을 이용하여 해당 이용자 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 위 통지 없이 서비스 이용을 중지할 수 있습니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제12조 서비스 이용중지의 해제</h3>
                                            <p>1. 전조의 규정에 의하여 서비스 이용중지를 통지 받은 이용자 또는 그 대리인은 서비스 이용중지에 대하여 이의 신청을 할 수 있습니다.</p>
                                            <p>2. 회사는 이용중지 사유가 해소된 것이 확인된 경우에 한하여 이용중지 조치를 즉시 해제합니다.</p>
                                        </figure>

                                        <figure>
                                            <h2>제3장 의무와 책임</h2>
                                            <h3>제13조 회사의 의무</h3>
                                            <p>1. 회사는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.</p>
                                            <p>2. 회사는 이용자로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 처리하며, 그에 일정 기간이 소요될 경우 이용자에게 그 사유와 처리 일정을 알려주어야 합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제14조 이용자의 의무</h3>
                                            <p>1. 이용자는 본인인증에 이용될 수 있는 정보(예를 들어, 아이디와 비밀번호, 공인인증서 비밀번호 등) 및 장치 등에 대한 관리의 책임을 부담하여, 이와 관련하여 이용자의 귀책사유로 인해 발생한 손해에 대하여 회사는 책임을 지지 않습니다.</p>
                                            <p>2. 이용자는 본인인증에 이용될 수 있는 자신의 정보가 도난 당하거나 제3자가 해당 정보를 사용하고 있음을 인지하는 경우 반드시 회사에 그 사실을 통보해야 합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>3. 이용자는 본 약관 및 관계 법령에서 규정한 사항을 준수하여야 하며, 다음 행위를 하여서는 안됩니다.</p>
                                                <span>가. 본인인증 시 다른 사람의 명의를 사용하거나 허위 정보를 등록하는 등 기타 부정한 수단의 이용</span>
                                                <span>나. 회사가 운영하는 웹사이트에 게시된 정보의 임의적인 변경</span>
                                                <span>다. 회사가 허락하지 않은 정보 등의 송신 또는 게시</span>
                                                <span>라. 회사, 기타 제3자의 저작권 등 지적재산권에 대한 침해</span>
                                                <span>마. 회사, 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span>
                                                <span>바. 외설 또는 폭력적인 메시지, 화상, 음성 기타 미풍양속에 반하는 정보를 공개 또는 게시하는 행위</span>
                                            </figure>

                                            <figure>
                                                <h3>제15조 손해배상</h3>
                                                <p>회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우, 그 손해를 배상하여야 합니다. 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보 취급방침에서 정하는 내용에 위반하지 않는 한 어떠한 손해에 대하여도 책임을 지지 않습니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제16조 면책조항</h3>
                                                <p>1. 회사는 천재지변 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.</p>
                                                <p>2. 회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여는 책임을 지지 않습니다.</p>
                                                <p>3. 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</p>
                                                <p>4. 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</p>
                                                <p>5. 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데 이용자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p>
                                                <p>6. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</p>
                                                <p>7. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우 또는 이용자가 본인인증 서비스를 이용함에 있어 자신의 개인정보를 부실하게 기재하여 손해가 발생한 경우에 책임을 지지 않습니다.</p>
                                            </figure>
                                            <figure>
                                                <h2>제4장 일반사항</h2>
                                                <h3>제17조 이용자의 게시물 관리</h3>
                                                <p>회사는 이용자가 게시하거나 등록한 내용물이 다음 사항에 해당된다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.</p>
                                                <p>1. 스팸(spam)성 게시물 등과 같이 회사에서 승인하지 아니한 광고, 판촉물을 게시하는 경우 및 서비스와 무관할 수 있는 내용인 경우</p>
                                                <p>2.  회사, 다른 이용자 또는 제3자의 명예를 손상시키는 비방 또는 중상모략일 수 있는 경우</p>
                                                <p>3. 공공질서 및 미풍양속에 위반되는 내용인 경우</p>
                                                <p>4. 범죄적 행위에 부합된다고 인정되는 내용인 경우</p>
                                                <p>5. 타인의 저작권 등 기타의 권리를 침해하는 내용인 경우</p>
                                                <p>6. 기타 관계 법령이나 본 약관에 위배되는 내용인 경우</p>
                                            </figure>

                                            <figure>
                                                <h3>제18조 이용자에 대한 통지 및 정보제공</h3>
                                                <p>1. 회사가 본인인증 서비스 이용자에 대한 통지를 하는 경우 해당 이용자가 회사에 미리 제공한 전자우편주소로 이메일을 보내거나 휴대폰 번호로 문자메시지를 보내는 방법 등으로 통지할 수 있습니다.</p>
                                                <p>2. 회사는 불특정다수의 이용자에 대한 통지의 경우 1주일이상 게시판에 게시하는 방법으로 할 수 있습니다.</p>
                                                <p>3. 회사는 본인인증 서비스 이용자에게 해당 서비스 이용에 필요가 있다고 인정되는 각종 정보를 전자우편, SMS, 전화 등의 방법으로 제공할 수 있습니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제19조 일부 무효</h3>
                                                <p>관련 법령 등에 의하여 본 약관의 일부 규정이 무효가 될 경우, 나머지 규정만으로 그 목적 달성이 불가능하거나 이용자에게 부당하게 불리하지 아니하는 이상 본 약관은 그 효력을 유지합니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제20조 재판관할 및 준거법</h3>
                                                <p>1. 회사의 유료 서비스 이용 회원의 경우 당해 서비스와 관련하여서는 회사가 별도로 정한 약관 및 정책에 따릅니다.</p>
                                                <p>2. 서비스 이용과 관련하여 회사와 이용자 간에 발생하는 분쟁 관련 소송의 관할 법원은 민사소송법상의 관할을 가지는 대한민국 법원으로 하며, 준거법은 대한민국법으로 합니다.</p>
                                            </figure>

                                            <figure>
                                                <p>(부칙)</p>
                                                <p>1. 본 약관은 2023년 08월 07일부터 적용됩니다.</p>
                                            </figure>

                                        </figure>
                                    </div>
                                    <p style={{color:'#FF0000',marginTop:'10px',fontSize:'14px',marginBottom:'60px'}}>*로그인 이후 필수 약관 동의는 변경할 수 없습니다.회원 탈퇴 시, 필수 동의 약관이 철회 처리됩니다.</p>
                                </div>

                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>개인정보 수집 및 이용동의(필수)</h1>
                                        <section>
                                            {policyPopList.map((v,i) => {
                                                const num = ("00" + i).slice(-2);
                                                return (
                                                    <label htmlFor={`collect_cp_${num}`} key={i}>
                                                        <input
                                                            type="radio"
                                                            value={v.value}
                                                            name="collectPush"
                                                            id={`collect_cp_${num}`}
                                                            onChange={chkModalChange}
                                                            disabled={!isMain}
                                                            checked={cpRequireObj.collectPush == v.value}
                                                        />
                                                        <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                    </label>
                                                )
                                            })}
                                        </section>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h2>개인정보 처리방침</h2>
                                            <p>엑시토 (엑시토 이하 "회사")는 이용자의 개인 정보를 보호하기 위하여 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 및 「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.</p>
                                            <p>회사는 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집, 이용되고 있으며, 이용자의 개인정보 보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.</p>
                                            <p>본 개인정보 처리 방침은 관련 법의 개정이나 회사의 정책에 따라 변경될 수 있으며, 회사는 웹사이트를 통하여 이를 알려드리오니, 웹사이트 이용 시에 수시로 확인하여 주시기 바랍니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>1. 처리하는 개인정보의 항목</h3>
                                            <p>“회사”는 하기 명시된 개인정보 항목을 수집하여 처리합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p> [필수] 수집항목 : </p>
                                                <span>1.담당자 정보 : 회사 이메일, 휴대전화번호</span>
                                                <span>2.기업 정보 : 사업자 형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업소재지</span>
                                                <span>3.로그인 정보 : 프로필사진, 생년월일, 성별, 연령대, 이메일, 이름</span>
                                            </figure>
                                            <p>[선택] 수집항목 : 없음</p>
                                        </figure>

                                        <figure>
                                            <h3>2. 개인정보의 처리 목적</h3>
                                            <p>“회사”는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                                            <p>가. 서비스 제공을 위한 정보 활용 : 서비스 이용에 따른 본인 확인, 만 14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 콘텐트 제공, 개인 식별, 부정 이용 방지와 비인가 사용 방지, 만족도 조사, 분쟁 조정을 위한 기록 보존, 불만 민원 처리, 고지사항 전달의 목적으로 활용</p>
                                            <p>나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 : 유료 서비스 결제 시 활용</p>
                                            <p>다. 마케팅 및 광고에 활용 : 신규 서비스 개발, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악, 서비스 이용에 대한 통계, 이벤트 경품 배송, 고객 지향적 이벤트와 광고성 정보 전달</p>
                                        </figure>

                                        <figure>
                                            <h3>3. 개인정보의 처리 및 보유 기간</h3>
                                            <p>1. 회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간(회원탈퇴 혹은 위탁 계약 종료 시)이 도래하면 해당 정보를 지체 없이 파기합니다. </p>
                                            <p>2. 제1항에도 불구하고 ‘회사’는 아래 개인정보항목에 대하여 관련 법령에 따른 보유 기간을 적용합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>상법, 국세기본법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 이 경우 회사는 보관하는 개인정보를 그 보관의 목적으로만 이용하며 보존 기간 및 보존 항목은 아래와 같습니다</p>
                                                <span>- 계약 또는 철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 납세 증거에 관한 기록 : 5년(국세 기본법)</span>
                                                <span>- 보존 항목 : </span>
                                                <span>1. 담당자 정보 : 회사 이메일, 휴대전화번호</span>
                                                <span>2. 기업 정보 : 사업자 형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업 소재지</span>
                                                <span>3. 로그인 정보 : 프로필사진, 생년월일, 성별, 연령대, 이메일, 이름</span>
                                            </figure>
                                        </figure>


                                        <figure>
                                            <h3>4. 개인정보 파기에 관한 사항</h3>
                                            <p>1. 파기절차</p>
                                            <p>서비스 이용을 위해 입력하신 정보는 목적이 달성된 후 기타 관련 법령에 의한 개인정보보관 사유에 해당하지 않는 한 지체없이 파기됩니다.</p>

                                            <p>2. 파기방법</p>
                                            <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>5. 개인정보 자동 수집의 목적 및 거부에 관한 사항</h3>
                                            <p>회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)', 'ActiveX'등 개인정보를 자동으로 수집하는 장치(이하 “쿠키등”)를 설치 운용합니다. 쿠키란 서비스 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.</p>
                                            <p>가. 쿠키등 사용 목적</p>
                                            <p>① 이용자의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 개선에 활용</p>
                                            <p>② 웹사이트 방문 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 웹사이트 방문 때 제품 정보를 제공</p>
                                            <p>나. 쿠키 설정 거부 방법</p>
                                            <p>① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 쿠키 설정을 거부하는 방법으로는 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</p>
                                            <p>② 설정방법의 예시(웹 브라우저의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보에서 변경. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</p>
                                            <p>③ 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를 설정합니다.</p>
                                            <p>④ 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.</p>
                                            <p>③ ActiveX 설정 거부 방법</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>A. 이용자는 ActiveX 설치에 대한 선택권을 가지고 있습니다. ActiveX 설정을 거부하는 방법은 다음과 같습니다.</p>
                                                <span>1) 웹 브라우저 상단의 도구 > 인터넷 옵션 > 보안 탭 클릭하여 하단의 사용자 지정수준 클릭 > ActiveX 컨트롤 및 플러그 인 항목에서 아래와 같이 체크함 </span>
                                                <span>-바이너리 및 스크립트 동작 => 사용안함 </span>
                                                <span>서명 된 ActiveX 컨트롤 다운로드 => 사용안함 </span>
                                                <span>-스크립팅하기 안전하지 않은 것으로 표시된 ActiveX 컨트롤 초기화 및 스크립팅 => 사용안함 </span>
                                                <span>-스크립팅하기 안전한 것으로 표시된 ActiveX 컨트롤 스크립트 => 사용안함 </span>
                                                <span>-ActiveX 컨트롤 및 플러그인 실행 => 사용안함 </span>
                                                <span>ActiveX 컨트롤을 자동으로 사용자에게 확인 => 사용안함</span>
                                                <span>2) 새 웹 브라우저 창을 여신 후, 다시 접속해 주시기 바랍니다. 접속이 원활하게 되지 않을 경우에는 바이러스 및 악성 코드 제거 프로그램으로 바이러스와 악성코드를 제거 후에 다시 시도해 주시기 바랍니다.</span>
                                            </figure>
                                            <p>다. 광고를 게재하는 배너에서도 광고주나 마케팅회사에 의해 쿠키가 사용되는 경우도 있음을 알려드립니다. 이 경우 당해 쿠키는 시스템 관리와 광고주에게 제공할 통계 집계 등을 위하여 이용자들의 IP 주소를 모으기도 합니다. 당해 쿠키가 수집하는 개인정보에 관한 사항은 광고주나 마케팅회사의 개인정보보호방침에 따릅니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>6. 개인정보의 안전성 확보조치에 관한 사항</h3>
                                            <p>회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 마련하고 있습니다.</p>
                                            <p>1) 기술적 보호조치</p>
                                            <p>① 이용자의 개인정보는 비밀번호에 의해 보호되며 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.</p>
                                            <p>② 해킹 등 외부 침입에 대비하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 기술적 장치를 갖추어 개인정보 보안에 만전을 기하고 있습니다.</p>
                                            <p>③ 패스워드 등 본인임을 인증하는 정보에 대해서는 복호화되지 아니하도록 일방향 암호화하여 저장합니다.</p>
                                            <p>④ 개인정보 처리 시스템에서 개인정보의 출력시(인쇄, 화면표시, 파일생성 등) 용도를 특정하며, 용도에 따라 출력 항목을 최소화합니다.</p>
                                            <p>⑤ 개인정보 처리자가 개인 정보를 종이로 인쇄하거나, 디스켓, 콤팩트디스크 등 이동 가능한 저장매체에 복사할 경우 개인정보보호 책임자의 사전 승인을 받도록 조치합니다. 출력, 복사물로부터 다시 출력 또는 복사하는 경우도 또한 같습니다.</p>


                                            <p>2) 관리적 보호조치</p>
                                            <p>① 이용자의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다.</p>
                                            <p>② 보직이동 또는 퇴직 등 인사 이동이 발생하여 개인정보처리자가 변경되었을 경우 지체 없이 개인 정보처리시스템의 접근 권한을 변경 또는 말소합니다. 이 경우 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 5년간 보관합니다</p>
                                            <p>③ 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를 설정합니다.</p>
                                            <p>④ 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.</p>
                                            <p>⑤ 입사시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.</p>
                                            <p>⑥ 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.</p>
                                            <p>⑦ 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의 서버를 통해 분리하여 보관하고 있습니다.</p>
                                            <p>⑧ 당사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.</p>
                                            <p>⑨ 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 이용자께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</p>
                                        </figure>


                                        <figure>
                                            <h3>7. 정보주체의 권리, 의무 및 그 행사방법에 관한 사항</h3>
                                            <p>이용자 및 법정대리인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자 및 법정대리인이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 및 법정대리인 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 서비스 이용이 제한될 수 있습니다. 이용자 및 법정대리인은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 이용자 및 법정대리인의 개인 정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등에 의해 처벌받을 수 있습니다.</p>
                                            <p>1) 개인정보 열람, 정정, 삭제 요구의 권리:</p>
                                            <p>이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제하실 수 있습니다. 개인정보 열람 및 정정, 삭제를 하고자 할 경우에는 개인정보보호책임자 및 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 지체 없이 조치하겠습니다.</p>
                                            <p>2) 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리:</p>
                                            <p>이용자 및 법정대리인은 서비스 이용을 위해 입력하신 개인정보의 수집, 이용, 제공, 저장에 대해 동의하신 내용을 철회하실 수 있습니다. </p>
                                            <p>동의 철회는 개인정보보호 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.</p>
                                        </figure>


                                        <figure>
                                            <h3>8. 개인정보의 열람청구를 접수, 처리하는 부서</h3>
                                            <p>개인정보 관련한 민원처리는 이용자의 개인정보를 보호하고 개인정보와 관련한 의문사항을 처리하기 위하여 다음과 같이 민원처리 콜센터를 운영하고 있습니다.</p>
                                            <p>①	전자우편 : exito@myctns.com</p>
                                            <p>②	전화번호 : 02-561-1898</p>

                                            <p>기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.</p>
                                            <p>1) 개인정보침해신고센터</p>
                                            <p>- 전화 : (국번없이) 118</p>
                                            <p>- URL : http://privacy.kisa.or.kr</p>


                                            <p>2) 개인정보분쟁조정위원회</p>
                                            <p>- 전화 : 1833-6972</p>
                                            <p>- URL : http://www.kopico.go.kr</p>

                                            <p>3) 대검찰청 사이버수사과</p>
                                            <p>전화 : (국번없이) 1301</p>
                                            <p>- URL : http://www.spo.go.kr</p>


                                            <p>4) 경찰청 사이버안전국</p>
                                            <p>전화 : (국번없이) 182</p>
                                            <p>- URL : http://cyberbureau.police.go.kr</p>
                                        </figure>

                                        <figure>
                                            <h3>9. 개인정보보호 책임자</h3>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다</p>
                                                <span>- 개인정보 보호 책임자 : CTNS 대표이사</span>
                                                <span>- 개인정보 보호 담당자 : CTNS 정보보호담당자</span>
                                                <span>- 전화번호 : 02-561-1898</span>
                                                <span>- 이메일 주소 : exito@myctns.com</span>
                                            </figure>
                                        </figure>

                                        <figure>
                                            <h3>10. 링크 사이트</h3>
                                            <p>회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공 받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭하여 타 웹사이트의 페이지로 옮겨갈 경우 해당 웹사이트의 개인정보보호 정책은 회사와 무관하므로 새로 방문한 웹사이트의 정책을 검토해 보시기 바랍니다.</p>

                                        </figure>

                                        <figure>
                                            <h3>11. 개인정보 처리방침의 변경에 관한 사항</h3>
                                            <p>이 개인정보처리방침은 법령ㆍ정책 또는 보안 기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 아래와 같은 방법으로 사전 공지하도록 하겠습니다.</p>
                                            <p>- 인터넷 홈페이지 첫 화면 공지 사항 란 또는 별도의 창을 통하여 수정내용 공지</p>

                                            <p>시행일자: 2023년 08월 07일</p>
                                        </figure>
                                    </div>
                                    <p style={{color:'#FF0000',marginTop:'10px',fontSize:'14px',marginBottom:'60px'}}>*로그인 이후 필수 약관 동의는 변경할 수 없습니다.회원 탈퇴 시, 필수 동의 약관이 철회 처리됩니다.</p>
                                </div>

                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>마케팅 정보 수신 동의(선택)</h1>
                                        <section>
                                            {policyPopList.map((v,i) => {
                                                const num = ("00" + i).slice(-2);
                                                return (
                                                    <label htmlFor={`marketing_cp_${num}`} key={i}>
                                                        <input
                                                            type="radio"
                                                            value={v.value}
                                                            name="marketingPushMyPage"
                                                            id={`marketing_cp_${num}`}
                                                            onChange={chkModalChange}
                                                            checked={ cpAlarmObj.marketingPush == v.value}
                                                        />
                                                        <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                    </label>
                                                    )})}
                                        </section>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h3>마케팅 정보 수신 동의</h3>
                                            <p>서비스 제공 및 이용과 관련하여 ㈜씨티엔에스가 취득한 개인정보는 "개인정보보호법" 및 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수합니다.</p>
                                            <p>1. 고객이 수집 및 이용에 동의한 개인정보를 활용하여, 전자적 전송 매체(App Push, 핸드폰 문자메시지(SMS,MMS), E-mail, 카카오톡 등 다양한 전송 매체)를 통하여 발송됩니다. </p>
                                            <p>2. 발송되는 마케팅 정보는 수신자에게 ㈜씨티엔에스 및 제 3자의 상품 또는 서비스에 대한 혜택 정보, 각종 이벤트 정보, 개인 맞춤형 광고 정보 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다.  단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다. </p>
                                            <p>3. 마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 엑시토 서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다. </p>
                                            <p>4. 수신동의를 받은 날부터 2년마다 (매 2년이 되는 해의 수신동의를 받은 날과 같은 날 전까지를 말한다.) 해당 수신자의 수신동의 여부를 확인합니다.</p>
                                            <p>5. ㈜씨티엔에스 고객센터를 통해 수신동의를 철회할 수 있습니다. </p>
                                        </figure>

                                        <figure>
                                            <p>※ 문의 및 동의철회 : 02-561-1898</p>
                                            <table>
                                                <tr>
                                                    <th>개인정보 수집 항목</th>
                                                    <td>개인정보 1등급: 없음</td>
                                                    <td>개인정보 2등급: 이메일,이름(또는 카카오톡 프로필 닉네임),휴대전화번호</td>
                                                </tr>
                                                <tr>
                                                    <th>개인정보 수집 이용 목적</th>
                                                    <td>마케팅 광고에 활용</td>
                                                    <td>이벤트 운영 및 광고성 정보 전송</td>
                                                </tr>
                                                <tr>
                                                    <th>보유 및 이용기간</th>
                                                    <td>-이용자가 동의를 철회하거나, 탈퇴 시까지 (마케팅 수신동의 여부 2년 주기로 확인)</td>
                                                    <td>-서비스 관련 정보 전송 단, 관련 법령의 규정에 따라 보존할 필요가 있을 경우, 해당 기간까지 보존함</td>
                                                </tr>
                                            </table>
                                        </figure>

                                        <figure>
                                            <p>이 마케팅약관은 2023년 08월 07일부터 적용됩니다.</p>
                                        </figure>
                                    </div>
                                </div>
                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>개인정보 및 기업정보 제 3자 제공 동의(선택)</h1>
                                        <section>
                                            {policyPopList.map((v,i) => {
                                                const num = ("00" + i).slice(-2);
                                                return (
                                                    <label key={i}  htmlFor={`personal_cp_${num}`}>
                                                        <input
                                                            type="radio"
                                                            value={v.value}
                                                            id={`personal_cp_${num}`}
                                                            name="personalInfoAgreeMyPage"
                                                            onChange={chkModalChange}
                                                            checked={cpAlarmObj.personalInfoAgree == v.value}
                                                        />
                                                        <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                    </label>
                                                )
                                            })}
                                        </section>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h3>개인정보 및 기업정보 제3자 제공에 대한 동의</h3>

                                                <table>
                                                    <tr>
                                                        <th>1.제공기관</th>
                                                        <td>- 국내 스타트업의 육성ㆍ투자ㆍ지원 관련 서비스를 운영하는 국내외 스타트업 관련 플랫폼 사업자 및 정부기관</td>
                                                        <td>- 기타 본 서비스의 활성화를 위해 필요한 기관</td>
                                                    </tr>

                                                    <tr>
                                                        <th>2.수집,이용 목적</th>
                                                        <td>- 국내외 스타트업 관련 플랫폼 사업자 및 정부기관과 엑시토 서비스 이용자 간의 협업 중개</td>
                                                    </tr>

                                                    <tr>
                                                        <th>3.수집항목</th>
                                                        <td>- 담당자 정보(회사 이메일, 전화번호)</td>
                                                        <td>- 기업 정보(사업자형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업 소재지)</td>
                                                        <td>- 로그인 정보는 제3자에게 제공하지 않음</td>
                                                    </tr>

                                                    <tr>
                                                        <th>4.보유-이용기간</th>
                                                        <td>- 회원 탈퇴 시 혹은 위탁 계약 종료 시</td>
                                                    </tr>

                                                    <tr>
                                                        <th>5.개인/기업정보 제3자 제공 동의 거부시 불이익</th>
                                                        <td>- 개인정보 및 기업정보 제3자 제공에 대한 동의를 거부할 수 있으며, 동의하지 않을 경우, 엑시토 서비스에서 제공하는 특별 혜택(무료 이벤트, 무료 특강 등)에 참여 제한이 있을 수 있습니다. </td>
                                                    </tr>

                                                </table>
                                            </figure>
                                            <p>개인정보 및 기업정보 제3자 제공에 대한 동의(선택)를 거부할 권리가 있습니다.</p>
                                            <p>이 제3자 제공 약관은 2023년 08월 07일부터 적용됩니다</p>
                                            <p>※ 문의 및 동의 철회 : 02-561-1898</p>

                                    </div>
                                </div>
                            </section>
                            <div className={FirstConditionsStyle.btns}>
                                <button
                                    onClick={() => {
                                        setConditions(false)
                                    }}
                                >닫기</button>
                                <button onClick={policySubmit}>완료</button>
                            </div>
                        </div>
                    : null}
                    {/*반응형*/}
                    {resize <= 1023 &&
                        <div className={FirstConditionsStyle.modal}>
                            <section className={FirstConditionsStyle.top}>
                                <input
                                    type="checkbox"
                                    id='checkAll'
                                    onChange={checkModalAllChange}
                                    checked={cpAllAgree}
                                />
                                <label htmlFor="checkAll">전체동의</label>
                            </section>


                            <section className={FirstConditionsStyle.contents}>

                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>서비스 이용약관(필수)</h1>
                                    </div>



                                    <div className={FirstConditionsStyle.txt}>
                                        <figure className={FirstConditionsStyle.txtCon}>
                                            <h2>제 1장 총칙</h2>
                                            <h3>제1조 목적</h3>
                                            <p>본 약관은 엑시토(EXITO 이하 “회사”라 한다)가 운영하는 웹/앱에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)의 이용 및 제공 등에 관한 제반 사항의 규정을 목적으로 합니다.</p>
                                        </figure>

                                        <figure className={FirstConditionsStyle.txtCon}>
                                            <h2>제2장 서비스 이용 및 제한</h2>
                                            <h3>제5조 회원가입</h3>
                                            <p>1. 이용자는 “회사”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>2. “회사”는 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</p>
                                                <span>1. 가입신청자가 이 약관 제6조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제6조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “회사”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.</span>
                                                <span>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</span>
                                                <span>3. 기타 회원으로 등록하는 것이 “회사”의 기술상 현저히 지장이 있다고 판단되는 경우</span>
                                            </figure>
                                            <p>3. 회원가입계약의 성립 시기는 “회사”의 승낙이 회원에게 도달한 시점으로 합니다.</p>
                                            <p>4. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “회사”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제6조 회원 탈퇴 및 자격 상실 등</h3>
                                            <p>1 . 회원은 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시 회원탈퇴를 처리합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>2. 회원이 다음 각 호의 사유에 해당하는 경우, “회사”는 회원자격을 제한 및 정지시킬 수 있습니다.</p>
                                                <span>1. 가입 신청 시에 허위 내용을 등록한 경우</span>
                                                <span>2. “회사”를 이용하여 구입한 재화 등의 대금, 기타 “회사”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</span>
                                                <span>3. 다른 사람의 “회사” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</span>
                                                <span>4. “회사”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</span>
                                            </figure>
                                            <p>3. “회사”가 회원 자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “회사”는 회원자격을 상실시킬 수 있습니다.</p>
                                            <p>4. “회사”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제7조 서비스 이용</h3>
                                            <p>1. 이용자의 서비스 이용 신청은 본 약관에 대한 동의로 간주되며, 이용자가 그와 같이 서비스 이용을 신청한 때 본 약관에 따른 서비스 이용 계약이 성립됩니다. 단, 이용자는 언제든지 본 약관에 대한 동의를 철회할 수 있습니다.</p>
                                            <p>2. 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다.</p>
                                            <p>3. 회사는 정보통신 시스템 등의 점검, 보수, 교체, 고장, 통신두절, 본 약관에 위배한 행위 등으로 인하여 정상적인 서비스 제공이 곤란한 경우 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</p>
                                            <p>4. 제2항에 의한 서비스 중단의 경우, 회사는 서비스화면을 통하여 관련 내용을 이용자에게 게시합니다. 단, 회사가 통제할 수 없는 사유로 인한 서비스 중단으로 서비스화면을 통한 게시가 불가능한 경우에는 그러하지 아니합니다.</p>
                                            <p>. 회사에서 제공하는 서비스는 기본적으로 무료입니다. 단, 별도로 유료임을 명시한 서비스에 대해서는 그러하지 아니합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제8조 서비스 이용 제한</h3>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>1. 회사는 다음 각 호에 경우에는 서비스를 제공하지 아니할 수 있습니다.</p>
                                                <span>가. 이용자가 본인인증 시 다른 사람의 명의를 사용하거나 허위 정보를 등록하는 등 기타 부정한 수단을 이용하는 경우</span>
                                                <span>나. 이용자가 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</span>
                                                <span>다. 이용자가 법령위반 또는 공공질서 내지 미풍양속을 저해할 목적으로 서비스 이용 경우</span>
                                                <span>라. 이용자가 부정한 용도로 서비스를 이용하고자 하는 경우</span>
                                                <span>마. 이용자가 본 약관에 규정된 제반 사항을 위반하거나 기타 이용자의 귀책사유로 이용승낙이 곤란한 경우</span>
                                            </figure>
                                            <p>2. 본인인증 서비스의 경우 이용자가 본인인증을 위하여 일부 개인정보를 회사에 제공하여야만 그 이용이 가능합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제9조 개인정보의 수집 및 이용 등</h3>
                                            <p>1. 회사는 원칙적으로 이용자의 개인정보를 수집•이용하지 않으며, 본인인증 서비스를 제공하기 위해 필요한 최소한의 정보를 수집합니다. 다만, 회사는 이용자의 원활한 사이트 접속을 위하여 인터넷 접속정보파일(이른바 쿠키정보)을 자동으로 수집하는 장치를 운영할 수 있으며, 이용자는 자신이 이용하는 컴퓨터의 설정을 변경함으로써 회사의 인터넷 접속정보파일의 수집을 차단할 수 있습니다.</p>
                                            <p>2. 회사가 이용자의 개인정보를 수집하는 때에는 반드시 해당 이용자의 동의를 받습니다.</p>
                                            <p>3. 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 부담합니다.</p>
                                            <p>4. 기타 개인정보 관련 사항은 정보통신망 이용 촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법령 및 회사의 서비스 화면에 게시하는 개인정보 취급방침에 의합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제10조 저작권의 귀속 및 이용제한</h3>
                                            <p>1. 회사가 제공하는 저작물에 대한 저작권 기타 지적 재산권은 회사에 귀속합니다.</p>
                                            <p>2. 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다</p>
                                        </figure>

                                        <figure>
                                            <h3>제11조 서비스 이용중지</h3>

                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>1. 회사는 이용자가 다음 사항에 해당하는 행위를 하였을 경우, 상당한 기간을 정하여 서비스 이용을 중지할 수 있습니다.</p>
                                                <span>가. 공공질서 내지 미풍양속에 반하는 행위를 한 경우</span>
                                                <span>나. 범죄적 행위에 관련되는 경우</span>
                                                <span>다. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획하거나 실행할 경우</span>
                                                <span>라. 타인의 본인인증 정보 등 개인정보를 도용한 경우</span>
                                                <span>마. 타인의 명예를 손상시키거나 불이익을 주는 경우</span>
                                                <span>바. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우</span>
                                                <span>사. 기타 관련 법령이나 본 약관에 위배되는 경우</span>
                                            </figure>
                                            <p>2. 회사는 서비스 이용을 중지하고자 하는 경우 그 사유, 일시 및 기간을 정하여 서면 또는 전화 등의 방법을 이용하여 해당 이용자 또는 대리인에게 통지합니다. 다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 위 통지 없이 서비스 이용을 중지할 수 있습니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제12조 서비스 이용중지의 해제</h3>
                                            <p>1. 전조의 규정에 의하여 서비스 이용중지를 통지 받은 이용자 또는 그 대리인은 서비스 이용중지에 대하여 이의 신청을 할 수 있습니다.</p>
                                            <p>2. 회사는 이용중지 사유가 해소된 것이 확인된 경우에 한하여 이용중지 조치를 즉시 해제합니다.</p>
                                        </figure>

                                        <figure>
                                            <h2>제3장 의무와 책임</h2>
                                            <h3>제13조 회사의 의무</h3>
                                            <p>1. 회사는 본 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.</p>
                                            <p>2. 회사는 이용자로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 처리하며, 그에 일정 기간이 소요될 경우 이용자에게 그 사유와 처리 일정을 알려주어야 합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>제14조 이용자의 의무</h3>
                                            <p>1. 이용자는 본인인증에 이용될 수 있는 정보(예를 들어, 아이디와 비밀번호, 공인인증서 비밀번호 등) 및 장치 등에 대한 관리의 책임을 부담하여, 이와 관련하여 이용자의 귀책사유로 인해 발생한 손해에 대하여 회사는 책임을 지지 않습니다.</p>
                                            <p>2. 이용자는 본인인증에 이용될 수 있는 자신의 정보가 도난 당하거나 제3자가 해당 정보를 사용하고 있음을 인지하는 경우 반드시 회사에 그 사실을 통보해야 합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>3. 이용자는 본 약관 및 관계 법령에서 규정한 사항을 준수하여야 하며, 다음 행위를 하여서는 안됩니다.</p>
                                                <span>가. 본인인증 시 다른 사람의 명의를 사용하거나 허위 정보를 등록하는 등 기타 부정한 수단의 이용</span>
                                                <span>나. 회사가 운영하는 웹사이트에 게시된 정보의 임의적인 변경</span>
                                                <span>다. 회사가 허락하지 않은 정보 등의 송신 또는 게시</span>
                                                <span>라. 회사, 기타 제3자의 저작권 등 지적재산권에 대한 침해</span>
                                                <span>마. 회사, 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</span>
                                                <span>바. 외설 또는 폭력적인 메시지, 화상, 음성 기타 미풍양속에 반하는 정보를 공개 또는 게시하는 행위</span>
                                            </figure>

                                            <figure>
                                                <h3>제15조 손해배상</h3>
                                                <p>회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우, 그 손해를 배상하여야 합니다. 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보 취급방침에서 정하는 내용에 위반하지 않는 한 어떠한 손해에 대하여도 책임을 지지 않습니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제16조 면책조항</h3>
                                                <p>1. 회사는 천재지변 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.</p>
                                                <p>2. 회사는 이용자의 귀책사유로 인한 서비스 이용 장애에 대하여는 책임을 지지 않습니다.</p>
                                                <p>3. 회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</p>
                                                <p>4. 회사는 이용자가 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</p>
                                                <p>5. 회사는 서비스 이용과 관련하여 이용자에게 발생한 손해 가운데 이용자의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</p>
                                                <p>6. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</p>
                                                <p>7. 회사는 이용자의 컴퓨터 오류에 의해 손해가 발생한 경우 또는 이용자가 본인인증 서비스를 이용함에 있어 자신의 개인정보를 부실하게 기재하여 손해가 발생한 경우에 책임을 지지 않습니다.</p>
                                            </figure>
                                            <figure>
                                                <h2>제4장 일반사항</h2>
                                                <h3>제17조 이용자의 게시물 관리</h3>
                                                <p>회사는 이용자가 게시하거나 등록한 내용물이 다음 사항에 해당된다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.</p>
                                                <p>1. 스팸(spam)성 게시물 등과 같이 회사에서 승인하지 아니한 광고, 판촉물을 게시하는 경우 및 서비스와 무관할 수 있는 내용인 경우</p>
                                                <p>2.  회사, 다른 이용자 또는 제3자의 명예를 손상시키는 비방 또는 중상모략일 수 있는 경우</p>
                                                <p>3. 공공질서 및 미풍양속에 위반되는 내용인 경우</p>
                                                <p>4. 범죄적 행위에 부합된다고 인정되는 내용인 경우</p>
                                                <p>5. 타인의 저작권 등 기타의 권리를 침해하는 내용인 경우</p>
                                                <p>6. 기타 관계 법령이나 본 약관에 위배되는 내용인 경우</p>
                                            </figure>

                                            <figure>
                                                <h3>제18조 이용자에 대한 통지 및 정보제공</h3>
                                                <p>1. 회사가 본인인증 서비스 이용자에 대한 통지를 하는 경우 해당 이용자가 회사에 미리 제공한 전자우편주소로 이메일을 보내거나 휴대폰 번호로 문자메시지를 보내는 방법 등으로 통지할 수 있습니다.</p>
                                                <p>2. 회사는 불특정다수의 이용자에 대한 통지의 경우 1주일이상 게시판에 게시하는 방법으로 할 수 있습니다.</p>
                                                <p>3. 회사는 본인인증 서비스 이용자에게 해당 서비스 이용에 필요가 있다고 인정되는 각종 정보를 전자우편, SMS, 전화 등의 방법으로 제공할 수 있습니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제19조 일부 무효</h3>
                                                <p>관련 법령 등에 의하여 본 약관의 일부 규정이 무효가 될 경우, 나머지 규정만으로 그 목적 달성이 불가능하거나 이용자에게 부당하게 불리하지 아니하는 이상 본 약관은 그 효력을 유지합니다.</p>
                                            </figure>

                                            <figure>
                                                <h3>제20조 재판관할 및 준거법</h3>
                                                <p>1. 회사의 유료 서비스 이용 회원의 경우 당해 서비스와 관련하여서는 회사가 별도로 정한 약관 및 정책에 따릅니다.</p>
                                                <p>2. 서비스 이용과 관련하여 회사와 이용자 간에 발생하는 분쟁 관련 소송의 관할 법원은 민사소송법상의 관할을 가지는 대한민국 법원으로 하며, 준거법은 대한민국법으로 합니다.</p>
                                            </figure>

                                            <figure>
                                                <p>(부칙)</p>
                                                <p>1. 본 약관은 2023년 08월 07일부터 적용됩니다.</p>
                                            </figure>

                                        </figure>
                                    </div>

                                    <p style={{color:'#FF0000',marginTop:'10px',fontSize:'14px'}}>*로그인 이후 필수 약관 동의는 변경할 수 없습니다.회원 탈퇴 시, 필수 동의 약관이 철회 처리됩니다.</p>
                                    <section>
                                        {policyPopList.map((v,i) => {
                                            const num = ("00" + i).slice(-2);
                                            return (
                                                <label htmlFor={`service_cp_${num}`} key={i}>
                                                    <input
                                                        type="radio"
                                                        value={v.value}
                                                        name="servicePush1"
                                                        id={`service_cp_${num}`}
                                                        onChange={chkModalChange}
                                                        disabled={!isMain}
                                                        checked={cpRequireObj.servicePush == v.value }
                                                    />
                                                    <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                </label>
                                            )
                                        })}
                                    </section>
                                </div>

                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>개인정보 수집 및 이용동의(필수)</h1>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h2>개인정보 처리방침</h2>
                                            <p>엑시토 (엑시토 이하 "회사")는 이용자의 개인 정보를 보호하기 위하여 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 및 「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.</p>
                                            <p>회사는 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집, 이용되고 있으며, 이용자의 개인정보 보호를 위해 회사가 어떠한 조치를 취하고 있는지 알려드립니다.</p>
                                            <p>본 개인정보 처리 방침은 관련 법의 개정이나 회사의 정책에 따라 변경될 수 있으며, 회사는 웹사이트를 통하여 이를 알려드리오니, 웹사이트 이용 시에 수시로 확인하여 주시기 바랍니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>1. 처리하는 개인정보의 항목</h3>
                                            <p>“회사”는 하기 명시된 개인정보 항목을 수집하여 처리합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p> [필수] 수집항목 : </p>
                                                <span>1.담당자 정보 : 회사 이메일, 휴대전화번호</span>
                                                <span>2.기업 정보 : 사업자 형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업소재지</span>
                                                <span>3.로그인 정보 : 프로필사진, 생년월일, 성별, 연령대, 이메일, 이름</span>
                                            </figure>
                                            <p>[선택] 수집항목 : 없음</p>
                                        </figure>

                                        <figure>
                                            <h3>2. 개인정보의 처리 목적</h3>
                                            <p>“회사”는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                                            <p>가. 서비스 제공을 위한 정보 활용 : 서비스 이용에 따른 본인 확인, 만 14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 콘텐트 제공, 개인 식별, 부정 이용 방지와 비인가 사용 방지, 만족도 조사, 분쟁 조정을 위한 기록 보존, 불만 민원 처리, 고지사항 전달의 목적으로 활용</p>
                                            <p>나. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 : 유료 서비스 결제 시 활용</p>
                                            <p>다. 마케팅 및 광고에 활용 : 신규 서비스 개발, 인구 통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악, 서비스 이용에 대한 통계, 이벤트 경품 배송, 고객 지향적 이벤트와 광고성 정보 전달</p>
                                        </figure>

                                        <figure>
                                            <h3>3. 개인정보의 처리 및 보유 기간</h3>
                                            <p>1. 회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간(회원탈퇴 혹은 위탁 계약 종료 시)이 도래하면 해당 정보를 지체 없이 파기합니다. </p>
                                            <p>2. 제1항에도 불구하고 ‘회사’는 아래 개인정보항목에 대하여 관련 법령에 따른 보유 기간을 적용합니다.</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>상법, 국세기본법, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 이 경우 회사는 보관하는 개인정보를 그 보관의 목적으로만 이용하며 보존 기간 및 보존 항목은 아래와 같습니다</p>
                                                <span>- 계약 또는 철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)</span>
                                                <span>- 납세 증거에 관한 기록 : 5년(국세 기본법)</span>
                                                <span>- 보존 항목 : </span>
                                                <span>1. 담당자 정보 : 회사 이메일, 휴대전화번호</span>
                                                <span>2. 기업 정보 : 사업자 형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업 소재지</span>
                                                <span>3. 로그인 정보 : 프로필사진, 생년월일, 성별, 연령대, 이메일, 이름</span>
                                            </figure>
                                        </figure>


                                        <figure>
                                            <h3>4. 개인정보 파기에 관한 사항</h3>
                                            <p>1. 파기절차</p>
                                            <p>서비스 이용을 위해 입력하신 정보는 목적이 달성된 후 기타 관련 법령에 의한 개인정보보관 사유에 해당하지 않는 한 지체없이 파기됩니다.</p>

                                            <p>2. 파기방법</p>
                                            <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>5. 개인정보 자동 수집의 목적 및 거부에 관한 사항</h3>
                                            <p>회사는 이용자의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)', 'ActiveX'등 개인정보를 자동으로 수집하는 장치(이하 “쿠키등”)를 설치 운용합니다. 쿠키란 서비스 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.</p>
                                            <p>가. 쿠키등 사용 목적</p>
                                            <p>① 이용자의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 개선에 활용</p>
                                            <p>② 웹사이트 방문 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 웹사이트 방문 때 제품 정보를 제공</p>
                                            <p>나. 쿠키 설정 거부 방법</p>
                                            <p>① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 쿠키 설정을 거부하는 방법으로는 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</p>
                                            <p>② 설정방법의 예시(웹 브라우저의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보에서 변경. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</p>
                                            <p>③ 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를 설정합니다.</p>
                                            <p>④ 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.</p>
                                            <p>③ ActiveX 설정 거부 방법</p>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>A. 이용자는 ActiveX 설치에 대한 선택권을 가지고 있습니다. ActiveX 설정을 거부하는 방법은 다음과 같습니다.</p>
                                                <span>1) 웹 브라우저 상단의 도구 > 인터넷 옵션 > 보안 탭 클릭하여 하단의 사용자 지정수준 클릭 > ActiveX 컨트롤 및 플러그 인 항목에서 아래와 같이 체크함 </span>
                                                <span>-바이너리 및 스크립트 동작 => 사용안함 </span>
                                                <span>서명 된 ActiveX 컨트롤 다운로드 => 사용안함 </span>
                                                <span>-스크립팅하기 안전하지 않은 것으로 표시된 ActiveX 컨트롤 초기화 및 스크립팅 => 사용안함 </span>
                                                <span>-스크립팅하기 안전한 것으로 표시된 ActiveX 컨트롤 스크립트 => 사용안함 </span>
                                                <span>-ActiveX 컨트롤 및 플러그인 실행 => 사용안함 </span>
                                                <span>ActiveX 컨트롤을 자동으로 사용자에게 확인 => 사용안함</span>
                                                <span>2) 새 웹 브라우저 창을 여신 후, 다시 접속해 주시기 바랍니다. 접속이 원활하게 되지 않을 경우에는 바이러스 및 악성 코드 제거 프로그램으로 바이러스와 악성코드를 제거 후에 다시 시도해 주시기 바랍니다.</span>
                                            </figure>
                                            <p>다. 광고를 게재하는 배너에서도 광고주나 마케팅회사에 의해 쿠키가 사용되는 경우도 있음을 알려드립니다. 이 경우 당해 쿠키는 시스템 관리와 광고주에게 제공할 통계 집계 등을 위하여 이용자들의 IP 주소를 모으기도 합니다. 당해 쿠키가 수집하는 개인정보에 관한 사항은 광고주나 마케팅회사의 개인정보보호방침에 따릅니다.</p>
                                        </figure>

                                        <figure>
                                            <h3>6. 개인정보의 안전성 확보조치에 관한 사항</h3>
                                            <p>회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을 마련하고 있습니다.</p>
                                            <p>1) 기술적 보호조치</p>
                                            <p>① 이용자의 개인정보는 비밀번호에 의해 보호되며 암호 알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.</p>
                                            <p>② 해킹 등 외부 침입에 대비하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 기술적 장치를 갖추어 개인정보 보안에 만전을 기하고 있습니다.</p>
                                            <p>③ 패스워드 등 본인임을 인증하는 정보에 대해서는 복호화되지 아니하도록 일방향 암호화하여 저장합니다.</p>
                                            <p>④ 개인정보 처리 시스템에서 개인정보의 출력시(인쇄, 화면표시, 파일생성 등) 용도를 특정하며, 용도에 따라 출력 항목을 최소화합니다.</p>
                                            <p>⑤ 개인정보 처리자가 개인 정보를 종이로 인쇄하거나, 디스켓, 콤팩트디스크 등 이동 가능한 저장매체에 복사할 경우 개인정보보호 책임자의 사전 승인을 받도록 조치합니다. 출력, 복사물로부터 다시 출력 또는 복사하는 경우도 또한 같습니다.</p>


                                            <p>2) 관리적 보호조치</p>
                                            <p>① 이용자의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다.</p>
                                            <p>② 보직이동 또는 퇴직 등 인사 이동이 발생하여 개인정보처리자가 변경되었을 경우 지체 없이 개인 정보처리시스템의 접근 권한을 변경 또는 말소합니다. 이 경우 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 5년간 보관합니다</p>
                                            <p>③ 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를 설정합니다.</p>
                                            <p>④ 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.</p>
                                            <p>⑤ 입사시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.</p>
                                            <p>⑥ 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.</p>
                                            <p>⑦ 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의 서버를 통해 분리하여 보관하고 있습니다.</p>
                                            <p>⑧ 당사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.</p>
                                            <p>⑨ 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 당사는 즉각 이용자께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</p>
                                        </figure>


                                        <figure>
                                            <h3>7. 정보주체의 권리, 의무 및 그 행사방법에 관한 사항</h3>
                                            <p>이용자 및 법정대리인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자 및 법정대리인이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 및 법정대리인 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 서비스 이용이 제한될 수 있습니다. 이용자 및 법정대리인은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 이용자 및 법정대리인의 개인 정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등에 의해 처벌받을 수 있습니다.</p>
                                            <p>1) 개인정보 열람, 정정, 삭제 요구의 권리:</p>
                                            <p>이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제하실 수 있습니다. 개인정보 열람 및 정정, 삭제를 하고자 할 경우에는 개인정보보호책임자 및 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 지체 없이 조치하겠습니다.</p>
                                            <p>2) 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리:</p>
                                            <p>이용자 및 법정대리인은 서비스 이용을 위해 입력하신 개인정보의 수집, 이용, 제공, 저장에 대해 동의하신 내용을 철회하실 수 있습니다. </p>
                                            <p>동의 철회는 개인정보보호 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.</p>
                                        </figure>


                                        <figure>
                                            <h3>8. 개인정보의 열람청구를 접수, 처리하는 부서</h3>
                                            <p>개인정보 관련한 민원처리는 이용자의 개인정보를 보호하고 개인정보와 관련한 의문사항을 처리하기 위하여 다음과 같이 민원처리 콜센터를 운영하고 있습니다.</p>
                                            <p>①	전자우편 : exito@myctns.com</p>
                                            <p>②	전화번호 : 02-561-1898</p>

                                            <p>기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.</p>
                                            <p>1) 개인정보침해신고센터</p>
                                            <p>- 전화 : (국번없이) 118</p>
                                            <p>- URL : http://privacy.kisa.or.kr</p>


                                            <p>2) 개인정보분쟁조정위원회</p>
                                            <p>- 전화 : 1833-6972</p>
                                            <p>- URL : http://www.kopico.go.kr</p>

                                            <p>3) 대검찰청 사이버수사과</p>
                                            <p>전화 : (국번없이) 1301</p>
                                            <p>- URL : http://www.spo.go.kr</p>


                                            <p>4) 경찰청 사이버안전국</p>
                                            <p>전화 : (국번없이) 182</p>
                                            <p>- URL : http://cyberbureau.police.go.kr</p>
                                        </figure>

                                        <figure>
                                            <h3>9. 개인정보보호 책임자</h3>
                                            <figure className={FirstConditionsStyle.txtBox}>
                                                <p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다</p>
                                                <span>- 개인정보 보호 책임자 : CTNS 대표이사</span>
                                                <span>- 개인정보 보호 담당자 : CTNS 정보보호담당자</span>
                                                <span>- 전화번호 : 02-561-1898</span>
                                                <span>- 이메일 주소 : exito@myctns.com</span>
                                            </figure>
                                        </figure>

                                        <figure>
                                            <h3>10. 링크 사이트</h3>
                                            <p>회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공 받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭하여 타 웹사이트의 페이지로 옮겨갈 경우 해당 웹사이트의 개인정보보호 정책은 회사와 무관하므로 새로 방문한 웹사이트의 정책을 검토해 보시기 바랍니다.</p>

                                        </figure>

                                        <figure>
                                            <h3>11. 개인정보 처리방침의 변경에 관한 사항</h3>
                                            <p>이 개인정보처리방침은 법령ㆍ정책 또는 보안 기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 아래와 같은 방법으로 사전 공지하도록 하겠습니다.</p>
                                            <p>- 인터넷 홈페이지 첫 화면 공지 사항 란 또는 별도의 창을 통하여 수정내용 공지</p>

                                            <p>시행일자: 2023년 08월 07일</p>
                                        </figure>
                                    </div>

                                    <p style={{color:'#FF0000',marginTop:'10px',fontSize:'14px'}}>*로그인 이후 필수 약관 동의는 변경할 수 없습니다.회원 탈퇴 시, 필수 동의 약관이 철회 처리됩니다.</p>
                                    <section>
                                        {policyPopList.map((v,i) => {
                                            const num = ("00" + i).slice(-2);
                                            return (
                                                <label htmlFor={`collect_cp_${num}`} key={i}>
                                                    <input
                                                        type="radio"
                                                        value={v.value}
                                                        name="collectPush"
                                                        id={`collect_cp_${num}`}
                                                        onChange={chkModalChange}
                                                        disabled={!isMain}
                                                        checked={cpRequireObj.collectPush == v.value}
                                                    />
                                                    <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                </label>
                                            )
                                        })}
                                    </section>
                                </div>
                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>마케팅 정보 수신 동의(선택)</h1>
                                    </div>
                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h3>마케팅 정보 수신 동의</h3>
                                            <p>서비스 제공 및 이용과 관련하여 ㈜씨티엔에스가 취득한 개인정보는 "개인정보보호법" 및 "정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수합니다.</p>
                                            <p>1. 고객이 수집 및 이용에 동의한 개인정보를 활용하여, 전자적 전송 매체(App Push, 핸드폰 문자메시지(SMS,MMS), E-mail, 카카오톡 등 다양한 전송 매체)를 통하여 발송됩니다. </p>
                                            <p>2. 발송되는 마케팅 정보는 수신자에게 ㈜씨티엔에스 및 제 3자의 상품 또는 서비스에 대한 혜택 정보, 각종 이벤트 정보, 개인 맞춤형 광고 정보 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다.  단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다. </p>
                                            <p>3. 마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 엑시토 서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다. </p>
                                            <p>4. 수신동의를 받은 날부터 2년마다 (매 2년이 되는 해의 수신동의를 받은 날과 같은 날 전까지를 말한다.) 해당 수신자의 수신동의 여부를 확인합니다.</p>
                                            <p>5. ㈜씨티엔에스 고객센터를 통해 수신동의를 철회할 수 있습니다. </p>
                                        </figure>

                                        <figure>
                                            <p>※ 문의 및 동의철회 : 02-561-1898</p>
                                            <table>
                                                <tr>
                                                    <th>개인정보 수집 항목</th>
                                                    <td>개인정보 1등급: 없음</td>
                                                    <td>개인정보 2등급: 이메일,이름(또는 카카오톡 프로필 닉네임),휴대전화번호</td>
                                                </tr>
                                                <tr>
                                                    <th>개인정보 수집 이용 목적</th>
                                                    <td>마케팅 광고에 활용</td>
                                                    <td>이벤트 운영 및 광고성 정보 전송</td>
                                                </tr>
                                                <tr>
                                                    <th>보유 및 이용기간</th>
                                                    <td>-이용자가 동의를 철회하거나, 탈퇴 시까지 (마케팅 수신동의 여부 2년 주기로 확인)</td>
                                                    <td>-서비스 관련 정보 전송 단, 관련 법령의 규정에 따라 보존할 필요가 있을 경우, 해당 기간까지 보존함</td>
                                                </tr>
                                            </table>
                                        </figure>

                                        <figure>
                                            <p>이 마케팅약관은 2023년 08월 07일부터 적용됩니다.</p>
                                        </figure>
                                    </div>

                                    <section>
                                        {policyPopList.map((v,i) => {
                                            const num = ("00" + i).slice(-2);
                                            return (
                                                <label htmlFor={`marketing_cp_${num}`} key={i}>
                                                    <input
                                                        type="radio"
                                                        value={v.value}
                                                        name="marketingPushMyPage"
                                                        id={`marketing_cp_${num}`}
                                                        onChange={chkModalChange}
                                                        checked={ cpAlarmObj.marketingPush == v.value}
                                                    />
                                                    <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                </label>
                                            )})}
                                    </section>
                                </div>

                                <div className={FirstConditionsStyle.con}>
                                    <div>
                                        <h1>개인정보 및 기업정보 제 3자 제공 동의(선택)</h1>
                                    </div>


                                    <div className={FirstConditionsStyle.txt}>
                                        <figure>
                                            <h3>개인정보 및 기업정보 제3자 제공에 대한 동의</h3>
                                            <table>
                                                <tr>
                                                    <th>1.제공기관</th>
                                                    <td>- 국내 스타트업의 육성ㆍ투자ㆍ지원 관련 서비스를 운영하는 국내외 스타트업 관련 플랫폼 사업자 및 정부기관</td>
                                                    <td>- 기타 본 서비스의 활성화를 위해 필요한 기관</td>
                                                </tr>
                                                <tr>
                                                    <th>2.수집,이용 목적</th>
                                                    <td>- 국내외 스타트업 관련 플랫폼 사업자 및 정부기관과 엑시토 서비스 이용자 간의 협업 중개</td>
                                                </tr>
                                                <tr>
                                                    <th>3.수집항목</th>
                                                    <td>- 담당자 정보(회사 이메일, 전화번호)</td>
                                                    <td>- 기업 정보(사업자형태, 기업명, 대표자명, 업태, 제품/서비스, 기업설립년도, 기업 소재지)</td>
                                                    <td>- 로그인 정보는 제3자에게 제공하지 않음</td>
                                                </tr>
                                                <tr>
                                                    <th>4.보유-이용기간</th>
                                                    <td>- 회원 탈퇴 시 혹은 위탁 계약 종료 시</td>
                                                </tr>
                                                <tr>
                                                    <th>5.개인/기업정보 제3자 제공 동의 거부시 불이익</th>
                                                    <td>- 개인정보 및 기업정보 제3자 제공에 대한 동의를 거부할 수 있으며, 동의하지 않을 경우, 엑시토 서비스에서 제공하는 특별 혜택(무료 이벤트, 무료 특강 등)에 참여 제한이 있을 수 있습니다. </td>
                                                </tr>
                                            </table>
                                        </figure>
                                        <p>개인정보 및 기업정보 제3자 제공에 대한 동의(선택)를 거부할 권리가 있습니다.</p>
                                        <p>이 제3자 제공 약관은 2023년 08월 07일부터 적용됩니다</p>
                                        <p>※ 문의 및 동의 철회 : 02-561-1898</p>
                                    </div>

                                    <section>
                                        {policyPopList.map((v,i) => {
                                            const num = ("00" + i).slice(-2);
                                            return (
                                                <label key={i}  htmlFor={`personal_cp_${num}`}>
                                                    <input
                                                        type="radio"
                                                        value={v.value}
                                                        id={`personal_cp_${num}`}
                                                        name="personalInfoAgreeMyPage"
                                                        onChange={chkModalChange}
                                                        checked={cpAlarmObj.personalInfoAgree == v.value}
                                                    />
                                                    <span>{i == 0 ? "동의함" : "동의하지 않음"}</span>
                                                </label>
                                            )
                                        })}
                                    </section>

                                </div>
                            </section>
                            <div className={FirstConditionsStyle.btns}>
                                <button
                                    onClick={() => {
                                        setConditions(false)
                                    }}
                                >닫기</button>
                                <button onClick={policySubmit}>완료</button>
                            </div>
                        </div>
                    }


                </div>


            :null}

        </>
    );
};

export default FirstConditions;
