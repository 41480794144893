import React, { useState , useEffect ,useCallback} from "react";
import styles from "scss/components/H_friendsList.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import * as api from "../../lib/api";
import {useParams} from "react-router-dom";


const H_friends = ({ item }) => {

    const {corp_nm} = useParams();


    async function handleClick() {
        let data = {
            "tl_page_type": "추천컨텐츠",
            "tl_page_depth": "2",
            "tl_page_name": "B2B지원사업",
            "tl_button_name": "컨텐츠버튼",
            "tl_type_cd": item.url.replace("https://exitobiz.co.kr/community/communityView/",""),
            "tl_event": "클릭",
            "tl_memo": item.title
        }
        let res = await api.getCommonCode(corp_nm.toLowerCase());
        api.insertCorpTimeLine(data, res.data.code).then(r => {
        });
        window.open(item.url, "_blank");
    }



    const cost = item.cost;
    const isCostNone = cost === 0 || cost === "";
    const CostNonestyle = {opacity:"0"};

    return (
        <>
            <div className={styles.friendsWrap}>
                <div className={styles.innerFriendsWrap}>
                    <div className={styles.BoxListItem}>
                        <button type="button" onClick={handleClick}>
                            <img className={styles.img} src={item.img} alt={item.title}/>
                        </button>
                    </div>
                    <h5 className={styles.title}>
                        {item.title}
                    </h5>
                    <p>{
                        isCostNone ? <p style={CostNonestyle}>원</p> :
                        cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '원'
                    }
                    </p>
                </div>
            </div>
        </>
    );
};

export default H_friends;
