// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hashtag_hashtagWrap__jQHGe {\n  box-sizing: border-box;\n  margin: 10px 0px 15px 0px; }\n  .Hashtag_hashtagWrap__jQHGe .Hashtag_hashtagCon__pljUk {\n    display: flex;\n    box-sizing: border-box; }\n    .Hashtag_hashtagWrap__jQHGe .Hashtag_hashtagCon__pljUk > li {\n      box-sizing: border-box;\n      margin-right: 30px;\n      cursor: pointer; }\n", "",{"version":3,"sources":["webpack://./src/scss/components/Hashtag.module.scss"],"names":[],"mappings":"AAGA;EACE,sBAAsB;EACtB,yBAAyB,EAAA;EAF3B;IAKI,aAAa;IACb,sBAAsB,EAAA;IAN1B;MASM,sBAAsB;MACtB,kBAAkB;MAClB,eAAe,EAAA","sourcesContent":["\n\n\n.hashtagWrap{\n  box-sizing: border-box;\n  margin: 10px 0px 15px 0px;\n\n  .hashtagCon{\n    display: flex;\n    box-sizing: border-box;\n\n    > li{\n      box-sizing: border-box;\n      margin-right: 30px;\n      cursor: pointer;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hashtagWrap": "Hashtag_hashtagWrap__jQHGe",
	"hashtagCon": "Hashtag_hashtagCon__pljUk"
};
export default ___CSS_LOADER_EXPORT___;
