import React, { useEffect, useState } from "react";
import CountArea from "components/home/CountArea";
import SnsLogin from "components/home/LoginArea";
import Banner from "components/ImageBanner";
import Event from "components/home/Event";
import Filter from "components/home/Filter";
import HomeCommunity from "components/home/HomeCommunity";
import HomeSupport from "components/home/HomeSupport";
import SignInPolicyModal from "components/home/SignInPolicyModal";
import styles from "scss/pages/Home.module.scss";
import style from "scss/components/Filter.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {setIsAdmin, setLoginCheck, setUserInfo, signIn} from "redux/store";
import * as api from '../lib/api';
import FirstLoginPerson from "../components/home/FirstLoginPerson";
import FirstLoginCompany from "../components/home/FirstLoginCompany";
import SearchForm from "../components/SearchForm";
import NewHomebox from "../components/home/NewHomebox";

const Home = ({}) => {
  const dispatch = useDispatch();
  const kakaoInform = useSelector((state) => state.kakaoInform);
  const appleInform = useSelector((state) => state.appleInform);
  const isMobile = useSelector((state) => state.isMobile);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const [modalOn, setModalOn] = useState(false);
  const [Modal1, setModal1] = useState(false);
  const [Modal2, setModal2] = useState(false);
  const marketingCheck = useSelector((state) => state.marketingCheck);
  const userInfo = useSelector((state) => state.userInfo);

  const [openModal, setOpenModal] = useState(false);

  //약관동의
  const [allServiceChecked, setAllServiceChecked] = useState(false);
  const [ServicePolicyObj, setServicePolicyObj] = useState({
    policy_0: false,
    policy_1: false,
    policy_2: false,
    policy_3: false,
  });





  // const [Service , setService] = useState(false); //서비스 이용약관(필수)
  // const [Personal , setPersonal] = useState(false); //개인정보 수집 및 이용동의(필수)
  // const [Marketing , setMarketing] = useState(false); //마케팅 정보 수신 동의(선택)
  // const [Another , setAnother] = useState(false); //개인정보 및 기업정보 제 3자 제공 동의(선택)




  // console.log('alarmObj(Home에 선언)>>>>>>>>>>',alarmObj)


  //
  // useEffect(() => {
  //   Modal();
  // }, []);
  //
  //
  // function Modal(){
  //   if(userInfo.id){
  //     setFirstLoginPersonM(true)
  //   }else{
  //     setFirstLoginPersonM(false)
  //   }
  // };
  //


  const [count, setCount] = useState({
    total_cnt: 0,
    week_cnt: 0,
    user_cnt: 0,
    target_cnt: 0,
  });

  const modalOpener = (e) => {
    const { currentTarget: { name, value } } = e;
    const isTrue = value == "true";
    // name, value, target
    setModalOn(isTrue);
    if (name === "Modal1") {
      setModal1(isTrue);
    } else if (name === "Modal2") {
      setModal2(isTrue);
    }
  };

  const [lastCheck, setLastCheck] = useState(false);

  useEffect(() => {
    if (kakaoInform.state || appleInform.state) {
      setLastCheck(true);
    }
  }, [kakaoInform, appleInform]);



  const [noticeList, setNoticeList] = useState({});
  const [getSupportableCnt, setGetSupportableCnt] = useState(0);
  const [getTotalCountList, setGetTotalCountList] = useState(0);

  Number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  useEffect(() => {
    let userid = null;
    if(sessionStorage.getItem("userId") != null) {
      userid = sessionStorage.getItem("userId").replace(/"/gi, '');
    }

    if (isMobile) {
      api.getTotalCount(userid).then((res) => {
        setGetTotalCountList(res.data.save_cnt);
      })

      api.getSupportableCnt(userid).then((res) => {
        setGetSupportableCnt(res.data.count);
      })

      api.getNoticeList().then((res) => {
        const data = res.data.filter((x) => x.activeYn == "Y");
        setNoticeList(data[0]);
      })

      if(userid != null) {
        api.chkMarketPush(userid).then((res) => {
          setOpenModal(res.data);
        })
      }
    }
  }, [isMobile , isLoggedIn]);

  var dt = new Date(); // 현재 날짜 및 시간
  var str = (dt.getMonth()+1)+'월 '+dt.getDate()+'일';





  return (
    <>
      <div className={styles.Home}>
        <section className={styles.sec01}>
          <div className={`inner ${styles.inner}`}>
            <SnsLogin />
            <Banner />
            {/*{openModal && isLoggedIn && (*/}

            {!isMobile && (
              <Event
                setModalOn={setModalOn}
                modalOn={modalOn}
                modalOpener={modalOpener}
                Modal2={Modal2}
              />
            )}

          </div>
        </section>


        <div className={`inner ${styles.inner}`}>


          {/*<Filter*/}
          {/*  modalOpener={modalOpener}*/}
          {/*  setModalOn={setModalOn}*/}
          {/*  modalOn={modalOn}*/}
          {/*  Modal1={Modal1}*/}
          {/*/>*/}




           <NewHomebox/>

          {isMobile && (
              <CountArea count={count} setCount={setCount} />
          )}





          {/*<div style={{border:'1px solid red', boxSizing:'border-box'}}>*/}
          {/*  <div style={{border:'1px solid blue',boxSizing:'border-box'}}>*/}
          {/*  </div>*/}
          {/*  <div style={{border:'1px solid green',boxSizing:'border-box'}}>*/}
          {/*  </div>*/}
          {/*  /!*<SearchForm />*!/*/}
          {/*</div>*/}



          <div className={styles.sec02}>

            {!isMobile && (
                <CountArea count={count} setCount={setCount} />
            )}
            {/*{isMobile && !isLoggedIn ? (*/}
            {/*    <div className={styles.mobileBox}>*/}
            {/*      <section className={styles.mobileTit}>*/}
            {/*        <span>기업정보 설정</span>*/}
            {/*        <Link*/}
            {/*            className={styles.linkSaved}*/}
            {/*            onClick={(e) => {*/}
            {/*              if (!isLoggedIn) {*/}
            {/*                e.preventDefault();*/}
            {/*                dispatch(setLoginCheck(true));*/}
            {/*              }*/}
            {/*            }}*/}
            {/*            to="/myPage"*/}
            {/*        >*/}
            {/*           <span>*/}
            {/*                맞춤 지원사업*/}
            {/*              </span>*/}
            {/*        </Link>*/}
            {/*        <img*/}
            {/*            src={require("assets/img/mobile/btn_next_gray.png")}*/}
            {/*            alt=">"*/}
            {/*        />*/}
            {/*      </section>*/}
            {/*    </div>*/}
            {/*):(*/}
            {/*    <div className={styles.titArea}>*/}
            {/*      <Link*/}
            {/*          className={styles.linkSaved}*/}
            {/*          onClick={(e) => {*/}
            {/*            if (!isLoggedIn) {*/}
            {/*              e.preventDefault();*/}
            {/*              dispatch(setLoginCheck(true));*/}
            {/*            }*/}
            {/*          }}*/}
            {/*          to="/myPage"*/}
            {/*      >*/}

            {/*        <div>*/}
            {/*          <h3>{userInfo.usernickname}님</h3>*/}
            {/*          <span>*/}
            {/*          기업정보 설정하고 맞춤정보 보기 >*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      </Link>*/}
            {/*    </div>*/}
            {/*)}*/}
            {!isMobile && <HomeCommunity />}

            {isMobile && (
                <>
            {!isLoggedIn ? (
                <div className={styles.mobileBox}>
                    <section className={styles.mobileTit}>
                      <span>기업정보 설정</span>
                      <Link
                          className={styles.linkSaved}
                          onClick={(e) => {
                            if (!isLoggedIn) {
                              e.preventDefault();
                              dispatch(setLoginCheck(true));
                            }
                          }}
                          to="/myPage"
                      >
                       <span>
                            맞춤 지원사업 보기
                          </span>
                      </Link>
                      <img
                          src={require("assets/img/mobile/btn_next_gray.png")}
                          alt=">"
                      />
                    </section>
                  <section className={styles.mobileTit}>
                    {/*<img*/}
                    {/*    src={require("assets/img/global/ico/ico_zzim_black.png")}*/}
                    {/*    alt="지원사업"*/}
                    {/*/>*/}
                    <span>지원사업</span>
                    {/*<h6>{str}</h6>*/}
                    <Link
                        onClick={(e) => {
                          if (!isLoggedIn) {
                            e.preventDefault();
                            dispatch(setLoginCheck(true));
                          }
                        }}
                        className={styles.linkSaved}
                        to="/support/supportList"
                    >
                      지원 가능 사업
                      <mark>{getSupportableCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</mark>개
                    </Link>
                    <img
                        src={require("assets/img/mobile/btn_next_gray.png")}
                        alt=">"
                    />
                  </section>

                  <section className={styles.mobileTit}>
                    {/*<img*/}
                    {/*  src={require("assets/img/mobile/mobileSaved.png")}*/}
                    {/*  alt="찜"*/}
                    {/*/>*/}
                    <span>찜</span>
                    <Link
                        className={styles.linkSaved}
                        onClick={(e) => {
                          if (!isLoggedIn) {
                            e.preventDefault();
                            dispatch(setLoginCheck(true));
                          }
                        }}
                        to="/saved?cate=save"
                    >
                      {!isLoggedIn ? (
                          <p>
                        {/*<mark>로그인</mark> 후 */}
                            관심사업 쉽게 관리
                      </p>
                      ) : (
                          <span>
                      찜한 사업
                      <mark className={styles.numb}>
                        {getTotalCountList}
                      </mark>개
                      </span>
                      )}
                    </Link>
                    <img
                        src={require("assets/img/mobile/btn_next_gray.png")}
                        alt=">"
                    />
                  </section>

                </div>
              ) : (
                <div className={styles.mobileBox}>
                  <section className={styles.mobileTit}>
                    {/*<img*/}
                    {/*    src={require("assets/img/global/ico/ico_zzim_black.png")}*/}
                    {/*    alt="지원사업"*/}
                    {/*/>*/}
                    <span><h3 className={styles.userName}>{userInfo.usernickname}</h3><h3 className={styles.nim}>님</h3></span>
                    <Link className={styles.linkSaved} to="/support/supportList">
                      맞춤 지원 사업
                      <mark>{getSupportableCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</mark>개
                    </Link>
                    <img
                        src={require("assets/img/mobile/btn_next_gray.png")}
                        alt=">"
                    />
                  </section>
                  <section className={styles.mobileTit}>
                    {/*<img*/}
                    {/*    src={require("assets/img/mobile/mobileSaved.png")}*/}
                    {/*    alt="찜"*/}
                    {/*/>*/}
                    <span>저장한 찜</span>
                    <Link
                        className={styles.linkSaved}
                        onClick={(e) => {
                          if (!isLoggedIn) {
                            e.preventDefault();
                            dispatch(setLoginCheck(true));
                          }
                        }}
                        to="/saved?cate=save"
                    >
                      {!isLoggedIn ? (
                          <span>
                        {/*<mark>로그인</mark> 후 */}
                            관심사업 쉽게 관리
                      </span>
                      ) : (
                          <span>
                      관심 사업
                      <mark className={styles.numb}>
                        {getTotalCountList.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </mark>개
                      </span>
                      )}
                    </Link>
                    <img
                        src={require("assets/img/mobile/btn_next_gray.png")}
                        alt=">"
                    />
                  </section>
                </div>
              )}

            {/*{isMobile && (*/}
            {/*    <>*/}
                  <div className={styles.mobileBox}>
                  <h3 className={styles.mobileTit}>
                    {/*<img*/}
                    {/*  src={require("assets/img/mobile/mobileComm.png")}*/}
                    {/*  alt=""*/}
                    {/*/>*/}
                    <span>커뮤니티</span>
                  </h3>
                  {/* <Link
                    className={styles.linkComm}
                    to="/community/communityList"
                  >
                    기업 운영에 관한 모든 것들을 커뮤니티에서 공유해봐요!
                  </Link> */}
                  <HomeCommunity />
                </div>
                <div className={styles.mobileBox}>
                  <h3 className={styles.mobileTit}>
                    {/*<img*/}
                    {/*  src={require("assets/img/mobile/mobileEvent.png")}*/}
                    {/*  alt=""*/}
                    {/*/>*/}
                    <span>더 많은 서비스</span>
                  </h3>
                  <Event
                    setModalOn={setModalOn}
                    modalOn={modalOn}
                    modalOpener={modalOpener}
                    Modal2={Modal2}
                  />
                </div>
              </>
            )}

          </div>
          <HomeSupport />
          {isMobile && Object.keys(noticeList).length > 0 && (
            <>
              <div className={styles.mobileBox1}>
                <h3 className={styles.mobileTit1}>
                  {/*<img src={require("assets/img/mobile/mobileNoti.png")} />*/}
                  <span>공지사항</span>
                </h3>
                <Link
                  className={`white ${styles.linkComm1} ${styles.homeNotice1}`}
                  to={`/notice/noticeList`}
                  style={{ fontSize: "16px" }}
                >
                  {noticeList.title}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && (
        <style>
          {
            "\
                  #wrap > div[class^='Header']{\
                    display:flex;\
                  }\
                "
          }
        </style>
      )}
      {lastCheck &&
        <FirstLoginPerson
          setLastCheck={setLastCheck}
          kakaoInform={kakaoInform}
          appleInform={appleInform}
          isFirst={true}
        />
      }
      {/*{lastCheck && <FirstLoginPerson */}
      {/*/>}*/}
    </>
  );
};
export default Home;
