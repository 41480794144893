import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import BannerCss from "scss/components/Banner.module.scss";
import MobileNavigation from "../MobileNavigation";
import * as api from "../../lib/api";
import {useParams} from "react-router-dom";

const Banner_Modal = ({
                          Modals,
                          setModals,
                          bannerModal1,
                          setBannerModal1,
                          bannerModal2,
                          setBannerModal2,
                          bannerModal3,
                          setBannerModal3,
                          title,
                }) => {

    const inputCss = {width:'16px', marginRight:'4px'}
    const position = {position: 'relative'}

    const inputRef = useRef();

    const {corp_nm} = useParams();
    const {user_id} = useParams();
    const [corp, setCorp] = useState('')
    const [sector, setSector] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [business, setBusiness] = useState('');
    const [karma, setKarma] = useState('1년차');


    const [emailtype, setEmailtype] = useState('');
    const [phonetype, setPhonetype] = useState('');


    const [corpValidation, setCorpValidation] = useState(false);
    const [sectorValidation, setSectorValidation] = useState(false);
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailTypeValidation, setEmailTypeValidation] = useState(false);
    const [infoValidation, setInfoValidation] = useState(false);
    const [telValidation, setTelValidation] = useState(false);
    const [telPhoneValidation, setTelPhoneValidation] = useState(false);
    const [marketingValidation, setMarketingValidation] = useState(false);
    const [businessValidation, setBusinessValidation] = useState(false);

    const [Agree, setAgree] = useState(false);

    const [marketingAgree, setmarketingAgree] = useState('');


    // 이메일 유효성 검사

    const checkEmail = (e) => {
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
        setEmailtype(regExp.test(e.target.value))
    }

    const checkPhonenumber = (e) => {
        // '-' 입력 시
        // var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/

        // 숫자만 입력시
        var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/



        setPhonetype(regExp.test(e.target.value))
        // console.log('핸드폰 유효성검사>>>>>> ',phonetype )
    }



    const BannerModals = async (e, move) => {

        if(move == 'move'){
            if(!Agree || corp == '' || sector == '' || email == ''){
                // console.log("move >> ", e);
                inputRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
        }

        e.preventDefault();
        var data = {
            corp_nm : corp,
            sector : sector,
            email : email,
            title : 'B2B마케팅 2차 무료 특강',
            tel: tel,
            corp_hist : karma,
            corp_addr : business,
            marketing_yn: marketingAgree
        }


        // console.log('datadata>>>>>',data)



        if(Agree == true && corp !== '' && sector !== '' && email !== '' && tel !== '' && tel !== undefined && emailtype !== false && phonetype !== false && business !== '' && marketingAgree && tel.length == 11){




            let corp_cd = await api.getCommonCode(corp_nm.toLowerCase());

            let headers = {
                user_id : user_id,
                corp_cd :corp_cd.data.code
            }

            // console.log('headers>>>',headers)

            let res = await api.saveRegist(data, headers);


            // console.log('corp_cd>>>',corp_cd)
            // console.log('왜이랴>>',res)
            // console.log('res>>>>>>',res.data)


            if(res.data == 'success'){
                //벨리데이션 지우기
                setCorpValidation(false);
                setSectorValidation(false);
                setEmailValidation(false);
                setEmailTypeValidation(false)
                setInfoValidation(false);
                setTelValidation(false);
                setTelPhoneValidation(false)
                setMarketingValidation(false)
                setBusinessValidation(false)



                // console.log('tel.length1111>>>>>',tel.length)


                alert('신청이 완료되었습니다');


                //개인정보수집동의 상태값
                setAgree(false);

                //input 빈값으로 만들기
                setCorp('');
                setSector('');
                setEmail('');
                setTel('');
                setBusiness('')
                setmarketingAgree('')

                setModals(false);

            }else{



                alert('신청이 실패되었습니다');
            }

        }else{


            // console.log('tel.length>>>>>',tel.length)




            if(corp == ''){
                setCorpValidation(true)
                return false;
            }else{
                setCorpValidation(false)
            }

            if(sector == ''){
                setSectorValidation(true)
                return false;
            }else{
                setSectorValidation(false)
            }

            if(business == ''){
                setBusinessValidation(true)
                return false;
            }else{
                setBusinessValidation(false)
            }

            if(email == ''){
                setEmailValidation(true)
                return false;
            }else{
                setEmailValidation(false)
            }


            if(emailtype == false){
                setEmailTypeValidation(true)
                return false;
            }else{
                setEmailTypeValidation(false)
            }


            if(phonetype == false  || 0 < tel.length < 12){
                setTelPhoneValidation(true)
                return false;
            }else{
                setTelPhoneValidation(false)
            }


            if(tel == '' || tel == undefined){
                setTelValidation(true)
                return false;
            }else{
                setTelValidation(false)
            }

            if(Agree == false){
                setInfoValidation(true)
                return false;
            }else{
                setInfoValidation(false)
            }

            if(!marketingAgree) {
                setMarketingValidation(true)
                return false;
            }
            return false;
        }
    };






    return (
        <>
            <div>
                {Modals &&
                    <div style={position}>
                        <div
                            className={BannerCss.bannerModalBG}>
                        </div>
                        <div className={BannerCss.bannerModalDIV}>

                            {bannerModal1 &&  <img src={require("assets/img/banner/modalHeader1.png")} alt="header이미지"/>}

                            <form action="" ref={inputRef}>
                                <section>
                                    <p>기업명
                                        {corpValidation && <span className={BannerCss.validationStyle}>(기업명을 입력해주세요)</span>}
                                    </p>
                                    <input
                                        type="text"
                                        placeholder='예시_주식회사 일이삼'
                                        onChange={(e) => {
                                            setCorp(e.target.value)
                                        }}
                                        value={corp}
                                    />
                                </section>
                                <section>
                                    <p>업종 혹은 업태
                                        {sectorValidation && <span className={BannerCss.validationStyle}>(업종 또는 업태 정보를 입력해주세요)</span>}
                                    </p>
                                    <input
                                        type="text"
                                        placeholder='예시_플라스틱 용기 제조업'
                                        onChange={(e) => {
                                            setSector(e.target.value)
                                        }}
                                        value={sector}
                                    />
                                </section>



                                <section>
                                    <p>업력
                                        {/*{sectorValidation && <span className={BannerCss.validationStyle}>(업력을 입력해주세요)</span>}*/}
                                    </p>
                                    <select
                                        name="karma"
                                        id="karma"
                                        onChange={(e) => {
                                            setKarma(e.target.value)
                                        }}
                                    >
                                        <option value="1년차">1년차</option>
                                        <option value="2년차">2년차</option>
                                        <option value="3년차">3년차</option>
                                        <option value="4년차">4년차</option>
                                        <option value="5년차">5년차</option>
                                        <option value="6년차">6년차</option>
                                        <option value="7년차">7년차</option>
                                        <option value="8년차이상">8년차 이상</option>
                                    </select>
                                </section>
                                <section>
                                    <p>사업장 소재지
                                        {businessValidation && <span className={BannerCss.validationStyle}>(사업장 소재지를 입력해주세요)</span>}
                                    </p>
                                    <input
                                        type="text"
                                        placeholder='예시_경기도 화성시'
                                        onChange={(e) => {
                                            setBusiness(e.target.value)
                                        }}
                                        value={business}
                                    />
                                </section>



                                <section>
                                    <p>접속 링크 안내받을 이메일
                                        {emailValidation &&  <span className={BannerCss.validationStyle}>(이메일정보를 입력해주세요)</span>}
                                        {emailTypeValidation &&  <span className={BannerCss.validationStyle}>(이메일형식이 올바른지 확인해주세요)</span>}
                                    </p>
                                    <input
                                        type="text"
                                        placeholder='example@example.com'
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }}
                                        value={email}
                                        onBlur={checkEmail}
                                    />
                                </section>
                                <section>
                                    <p>휴대전화번호
                                        {telValidation &&  <span className={BannerCss.validationStyle}>(휴대전화번호를 입력해주세요)</span>}
                                        {telPhoneValidation &&  <span className={BannerCss.validationStyle}>(휴대전화번호 형식이 올바른지 확인해주세요)</span>}
                                    </p>
                                    <input
                                        onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength)
                                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                                        }}
                                        maxlength={11}

                                        type="number"
                                        placeholder='01012341234'
                                        onChange={(e) => {
                                            setTel(e.target.value)
                                        }}
                                        value={tel}
                                        onBlur={checkPhonenumber}
                                    />
                                </section>

                                <section>
                                    <p>개인정보 수집 동의
                                        {infoValidation && <span className={BannerCss.validationStyle}>(개인정보수집에 동의해주세요)</span>}
                                    </p>

                                    <div className={BannerCss.texts} name="" id="" cols="30" rows="10" readOnly>
                                        <h1>개인정보 처리 방침</h1>
                                        <p className={BannerCss.margin}>주식회사 씨티엔에스(이하 "회사")는 이용자의 개인 정보를 보호하기 위하여 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 및 「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.</p>


                                        <h3>1. 처리하는 개인정보의 항목</h3>
                                        <p>회사는 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다</p>
                                        <p className={BannerCss.margin}>개인정보 항목 : 서비스 이용자의 회사명, 휴대전화번호, 이메일주소</p>


                                        <h3>2. 개인정보의 처리 목적</h3>
                                        <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                                        <p className={BannerCss.margin}>가. 서비스 제공을 위한 정보 활용 : 서비스 이용에 따른 본인 확인, 콘텐츠 제공, 만족도 조사, 불만 민원 처리, 고지사항 전달의 목적으로 활용</p>


                                        <h3>3. 개인정보의 처리 및 보유 기간</h3>
                                        <p>회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간이 도래하면 해당 정보를 지체 없이 파기합니다. 보존 기간 및 보존 항목은 아래와 같습니다.</p>
                                        <p>- 보존 항목 : 서비스 이용자의 회사명, 휴대전화번호, 이메일주소</p>
                                        <p className={BannerCss.margin}>- 보존 기간 : 2024년 9월 01일까지</p>


                                        <h3> 4. 개인정보 파기에 관한 사항</h3>
                                        <p>1) 파기절차</p>
                                        <p> 서비스 이용을 위해 입력하신 정보는 목적이 달성된 후 기타 관련 법령에 의한 개인정보보관 사유에 해당하지 않는 한 지체없이 파기됩니다.</p>



                                        <p>2) 파기방법</p>
                                        <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>




                                        <h3>5. 개인정보의 열람청구를 접수, 처리하는 부서</h3>
                                        <p className={BannerCss.margin}>개인정보 관련한 민원처리는 이용자의 개인정보를 보호하고 개인정보와 관련한 의문사항을 처리하기 위하여 다음과 같이 민원처리 콜센터를 운영하고 있습니다.</p>


                                        <p>전자우편 : exito@myctns.com</p>
                                        <p>전화번호 : 02-561-1898</p>
                                        <p className={BannerCss.margin}>기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.</p>


                                        <p> 1) 개인정보침해신고센터</p>
                                        <p> 전화 : (국번없이) 118</p>
                                        <p className={BannerCss.margin}>URL : http://privacy.kisa.or.kr</p>


                                        <p>2) 개인정보분쟁조정위원회</p>
                                        <p>전화 : 1833-6972</p>
                                        <p className={BannerCss.margin}>URL : http://www.kopico.go.kr</p>


                                        <p>3) 대검찰청 사이버수사과</p>
                                        <p>전화 : (국번없이) 1301</p>
                                        <p className={BannerCss.margin}> URL : http://www.spo.go.kr</p>


                                        <p>4) 경찰청 사이버안전국</p>
                                        <p>전화 : (국번없이) 182</p>
                                        <p className={BannerCss.margin}>URL : http://cyberbureau.police.go.kr</p>




                                        <h3>6. 개인정보보호 책임자</h3>
                                        <p>회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다</p>
                                        <p>- 개인정보 보호 책임자 : 엑시토 정보보호 담당</p>
                                        <p>- 개인정보 운영 책임자 : 엑시토 대표이사</p>
                                        <p>- 전화번호 : 02-561-1898</p>
                                        <p className={BannerCss.margin}>- 이메일 주소 : exito@myctns.com</p>


                                        <h3>7. 개인정보 처리방침의 변경에 관한 사항</h3>
                                        <p>이 개인정보처리방침은 법령ㆍ정책 또는 보안 기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 아래와 같은 방법으로 사전 공지하도록 하겠습니다.</p>
                                        <p className={BannerCss.margin}>- 인터넷 홈페이지 첫 화면 공지 사항 란 또는 별도의 창을 통하여 수정내용 공지</p>


                                        <p>시행일자: 2023년 08월 31일</p>

                                    </div>

                                    <div className={BannerCss.checkBox}>
                                        <div>
                                            <input
                                                style={inputCss}
                                                type="radio"
                                                name="or"
                                                id='Agree'
                                                value={Agree}
                                                onClick={(e) => {
                                                    setAgree(true)
                                                }}
                                            />
                                            <label htmlFor="Agree">동의</label>
                                        </div>
                                        <div>
                                            <input
                                                style={inputCss}
                                                type="radio"
                                                name="or"
                                                id='Disagree'
                                                value={Agree}
                                                onClick={(e) => {
                                                    setAgree(false)
                                                }}
                                            />
                                            <label htmlFor="Disagree">동의하지 않음</label>
                                        </div>
                                    </div>
                                </section>







                                <section>
                                    <p>마케팅 정보 수신 동의
                                        {marketingValidation && <span className={BannerCss.validationStyle}>(마케팅정보수신을 선택해주세요)</span>}
                                    </p>

                                    <div className={BannerCss.texts} name="" id="" cols="30" rows="10" readOnly>
                                        <h1>마케팅 정보 수신 동의</h1>
                                        <p >서비스 제공 및 이용과 관련하여 ㈜씨티엔에스가 취득한 개인정보는 "개인정보보호법" 및 </p>
                                        <p className={BannerCss.margin}>"정보통신망 이용촉진 및 정보보호 등에 관한 법률" 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보 보호 규정을 준수합니다.</p>


                                        <p>1. 고객이 수집 및 이용에 동의한 개인정보를 활용하여, 전자적 전송 매체(핸드폰 문자메시지(SMS,MMS), E-mail 등)를 통하여 발송됩니다.</p>
                                        <p>2. 발송되는 마케팅 정보는 수신자에게 ㈜씨티엔에스 및 제 3자의 상품 또는 서비스에 대한 혜택 정보, 각종 행사 정보 등 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.</p>
                                        <p>3. 마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집이용을 거부하실 수 있으며, 거부 시에도 서비스를 이용하실 수 있으나, 동의를 거부한 경우 각종 소식 및 행사 참여에 제한이 있을 수 있습니다.</p>
                                        <p>4. 수신동의를 받은 날부터 1년 동안 동의 이력이 유지됩니다. 1년이 지난 시점인 24년 9월 01일에 개인정보 및 마케팅 정보 수신 동의 이력이 즉시 폐기됩니다.</p>
                                        <p className={BannerCss.margin}>5. ㈜씨티엔에스 고객센터를 통해 수신 동의를 철회할 수 있습니다.</p>

                                        <p className={BannerCss.margin}>※ 문의 및 동의철회 : exito@myctns.com / 02-561-1898</p>

                                        <p>1) 개인정보 수집 항목 - 개인정보 2등급 : 휴대전화번호, 이메일주소</p>
                                        <p>2) 개인정보 수집 이용 목적 - 마케팅 광고에 활용 (행사 관련 광고성 정보 전송) - 서비스 관련 정보 전송</p>
                                        <p className={BannerCss.margin}>3) 보유 및 이용기간 - 이용자가 동의를 철회 시까지 - 2024년 9월 1일 23:59 까지 - 단, 관련 법령의 규정에 따라 보존할 필요가 있을 경우, 해당 기간까지 보존함</p>

                                        <p>이 마케팅약관은 2023년 08월 31일부터 적용됩니다.</p>

                                    </div>

                                    <div className={BannerCss.checkBox}>
                                        <div>
                                            <input
                                                style={inputCss}
                                                type="radio"
                                                name="or2"
                                                id='marketingAgree'
                                                value={marketingAgree}
                                                onClick={(e) => {
                                                    setmarketingAgree('Y')
                                                }}
                                            />
                                            <label htmlFor="marketingAgree">동의</label>
                                        </div>
                                        <div>
                                            <input
                                                style={inputCss}
                                                type="radio"
                                                name="or2"
                                                id='marketingDisagree'
                                                value={marketingAgree}
                                                onClick={(e) => {
                                                    setmarketingAgree('N')
                                                }}
                                            />
                                            <label htmlFor="marketingDisagree">동의하지 않음</label>
                                        </div>
                                    </div>
                                </section>













                                <button
                                    onClick={(e) => {
                                        BannerModals(e);
                                    }}
                                >신청 완료하기</button>
                                {/*<a href="https://exitobiz.co.kr/community/communityView/422" target='_blank'>바우처 지원사업 설명 보기</a>*/}
                            </form>


                            {bannerModal1 &&  <img src={require("assets/img/banner/modalImg3.png")} alt="배너이미지1"/>}
                            {/*{bannerModal2 &&  <img src={require("assets/img/banner/img2.png")} alt="배너이미지1"/>}*/}
                            {/*{bannerModal3 &&  <img src={require("assets/img/banner/img2.png")} alt="배너이미지1"/>}*/}

                            {/*{bannerModal1 && <img src={require("assets/img/banner/footer.png")} alt="footer"/>}*/}
                            {/*{bannerModal2 && <img src={require("assets/img/banner/footer.png")} alt="footer"/>}*/}
                            {/*{bannerModal3 && <img src={require("assets/img/banner/footer.png")} alt="footer"/>}*/}

                            <div className={BannerCss.modalbtns}>
                                <button
                                    onClick={() => {
                                        setModals(false)
                                        // 벨리데이션 지우기
                                        setCorpValidation(false)
                                        setSectorValidation(false)
                                        setEmailValidation(false)
                                        setEmailTypeValidation(false)
                                        setInfoValidation(false)
                                        setTelValidation(false)
                                        setTelPhoneValidation(false)
                                        setMarketingValidation(false)
                                        setBusinessValidation(false)

                                        setEmailTypeValidation(false)

                                        //화면닫으면 reset
                                        setCorp('')
                                        setSector('')
                                        setEmail('')
                                        setTel('')
                                        setBusiness('')
                                    }}
                                >닫기
                                </button>
                                <button
                                    onClick={(e) => {
                                        BannerModals(e, 'move');
                                    }}
                                >교육신청</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Banner_Modal;
