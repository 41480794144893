import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import H_SearchBox from "components/Hyundai/H_SearchBox";
import { modalOverflow } from "redux/store";
import styles from "scss/components/H_SearchForm.module.scss";
import * as api from '../../lib/api';
import SupportList from "../../pages/support/SupportList";
import event from "../home/Event";

const E_SearchForm = (
    initUrl,
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const [searchVal, setSearchVal] = useState("");
    const [searchOpen, setSearchOpen] = useState(false);
    const [popularKeyword, setPopularKeyword] = useState([]);
    const [myKeyword, setMyKeyword] = useState([]);
    const [sessionKeyword, setSessionKeyword] = useState([]);
    const {corp_nm} = useParams();
    const [url, setUrl] = useState("");
    const userInfo = useSelector((state) => state.userInfo);
    const getPopluarKeyword = async () => {
        // let res = await api.getCommonCode(corp_nm.toLowerCase());
        let res = await api.getCommonCode(corp_nm);
        let params = {corp_cd : res.data.code};
        api.getCorpSearchHotKeyword(params).then((res) => {
            setPopularKeyword(res.data);
        })
    };


    useEffect(() => {
        setUrl(initUrl);
    },[initUrl]);

    const onChange = (e) => {
        const { target: { value } } = e;
        // console.log("value >>> ", value);
        setSearchVal(value);
    };


    // popularKeyword 항목 클릭 시 검색창에 검색어 입력
    const onClickKeyword = (word) => {
        setSearchVal(word);
    };

    const onClickRecentKeyword = (word) => {
        setSearchVal(word);
    }



    const searchSubmit = async (e) => {
        e.preventDefault();
        const searchValue = e.target.searchText.value;
        if (searchValue.replaceAll(" ", "") == "") {
            alert("검색어를 입력하세요.");
            return false;
        }

        let data = {
            "tl_page_type" : "지원사업",
            "tl_page_depth" : "2",
            "tl_page_name" : "지원사업-검색화면",
            "tl_button_name" : "검색버튼",
            "tl_type_cd" : 0,
            "tl_event" : searchVal,
            "tl_memo" : "-",
            "tl_user_id" : sessionStorage.getItem("corpId")
        }
        if([...sessionKeyword].filter(x => x == searchValue).length == 0)
            setSessionKeyword([searchValue, ...sessionKeyword]);


        // let res = await api.getCommonCode(corp_nm.toLowerCase());
        let res = await api.getCommonCode(corp_nm);


        // let searchValue1 = document.querySelector("#searchText").value;
        // api.insertCorpTimeLine(data, res.data.code).then(() => {
        //
        //     navigate(`/corp/${sessionStorage.getItem("corpNm")}/${sessionStorage.getItem("corpId")}?keyword=${searchValue}`);
        //     // navigate(`/support/supportList?keyword=${searchValue}`);
        //
        //     setSearchOpen(false);
        //     getMyKeyword();
        //     setSearchVal(searchValue1);
        //     document.querySelector("#searchText").blur();
        // })



        let searchValue1 = document.querySelector("#searchText").value;

        api.insertCorpTimeLine(data, res.data.code).then(() => {

            navigate(url.initUrl + searchValue)

            setSearchOpen(false);
            getMyKeyword();
            setSearchVal(searchValue1);
            document.querySelector("#searchText");
            // document.querySelector("#searchText").blur();

        })


    };





    const handleBlur = (e) => {
        const currentTarget = e.currentTarget;
        requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setSearchOpen(false);
            }
        });
    };









    const getMyKeyword = () => {
        api.getMyRecentKeyword(userInfo.id).then((res) => {
            const data = res.data;
            data.forEach((v, i) => {
                if (i >= 10) {
                    api.delMyRecentKeyword({ tl_event: v.tl_event }, userInfo.id).then(() => {
                        getMyKeyword();
                    })
                }
            });
            setMyKeyword(data);
        })
    };



    useEffect(() => {
        if (!isLoggedIn) {
            let sessionItem = sessionStorage.getItem("sessionKeyword");
            if (sessionItem == null) setSessionKeyword([]);
            else setSessionKeyword(JSON.parse(sessionItem));
        }
    }, [isLoggedIn]);

    useEffect(() => {
        getPopluarKeyword();
        getMyKeyword();
    }, [corp_nm]);

    useEffect(() => {
        sessionStorage.setItem( "sessionKeyword",  JSON.stringify([...sessionKeyword].filter((x) => x != "")));
    }, [sessionKeyword]);

    useEffect(() => {
        if(location.search.indexOf("keyword")<0)
            setSearchVal("");
        setSearchOpen(false);
    }, [location]);

    useEffect(() => {
        /* 반응형 주석
        if (isMobile) {
            dispatch(modalOverflow(true));
        } */
        return () => {
            dispatch(modalOverflow(false));
        };
    }, []);


    return (
        <form
            className={styles.SearchForm}
            onSubmit={searchSubmit}
            onFocus={() => {
                setSearchOpen(true);
            }}
            onBlur={handleBlur}
        >
            <div className={styles.searchFormTop}>
                {/*반응형 주석*/}
                {/*{isMobile && (*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className={styles.btnBack}*/}
                {/*        onClick={(e) => {*/}
                {/*            e.preventDefault();*/}
                {/*            setMobileSearchOpen(false);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <img*/}
                {/*            src={require("assets/img/global/btn/btn_back_mobile.png")}*/}
                {/*            alt="모바일 서치폼 닫기"*/}
                {/*        />*/}
                {/*    </button>*/}
                {/*)}*/}
                <div className={styles.iptWrap}>
                    <input
                        // style={{borderColor : colors ? '#30d6c2' : '#0B8EC5'}}
                        type="text"
                        name="searchText"
                        id="searchText"
                        placeholder="에너지,AI,제조 등 검색어로 지원사업을 검색해 보세요"
                        onChange={onChange}
                        value={searchVal}
                        autoComplete="off"
                        className={styles.boxColor2}
                    />
                    <button type="submit">
                        <img
                            src={require("assets/img/global/ico/searchIcon.png")}
                            alt="SEARCH"
                        />
                    </button>
                </div>
            </div>
            {searchOpen && (
                // {!isMobile && searchOpen && (
                <H_SearchBox
                    styles={styles}
                    popularKeyword={popularKeyword}
                    sessionKeyword={sessionKeyword}
                    setSessionKeyword={setSessionKeyword}
                    myKeyword={myKeyword}
                    getMyKeyword={getMyKeyword}
                    onClickKeyword={onClickKeyword}
                    onClickRecentKeyword={onClickRecentKeyword}
                />
            )}
            {/* 반응형 주석*/}
            {/*{isMobile && (*/}
            {/*    <H_SearchBox*/}
            {/*        styles={styles}*/}
            {/*        popularKeyword={popularKeyword}*/}
            {/*        myKeyword={myKeyword}*/}
            {/*        sessionKeyword={sessionKeyword}*/}
            {/*        setSessionKeyword={setSessionKeyword}*/}
            {/*    />*/}
            {/*)}*/}
        </form>
    );
};
export default E_SearchForm;
