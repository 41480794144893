// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Router_inner__MB-Qv {\n  margin: 30px auto; }\n", "",{"version":3,"sources":["webpack://./src/scss/components/Router.module.scss"],"names":[],"mappings":"AACA;EACE,iBAAiB,EAAA","sourcesContent":["@import \"scss/utils\";\n.inner {\n  margin: 30px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "Router_inner__MB-Qv"
};
export default ___CSS_LOADER_EXPORT___;
