import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import H_Support from "scss/pages/H_Support.module.scss";
import H_SupportItem from "components/Hyundai/H_supportItem";
import PaginationSupport from "components/PaginationSupport";
import EventModal from "components/home/EventModal";
import PaginationSupportCorp from "../PaginationSupportCorp";

const checkbox = {boxSizing:"border-box",display:"inline-block",position:"absolute",right:"0px",top:"15px"}
const checkboxInput = {boxSizing:"border-box",marginRight:"10px",width:"15px",height:"15px",position:"relative",top:"2px"}
const checkboxLabel = {boxSizing:"border-box"}
const position = {position: "relative"}

const H_SupportContent = ({
                              setIsCheckingBox,
                              getSupportCont,
                              setScrollStorage,
                              count,
                              setCount,
                              keyword,
                              page,
                              ord,
                              mobilePage,
                              setMobilePage,
                              total,
                              supportFilterCont,
                              setSupportFilterCont,
                              totalCount,
                              setTotalCount,
                              cate,
                              getCorpMySavedBook,
                              getDoughnutList,

                          }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const supportData = useSelector((state) => state.supportData);
    const [supportCont, setSupportCont] = useState([]);
    const [lastCheckTarget, setLastCheckTarget] = useState(null);
    const navigate = useNavigate();
    const [sltView, setSltView] = useState(false);



    const countClick = (e) => {
        const { currentTarget: { name, value } } = e;
        setSltView(false);
        setCount(e.target.value);
        navigateSearchTxt(name, value);
    };

    const ordClick = (e) => {
        const { currentTarget: { name, value } } = e;
        setScrollStorage(window.scrollY);
        navigateSearchTxt(name, value);
    };


    useEffect(() => {
        setSupportCont([...supportData]);
    }, [supportData]);


    // console.log('supportData>>>',supportData)




    useEffect(() => {
            setSupportFilterCont( [...supportCont])
    }, [supportData, supportCont, keyword]);


    useEffect(() => {
        setSupportFilterCont( [...supportCont])
    }, [supportData, supportCont, keyword]);

    function decode(txt) {
        return decodeURI(txt);
    };



    function navigateSearchTxt(name, value) {
        const searchTxt = location.search;
        const searchArr = searchTxt.replace("?", "").split("&");
        let searchObj = {};
        searchArr.forEach((v) => {
            const arrObj = v.split("=");
            searchObj[arrObj[0]] = decode(arrObj[1]);
        });
        let newSearchTxt = "";
        for (let key in searchObj) {
            if (searchObj[key] == "undefined" || key == name) {
                continue;
            } else if (key == "page") {
                newSearchTxt += `page=1&`;
            } else {
                newSearchTxt += `${key}=${searchObj[key]}&`;
            }
        }
        newSearchTxt += `${name}=${value}`;
        navigate("?" + newSearchTxt);
    }

    const [modalOn, setModalOn] = useState(false);
    const [modalTab, setModalTab] = useState(0);
    const [url, setUrl] = useState();
    const modalOpener = (e) => {
        const { currentTarget: { name, value } } = e;
        const isTrue = value == "true";
        setModalOn(isTrue);
    };

    function handleMobilePage() {
        setMobilePage(parseInt(sessionStorage.getItem("s_mo_page")));
    }
    let infiniteState = true;
    const listener = () => {
        if (lastCheckTarget) {
            if (infiniteState) {
                const yHeight = window.innerHeight;
                const targetPos = lastCheckTarget.getBoundingClientRect().top;
                const trigger = targetPos - yHeight + 100;
                if (trigger < 0) {
                    infiniteState = false;
                    let mobilePage = sessionStorage.getItem("s_mo_page");
                    if (mobilePage == null) {
                        sessionStorage.setItem("s_mo_page", 2);
                    } else {
                        sessionStorage.setItem("s_mo_page", parseInt(mobilePage) + 1);
                    }
                    handleMobilePage();
                    setTimeout(() => {
                        infiniteState = true;
                    }, 500);
                }
            }
        }
    };
    useEffect(() => {
    }, [mobilePage, supportFilterCont, supportData, total]);
    useEffect(() => {
        if (lastCheckTarget) {
            window.addEventListener("scroll", listener);
        }
        return () => {
            window.removeEventListener("scroll", listener);
        };
    }, [lastCheckTarget]);


    return (
        <div className={H_Support.SupportContent}>
            <div className={H_Support.contArea}>
                { cate == "government" ?
                    <p className={H_Support.total}>전체 {total}개</p>
                    : ""
                }
                <div className={H_Support.contTop}>
                    <div className={H_Support.ordWrap}>
                        <div className={H_Support.ordBtns2} style={position}>
                            <button
                                type="button"
                                name="ord"
                                value="최신순"
                                onClick={ordClick}
                                data-selected={ord == "최신순" && "selected"}
                            >
                                <span>최신순</span>
                            </button>
                            <button
                                type="button"
                                name="ord"
                                value="인기순"
                                onClick={ordClick}
                                data-selected={ord == "인기순" && "selected"}
                            >
                                <span>인기순</span>
                            </button>
                            <button
                                type="button"
                                name="ord"
                                value="금액높은순"
                                onClick={ordClick}
                                data-selected={ord == "금액높은순" && "selected"}
                            >
                                <span>금액높은순</span>
                            </button>
                            <button
                                type="button"
                                name="ord"
                                value="마감임박순"
                                onClick={ordClick}
                                data-selected={ord == "마감임박순" && "selected"}
                            >
                                <span>마감임박순</span>
                            </button>
                        </div>
                        {/*<div style={checkbox}>*/}
                        {/*    <input type="checkbox" style={checkboxInput}*/}
                        {/*           id="Deadlinecheck"*/}
                        {/*           onClick={(e) => {*/}
                        {/*               setIsCheckingBox(e.currentTarget.checked);*/}
                        {/*           }}*/}
                        {/*    />*/}
                        {/*    <label htmlFor="Deadlinecheck" style={checkboxLabel}>마감된 정보 포함</label>*/}
                        {/*</div>*/}
                    </div>
                    <div className={H_Support.countWrap}>
                        { (
                            // {!isMobile && (
                            <p
                                onClick={() => {
                                    setSltView((prev) => !prev);
                                }}
                                className={H_Support.count}
                            >
                                <span>{count}개씩 보기</span>
                                <img
                                    src={require("assets/img/global/btn/btn_arr_bottom.png")}
                                    alt="열기"
                                />
                            </p>
                        )}
                        {sltView && (
                            <ul className={H_Support.selectArea}>
                                <li>
                                    <button
                                        type="button"
                                        value={10}
                                        name="view"
                                        onClick={countClick}
                                    >
                                        10개씩 보기
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        value={30}
                                        name="view"
                                        onClick={countClick}
                                    >
                                        30개씩 보기
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <div className={H_Support.itemWrap}>

                    { keyword == "" ? (
                        supportFilterCont.length == 0 ? (
                            // 필터 갯수 <div>키워드X / 갯수 == 0 / PC</div>
                            <>
                                {cate == "government" ?
                                    <div className="empty">
                                        <p className="empty_tit">일치하는 지원사업이 없습니다.</p>
                                    </div>
                                    :
                                    <div className="empty">
                                        <p className="empty_tit">관심사업이 없습니다.</p>
                                    </div>
                                }

                                {modalOn && (
                                    <EventModal
                                        modalOpener={modalOpener}
                                        modalTab={parseInt(modalTab)}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <ul>
                                    {supportFilterCont
                                        .slice((page - 1) * count, page * count)
                                        .map((item, idx) => {
                                            return (
                                                <H_SupportItem
                                                    key={idx}
                                                    item={item}
                                                    setScrollStorage={setScrollStorage}
                                                    getSupportCont={getSupportCont}
                                                    getDoughnutList={getDoughnutList}
                                                    keyword={keyword}
                                                    ord={ord}
                                                    cate={cate}
                                                />
                                            );
                                        })}
                                </ul>
                                <PaginationSupportCorp
                                    total={supportFilterCont.length}
                                    postLimit={count}
                                    numLimit={5}
                                    page={parseInt(page)}
                                    searchParams={searchParams}
                                    ord={ord}
                                />
                            </>
                        )
                    ) :
                        supportData.length == 0 ? (
                        // 검색 갯수 0 <div>키워드O / 갯수 == 0 / PC</div>
                        <>
                            {cate == "government" ?
                                <div className="empty">
                                    <p className="empty_tit">일치하는 지원사업이 없습니다.</p>
                                </div>
                                :
                                <div className="empty">
                                    <p className="empty_tit">관심사업이 없습니다.</p>
                                </div>
                            }
                        </>
                    ) :  (
                        // 검색 PC <div>키워드O / 갯수 > 0 / PC</div>
                        <>
                            <ul>
                                {supportData
                                    .slice((page - 1) * count, page * count)
                                    .map((item,idx) => {
                                        return (
                                            <H_SupportItem
                                                url={url}
                                                key={idx}
                                                item={item}
                                                setScrollStorage={setScrollStorage}
                                                getSupportCont={getSupportCont}
                                                keyword={keyword}
                                                ord={ord}
                                                cate={cate}
                                                setIsCheckingBox={setIsCheckingBox}
                                                totalCount={totalCount}
                                                setTotalCount={setTotalCount}
                                                getDoughnutList={getDoughnutList}
                                            />
                                        );
                                    })}
                            </ul>
                            <PaginationSupportCorp
                                total={supportData.length}
                                postLimit={count}
                                numLimit={5}
                                page={parseInt(page)}
                                searchParams={searchParams}
                                ord={ord}
                            />
                        </>
                    )
                    }
                </div>
            </div>
        </div>
    );
};
export default H_SupportContent;