import React from "react";
import styles from "scss/pages/Support.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLoginCheck } from "redux/store";
import { useEffect } from "react";
import { useState } from "react";
import * as api from '../../lib/api';

const SupportItem = ({
                       item,
                       getSupportCont,
                       getRecent,
                       ord,
                       keyword
                     }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const isWebApp = useSelector((state) => state.isWebApp);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  const supportItemReady = useSelector((state) => state.supportItemReady);
  const supportItem = useSelector((state) => state.supportItem);
  const cateCode = item.target_cat_name;
  const locName = item.locname;
  const targetName = item.target_name;
  const title = item.si_title;
  const cost = item.target_cost_value;
  const [cateName, setCateName] = useState("");
  let costComma;
  let totalCost;
  const margin = {marginRight:"10px"}


  if (cost > 0) {
    totalCost = item.total_cost;
    costComma = addComma(item.target_cost_value) + "원";
  }else{
    costComma = '공고확인'
  }


  if(totalCost == null || totalCost == 0 || totalCost == undefined){
    totalCost = '공고확인'
  }else{
    totalCost = addComma(item.total_cost) +'원'
  }




  useEffect(() => {
    window.addEventListener("jjimListener", (event) => {
      getSupportCont(ord, keyword, false);
    });
  },[]);

  useEffect(() => {
    if (supportItemReady && cateCode != null) {
      setCateName(supportItem.spt_cd.find((x) => x.code == cateCode).code_nm);
    }
  }, [supportItemReady]);

  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const readDateSource = item.tl_cret_dt;
  const readDay = week[new Date(item.tl_cret_dt).getDay()];
  const endDateSource = item.si_end_dt;
  const today = new Date();
  today.setHours(0,0,0,0);
  const isEnd = endDateSource - today.getTime() < 0;
  const [endDate, endDay] = stringToDate(item.si_end_dt, "MMDD");
  const viewCount = item.view_cnt;
  const isZzim = item.mb_save_yn == "Y";

  function addComma(numb) {
    return numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function stringToDate(stringDate, type) {
    const dateSource = new Date(stringDate);
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const month = ("00" + (dateSource.getMonth() + 1)).slice(-2);
    const date = ("00" + dateSource.getDate()).slice(-2);
    const day = week[dateSource.getDay()];
    return [`${month}.${date}`, day];
  }

  const zzimClick = (idx, mb_save_yn) => {
    let data = { mb_addidx: idx, mb_save_yn: mb_save_yn };
    api.updateZzimStatus(data, userInfo.id).then(() => {
      getSupportCont(ord, keyword, false);
    })
  };
  const openInNewTab = (url, idx) => {
    if(isWebApp){
      if (window && window.flutter_inappwebview)  window.flutter_inappwebview.callHandler("supportDtlF", idx);
    }else{
      window.open(url, "_blank", "noopener,noreferrer");
    }
    if (Object.keys(userInfo).length > 0) {
      let data = {
        "tl_page_type" : "지원사업",
        "tl_page_depth" : "3",
        "tl_page_name" : "지원사업-웹뷰",
        "tl_button_name" : "화면진입",
        "tl_type_cd" : idx,
        "tl_event" : "진입완료",
        "tl_memo" : "-"
      };
      api.insertTimeline(data, userInfo.id).then(() => {
        getRecent();
      });
    }

    api.updateSupportViewCnt(idx).then(() => {
      getSupportCont(ord, keyword);
    })
  };

  return (
      <>
        <li className={styles.SupportItem}>
          <div className={styles.leftArea}>
            <div className={styles.itemTop}>
              {isMobile && (
                  <p>{readDateSource && `${readDateSource} (${readDay}) 읽음`}</p>
              )}
              <ol>
                <li>{cateName}</li>
                <li>{locName}</li>
                <li>{targetName}</li>
              </ol>
              {!isMobile && (
                  <p>{readDateSource && `${readDateSource} (${readDay}) 읽음`}</p>
              )}
            </div>
            <div className={styles.itemInfo}>
              <h4>
                <button
                    type="button"
                    onClick={() => {
                      openInNewTab(item.mobile_url, item.si_idx);
                    }}
                >
                  {title}
                </button>
              </h4>



              <p>
                {/*{cost > 0 && (*/}
                    <>
                      <span className={styles.moneyTit}>총 사업비</span>
                      <span style={margin} className={styles.moneyAmount}>{totalCost}</span>

                      <span className={styles.moneyTit}>기업당 지원금</span>
                      <span className={styles.moneyAmount}>{costComma}</span>
                    </>
                {/*)}*/}
              </p>

              <p style={{color:'#959292'}}>등록일<span>{item.si_cret_dt}</span></p>



            </div>
          </div>
          <div className={styles.rightArea}>
            <ul>
              <li className={`${styles.dueDate} ` + (isEnd ? styles.end : "")}>
                {isEnd ? (
                    <span>마감</span>
                ) : (
                    <>
                      <img
                          priority="true"
                          src={require("assets/img/global/ico/ico_date.png")}
                          alt="마감일"
                      />
                      <span>
                    {endDate} ({endDay})
                  </span>
                    </>
                )}
              </li>
              <li>
                <img
                    priority="true"
                    src={require("assets/img/global/ico/ico_view_black.png")}
                    alt="조회수"
                />
                <span>{viewCount} 회</span>
              </li>
              <li
                  className={`${styles.btnZzim} ` + (isZzim ? styles.isZzim : null)}
              >
                <button
                    type="button"
                    onClick={() => {
                      if (!isLoggedIn) {
                        dispatch(setLoginCheck(true));
                        return false;
                      }
                      zzimClick(item.si_idx, item.mb_save_yn);
                    }}
                >
                  <img
                      priority="true"
                      src={
                        isZzim
                            ? require("assets/img/global/ico/ico_zzim.png")
                            : require("assets/img/global/ico/ico_zzim_black.png")
                      }
                      alt={`찜 : ${isZzim}`}
                  />
                  <span>찜</span>
                </button>
              </li>
            </ul>
          </div>
        </li>
      </>
  );
};
export default SupportItem;
