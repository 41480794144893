import React from "react";
import styles from "scss/components/BoxListItem.module.scss";
import {useDispatch, useSelector} from "react-redux";
import * as api from '../../lib/api';
import {setLoginCheck, setMarketingCheck} from "../../redux/store";

const BoxListItemHome = ({
                           item,
                           writerShow,
                           commentShow,
                           viewShow,
                           likeShow,
                           getHomeSupport,
                           category,
                         }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const isMobile = useSelector((state) => state.isMobile);
  const isWebApp = useSelector((state) => state.isWebApp);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const dispatch = useDispatch();
  const openInNewTab = (url, idx) => {
      if(isWebApp){
          if (window && window.flutter_inappwebview)  window.flutter_inappwebview.callHandler("supportDtlF", idx);
      }else{
          window.open(url, "_blank", "noopener,noreferrer");
      }

    if (Object.keys(userInfo).length > 0) {
      let data = {
          "tl_page_type" : "지원사업",
          "tl_page_depth" : "3",
          "tl_page_name" : "지원사업-웹뷰",
          "tl_button_name" : "화면진입",
          "tl_type_cd" : idx,
          "tl_event" : "진입완료",
          "tl_memo" : "-"
      };
      api.insertTimeline(data, userInfo.id);
    }

    api.updateSupportViewCnt(idx).then(() => {
        getHomeSupport(category.category, category.url, category.cat_name);
    })

  };
  return (
      <>
        <div className={styles.BoxListItem}>
          <button
              type="button"
              onClick={(e) => {
                  if (!isLoggedIn) {
                      e.preventDefault();
                      dispatch(setLoginCheck(true));
                      return false;
                  }
                  if(isChkMarketing && isLoggedIn){
                      e.preventDefault();
                      dispatch(setMarketingCheck(true));
                      return false;
                  }
                openInNewTab(item.mobile_url, item.si_idx);
              }}
          >
            <h5 className={styles.title}>{item.si_title}</h5>
            {writerShow && <p className={styles.writer}>{item.target_name}</p>}
            <div className={styles.countArea}>
              {commentShow && (
                  <p>
                    <img
                        src={require("assets/img/global/ico/ico_comment.png")}
                        alt="like count"
                    />
                    <span>{item.comment_cnt}</span>
                  </p>
              )}
              {likeShow && (
                  <p>
                    <img
                        src={require("assets/img/global/ico/ico_like.png")}
                        alt="View count"
                    />
                    <span>{item.lovely_cnt}</span>
                  </p>
              )}
              {viewShow && (
                  <p>
                    {isMobile ? (
                        <img
                            src={require("assets/img/global/ico/ico_view_green.png")}
                            alt="like count"
                        />
                    ) : (
                        <img
                            src={require("assets/img/global/ico/ico_view_gray.png")}
                            alt="like count"
                        />
                    )}
                    <span>{item.view_cnt}</span>
                  </p>
              )}
            </div>
          </button>
        </div>
      </>
  );
};
export default BoxListItemHome;
