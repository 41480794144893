import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {setLoginCheck, setMarketingCheck} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";
const HomeCommunityItem = ({ item, likeShow, styles }) => {

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.HomeCommunityItem}>
        <Link
            to={`/community/communityView/${item.id}`}
            onClick={(e) => {
              if (!isLoggedIn) {
                e.preventDefault();
                dispatch(setLoginCheck(true));
                return false;
              }
              if(isChkMarketing && isLoggedIn){
                e.preventDefault();
                dispatch(setMarketingCheck(true));
                return false;
              }
              navigate(e.currentTarget.getAttribute("to"));
            }}
        >
          <h5>{item.title}</h5>
          <h6>{item.content.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, "")}</h6>
          <div className={styles.countArea}>
            <p>
              <img
                src={require("assets/img/global/ico/ico_like_white.png")}
                alt="View count"
              />
              <span>{item.like_cnt}</span>
            </p>
            <p>
              <img
                src={require("assets/img/global/ico/ico_view.png")}
                alt="like count"
              />
              <span>{item.view_count}</span>
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};
export default HomeCommunityItem;
