import React, { useState, useEffect } from "react";
import styles from "scss/pages/Deliver.module.scss";
import MyDeliverItem from "./MyDeliverItem";
import { useSelector } from "react-redux";
import Pagination from "components/Pagination";
import * as api from '../../lib/api';

const MyDeliver = ({ page, postLimit }) => {
  const searchParams = new URLSearchParams(window.location);
  const [emilHsts, setEmailHst] = useState([]);
  const [controlBox, setControlBox] = useState({ id: "" });
  const userInfo = useSelector((state) => state.userInfo);

  const setScrollStorage = (value) => {
    sessionStorage.setItem("cOffset", value);
  };

  const getMyEmailDeliver = () => {
      api.getMyEmailDeliver(userInfo.id).then((res) => {
          setEmailHst(res.data);
      })
  }

  useEffect(() => {
      getMyEmailDeliver();
  }, [userInfo]);

  return (
      <>
        <div className={styles.listCont}>
          {emilHsts.length > 0 ? (
              <ul className="commonListItemWrap">
                <li className={`commonListItem ${styles.DeliverListItem}`}>
                    <div className={`leftArea ${styles.leftArea}`}>
                        <p>발송일</p>
                        <p>제목</p>
                    </div>
                </li>
                {emilHsts
                    .slice((page - 1) * postLimit, page * postLimit)
                    .map((email, i) => {
                      return (
                          <MyDeliverItem
                              key={i}
                              setScrollStorage={setScrollStorage}
                              email={email}
                              controlBox={controlBox}
                              setControlBox={setControlBox}
                          />
                      );
                    })}
              </ul>
          ) : (
              <div className="empty">
                <p className="empty_tit">발송 이력이 없습니다.</p>
                <p className="empty_para">이메일 정기배송을 신청해보세요!</p>
              </div>
          )}
        </div>
        <Pagination
            total={emilHsts.length}
            postLimit={postLimit}
            numLimit={5}
            page={parseInt(page)}
            searchParams={searchParams}
        />
      </>
  );
};
export default MyDeliver;
