import React, { useState, useEffect } from "react";
import NoticeListItem from "components/notice/NoticeListItem";
import styles from "scss/pages/Notice.module.scss";
import * as api from '../../lib/api'
import MobileTitle from "components/MobileTitle";

const NoticeList = () => {
  const [noticeMu, setNoticeMu] = useState([]);
  const [noticeNo, setNoticeNo] = useState([]);
  const getNoticeData = async () => {

    const res = await api.getNoticeList();
    setNoticeMu(res.data.filter(x => x.mustYn == 'Y' && x.activeYn == 'Y'));
    setNoticeNo(res.data.filter(x => x.mustYn != 'Y' && x.activeYn == 'Y'));

  };

  useEffect(() => {
    getNoticeData();
  }, []);
  return (
    <div className="NoticeList">
      <MobileTitle title={"공지사항"} />
      <div className={`commonTitleWrap`}>
        <div className={` inner`}>
          <div>
            <h3 className={`title`}>공지사항</h3>
            <p>공지사항을 확인해 주세요.</p>
          </div>
        </div>
      </div>
      <div className={`cont ${styles.NoticeListCont}`}>
        <div className="inner">
          <ul className={`commonListItemWrap ${styles.noticeListWrap}`}>
            {noticeMu.map((item, idx) => {
              return <NoticeListItem item={item} key={item.id} />;
            })}
            {noticeNo.map((item, idx) => {
              return <NoticeListItem item={item} key={item.id} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default NoticeList;
