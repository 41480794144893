import React from "react";
import styles from "scss/pages/H_Support.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {modalOverflow, setSupportData, setSupportInfoModal, setSupportItem} from "redux/store";
import { useEffect, useState } from "react";
import { loadingStart, loadingEnd } from "redux/store";
import Tooltip from "components/Tooltip";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import * as api from '../../lib/api';

const H_Support = ({
                       allSupport,
                       setAllSupport,
                       isCheckingBox,
                        getSupportCont,
                       setIsCheckingBox,


                       setSearchValSet,
                       Energy,
                       setEnergy,
                       Environment,
                       setEnvironment,
                       Manufacturing,
                       setManufacturing,
                       factory,
                       setfactory,
                       ESG,
                       setESG,
                       RD,
                       setRD,
                       ICT,
                       setICT,
                       Bio,
                       setBio,
                   }) => {
    const { user_id } = useParams();
    const { corp_nm } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const supportItem = useSelector((state) => state.supportItem);
    const userCompany = useSelector((state) => state.userCompany);
    const supportInfo = useSelector((state) => state.supportInfo);
    const supportItemReady = useSelector((state) => state.supportItemReady);
    const location = useLocation();
    const [objDummy, setObjDummy] = useState({ ...supportInfo });
    const [modalIdx, setModalIDx] = useState(0);
    const [modalOn, setModalOn] = useState(false);
    const getFilterData = (code, custom) => {
        let param = custom ? `ctgCd=${code}&cust=${custom}` : `ctgCd=${code}`;
        api.getCodeDtlList(param).then((res) => {
            dispatch(setSupportItem({ cate: code, arr: res.data }));
        })
    };

        useEffect(()=>{
            getFilterData("bizp_type_cd"); // 사업자형태
            getFilterData("prd_cd"); // 창업기간
            getFilterData("biz_type_cd"); // 기업형태
            getFilterData("spt_cd"); // 지원분야
            // getFilterData("biz_cd"); // 사업분야
            // getFilterData("tech_cd"); // 기술분야
            getFilterData("loc_cd"); //
        },[]);


    let resetData = {
        bizp_type_cd: {
            name: "사업자형태",
            multiply: false,
            require: true,
            datas: [
                {
                    code_nm: "법인사업자",
                    code: "03",
                    ctg_cd: "bizp_type_cd",
                    ctg_nm: "사업자형태",
                },
            ],
        },
        biz_type_cd: {
            name: "기업형태",
            multiply: true,
            require: true,
            datas: [
                {
                    code_nm: "전체",
                    code: "01",
                    ctg_cd: "biz_type_cd",
                    ctg_nm: "기업형태",
                },
            ],
        },
        prd_cd: {
            name: "창업기간",
            multiply: false,
            require: true,
            datas: [
                {
                    code_nm: "1년 미만",
                    code: "10",
                    ctg_cd: "prd_cd",
                    ctg_nm: "창업기간",
                },
            ],
        },
        spt_cd: {
            name: "지원분야",
            multiply: true,
            require: true,
            datas: [
                {
                    code_nm: "전체",
                    code: "01",
                    ctg_cd: "spt_cd",
                    ctg_nm: "지원분야",
                },
            ],
        },
        // biz_cd: {
        //     name: "사업분야",
        //     multiply: true,
        //     require: true,
        //     datas: [
        //         {
        //             code_nm: "전체",
        //             code: "01",
        //             ctg_cd: "biz_cd",
        //             ctg_nm: "사업분야",
        //         },
        //     ],
        // },
        // tech_cd: {
        //     name: "기술분야",
        //     multiply: true,
        //     require: true,
        //     datas: [
        //         {
        //             code_nm: "전체",
        //             code: "01",
        //             ctg_cd: "tech_cd",
        //             ctg_nm: "기술분야",
        //         },
        //     ],
        // },
        loc_cd: {
            name: "지역",
            multiply: true,
            require: true,
            datas: [
                {
                    code_nm: "전체",
                    code: "C99",
                    ctg_cd: "loc_cd",
                    ctg_nm: "지역",
                },
            ],
        },
    };

    function setApiData(arr, name, code){
            switch(name){
                case "biz_type_cd" :arr.company_type = code; break;
                case "spt_cd" : arr.target_cat_name = code; break;
                case "loc_cd" : arr.loc_code = code; break;
                // case "tech_cd" : arr.tech_ctg = code; break;
            }
    }

    useEffect(async ()=>{
        if(supportItemReady){
            const searchParams = new URLSearchParams(location.search);
            let res = await api.getCommonCode(corp_nm.toLowerCase());
            let corp_cd = res.data.code;
            if(user_id != null){
                const params = {
                    corp_cd : corp_cd
                }
                await api.getCorpCompanyInfo(params,user_id).then((res) => {
                    var filter= {};


                    if(res.data !== null){

                        delete res.data.tech_ctg;
                        delete res.data.business_ctg;
                        // if(res.data == null) return false;
                        // if (Object.keys(res.data).length > 0) {

                    }

                    const itemArr = {
                        company_type: "biz_type_cd",
                        start_period: "prd_cd",
                        support_type: "spt_cd",
                        // business_ctg: "biz_cd",
                        // tech_ctg: "tech_cd",
                        loc_ctg: "loc_cd",
                    };

                    let initData = {
                        // business_ctg  : "01",
                        company_type   :"01",
                        loc_ctg  :  "C99",
                        start_period  :  "10",
                        support_type  : "01"
                        // tech_ctg :  "01"
                    };


                    let obj = res.data == null ? initData : res.data ;


                    // const obj = res.data;
                    for (let key in itemArr) {
                        const name = itemArr[key];
                        const origin = supportItem[name];
                        const arr = obj[key].split(",");
                        const newArr = arr.map((v) => {
                            return origin.find((x) => x.code == v);
                        });
                        dispatch(
                            setSupportInfoModal({
                                name: name,
                                datas: newArr,
                            })
                        );
                        setApiData(filter,name,obj[key]);
                    }
                    filter.ord=  searchParams.get("ord") == undefined ? '전체' : searchParams.get("ord");
                    filter.end_dt = (!isCheckingBox).toString();
                    filter.keyword = searchParams.get("keyword") == undefined ? '' : searchParams.get("keyword");

                    let headers = {
                        corp_cd : corp_cd,
                        user_id : user_id
                    }

                    api.getCorpSupportList(filter, headers).then((res) => {
                        dispatch(setSupportData(res.data));
                        dispatch(loadingEnd());
                    })
                    // }



                });

            }
        }
    },[supportItemReady]);



    // 조건초기화 버튼 누르면 실행
    const filterReset = () => {
        for (let key in resetData) dispatch(setSupportInfoModal({ name: key, datas: resetData[key].datas }));
        dispatch(loadingEnd());
    };


    const checkbox = {boxSizing:"border-box",padding:"12px 24px",border:"1px solid #ddd",borderRadius:"5px 5px 0 0",borderBottom:"none"}
    const checkboxInput = {boxSizing:"border-box",marginRight:"10px",width:"15px",height:"15px",position:"relative",top:"2px"}
    const checkboxLabel = {boxSizing:"border-box",fontSize:"14px"}

    // 조회 필터(조회기능)
    const SubmitBtn = async () => {
        setEnergy(false)
        setEnvironment(false)
        setManufacturing(false)
        setfactory(false)
        setESG(false)
        setRD(false)
        setICT(false)
        setBio(false)


        //코드 조회
        const searchExist = window.location.search != "";
        let res = await api.getCommonCode(corp_nm.toLowerCase());

        if(allSupport == undefined || allSupport == false){
            if(user_id != '' && user_id != null){
                let params ={
                    companyType: supportInfo.biz_type_cd.datas.map((v) => v.code).toString(), //기업형태 biz_type_cd
                    supportType: supportInfo.spt_cd.datas.map((v) => v.code).toString(), //지원분야 spt_cd
                    // techCtg: supportInfo.tech_cd.datas.map((v) => v.code).toString(), //기술분야 tech_cd
                    locCtg: supportInfo.loc_cd.datas.map((v) => v.code).toString(), // 지역 loc_cd
                    corp_cd : res.data.code
                }

                let corpNm = sessionStorage.getItem("corpNm");
                let userId = sessionStorage.getItem("corpId");

                api.updateCorpCompanyInfo(params, user_id).then(() => {
                    searchExist ? navigate(`/corp/${corpNm}/${userId}`) : getSupportCont("전체", "", "government");
                }).catch(e => console.log(e));
            }
            dispatch(loadingEnd());

        }else{
            dispatch(loadingStart());
            const obj = {
                company_type: "", //기업형태 biz_type_cd
                // tech_ctg: "", //기술분야 tech_cd
                loc_code: "", // 지역 loc_cd
                end_dt : (!isCheckingBox).toString(),
            };

            let headers = {
                corp_cd : res.data.code,
                user_id : user_id
            }

            api.getCorpSupportList(obj, headers).then((res) => {
                console.log("##");
                dispatch(setSupportData(res.data));
                dispatch(loadingEnd());
                window.scrollTo(0, 0);
            })

        }

    };
    const modalControl = (step) => {
        // 안열려있을때
        if (modalOn == false) {
            setModalOn(true);
            setModalIDx(step);
        } else {
            if (step == modalIdx) {
                setModalOn(false);
            } else {
                setModalIDx(step);
            }
        }
        // 열려있는데 다른거 눌렀을때
        // 열려있는거 누를때
    };

    const filterBtnClick = (item) => {
        const cate = item.ctg_cd;
        const name = item.code_nm;
        let copy = JSON.parse(JSON.stringify(objDummy));
        const require = copy[cate].require;
        const multiply = copy[cate].multiply;
        if (multiply) {
            if (name == "전체") {
                if (
                    require &&
                    copy[cate].datas.filter((x) => x.code_nm != "전체").length == 0
                ) {
                    alert("한가지 이상 선택해주세요.");
                }
                copy[cate].datas = [item];




                // console.log('이거다>>>>',copy[cate].datas)



            } else {
                copy[cate].datas = copy[cate].datas.filter((x) => x.code_nm != "전체");
                if (someItem(copy[cate].datas, item)) {
                    if (require && copy[cate].datas.length == 1) {
                        alert("한가지 이상 선택해주세요.");
                    } else {
                        copy[cate].datas = filterItem(copy[cate].datas, item);
                    }
                } else {
                    if (cate == "loc_cd") {
                        copy[cate].datas = addItemLoc(copy[cate].datas, item);
                    } else {
                        copy[cate].datas = addItem(copy[cate].datas, item);
                    }
                }
            }
        } else {
            if (someItem(copy[cate].datas, item)) {
                if (require) {
                    alert("한가지 이상 선택해주세요.");
                }
            } else {
                copy[cate].datas = [item];
            }
        }
        setObjDummy(copy);

        function addItemLoc(target, item) {
            const sortArr = [...target, item].sort((a, b) => {
                return a.code.replace("C", "") - b.code.replace("C", "");
            });
            if (sortArr.some((item) => item.code == "C82")) {
                if (sortArr.length == 1) {
                    return sortArr;
                } else {
                    const tObj = sortArr.find((item) => item.code == "C82");
                    const tIdx = sortArr.findIndex((item) => item.code == "C82");
                    sortArr.splice(tIdx, 1);
                    sortArr.unshift(tObj);
                }
            }
            return sortArr;
        }
    };
    const filterModalSubmit = () => {
        for (let key in objDummy) {
            dispatch(setSupportInfoModal({ name: key, datas: objDummy[key].datas }));
        }
        setModalOn(false);
    };

    const tooltipOpen = (e) => {
        e.stopPropagation();
        const target = e.currentTarget.querySelector(".toolTipBox");
        if (target.classList.contains("active")) {
            target.classList.remove("active");
        } else {
            const allTarget = document
                // .querySelector('[class^="Support_modalCont"]')
                .querySelectorAll(".toolTipBox");
            allTarget.forEach((v) => v.classList.remove("active"));
            target.classList.add("active");
        }
    };

    function someItem(target, item) {
        return target.some(
            (x) => Object.entries(x).toString() == Object.entries(item).toString()
        );
    }
    function filterItem(target, item) {
        return target.filter(
            (x) => Object.entries(x).toString() != Object.entries(item).toString()
        );
    }
    function addItem(target, item) {
        const sortArr = [...target, item].sort((a, b) => {
            return a.code - b.code;
        });
        return sortArr;
    }
    const [renderItems, setRenderItems] = useState([]);

    function navigateSearchTxt(name, value) {
        const searchTxt = location.search;
        const searchArr = searchTxt.replace("?", "").split("&");
        let searchObj = {};
        searchArr.forEach((v) => {
            const arrObj = v.split("=");
            searchObj[arrObj[0]] = decodeURI(arrObj[1]);
        });
        let newSearchTxt = "";
        for (let key in searchObj) {
            if (searchObj[key] == "undefined" || key == name) {
                continue;
            } else if (key == "page") {
                newSearchTxt += `page=1&`;
            } else {
                newSearchTxt += `${key}=${searchObj[key]}&`;
            }
        }
        newSearchTxt += `${name}=${value}`;
        navigate("?" + newSearchTxt);
    }
    useEffect(() => {
        setRenderItems([]);
        for (let key in supportInfo) {
            if(key != 'city_cd' && key != 'district_cd')
                setRenderItems((prev) => [...prev, key]);
        }
    }, [supportInfo, location]);
    useEffect(() => {
        setObjDummy({ ...supportInfo });
    }, [modalIdx, modalOn]);
    return (
        <>
            <div className={styles.SupportFilter}>
                <div style={checkbox}>
                    <input type="checkbox" style={checkboxInput}
                           id="Deadlinecheck"
                           onClick={(e) => {
                               setIsCheckingBox(e.currentTarget.checked);
                           }}
                    />
                    <label htmlFor="Deadlinecheck" style={checkboxLabel}>마감된 정보 포함</label>
                </div>
                {/*<h4>*/}
                {/*  <span>*/}
                {/*    조회 필터*/}
                {/*    <i className="btnToolTip" onClick={tooltipOpen}>*/}
                {/*      <img*/}
                {/*          src={require("assets/img/global/btn/btn_tooltip.png")}*/}
                {/*          alt="tooltip"*/}
                {/*      />*/}
                {/*      <Tooltip*/}
                {/*          cont={ "입력한 정보는 저장되며 해당 정보를 기반으로 맞춤 추천이 진행됩니다." }*/}
                {/*      />*/}
                {/*    </i>*/}
                {/*  </span>*/}
                {/*</h4>*/}
                {/*{ (*/}
                {/*    // {!isMobile && ( 반응형 주석처리*/}
                {/*    <div className={styles.chkArea}>*/}
                {/*        <input*/}
                {/*            type="checkbox"*/}
                {/*            id="chkAll"*/}
                {/*            checked={allSupport}*/}
                {/*            onChange={(e) => {*/}
                {/*                setAllSupport(e.currentTarget.checked);*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        <label htmlFor="chkAll">전체 지원사업 보기</label>*/}
                {/*    </div>*/}

                {/*)}*/}
                {
                    // !isMobile ?
                    (
                        <div className={styles.filterBox}>
                            <ul className={styles.filterList}>
                                {renderItems.map((cate, idx) => {
                                    if (cate == 'bizp_type_cd' || cate == 'prd_cd') return false;
                                        return (
                                            <li className={styles.filterItem} key={idx}>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        modalControl(idx);
                                                    }}
                                                >
                                                    <div className={styles.itemTit}>
                                                        <span>{supportInfo[cate].name}</span>
                                                        <div className={styles.arr}>
                                                            <img
                                                                src={require("assets/img/global/btn/btn_arr.png")}
                                                                alt="Select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <p
                                                        className={styles.currentSelected}>
                                                        {supportInfo[cate].datas.length > 1
                                                            ? `${supportInfo[cate].datas[0].code_nm} 외 ${
                                                                supportInfo[cate].datas.length - 1
                                                            }건`
                                                            : supportInfo[cate].datas.length == 1
                                                                ? supportInfo[cate].datas[0].code_nm
                                                                : "선택"}
                                                    </p>
                                                </button>
                                                {modalOn && modalIdx == idx && (
                                                    <div className={styles.filterItemModal}>
                                                        <div className={styles.modalCont}>
                                                            <div className={styles.itemTit}>
                                                              <span>
                                                                {supportInfo[cate].name}
                                                                  <i className="btnToolTip" onClick={tooltipOpen}>
                                                                  <img
                                                                      src={require("assets/img/global/btn/btn_tooltip.png")}
                                                                      alt="tooltip"
                                                                  />
                                                                  <div className="toolTipBox">
                                                                    <p className="txt">
                                                                      사업공고 출처기관에서 명시한 분야로 분류합니다. 해당되는 분야 키워드를 중복 선택하시고 조회/추천되는 지원사업에 따라 수정해 보세요!
                                                                    </p>
                                                                    <p className="exTxt">
                                                                      AI를 활용한 수산물 밀키트 판매 커머스
                                                                    </p>
                                                                    <div className="exBox">
                                                                      <img
                                                                          src={require("assets/img/global/ico/ico_ex.png")}
                                                                          alt="ico_example"
                                                                          className="ico_ex"
                                                                      />
                                                                      <span>딥테크</span>
                                                                      <span>커머스</span>
                                                                      <span>푸드/농업</span>
                                                                      <span>기타(수산물)</span>
                                                                      선택
                                                                    </div>
                                                                  </div>
                                                                </i>
                                                              </span>
                                                            </div>
                                                            <ul>
                                                                {supportItem[cate].map((item, idx) => {
                                                                    let hasToolTip = false;
                                                                    let toolTipCont = "";
                                                                    if (cate == "tech_cd") {
                                                                        if (item.code_nm == "기타") {
                                                                            hasToolTip = true;
                                                                            toolTipCont = "뷰티/화장품, 패션, 예술, 광고/마케팅, 화학, 유아/출산, 부동산/건설, 소셜미디어/커뮤니티, 화학, 인사/비지니스/법률 등을 포함합니다.";
                                                                        }
                                                                        if (item.code_nm == "딥테크") {
                                                                            hasToolTip = true;
                                                                            toolTipCont = "AI,자율주행, 블록체인, 나노소재, 5G/6G, 스마트팜, 빅데이터 스마트홈 등을 포함하는 분야입니다.";
                                                                        }
                                                                    }
                                                                    return (
                                                                        <li
                                                                            className={
                                                                                hasToolTip ? styles.hasToolTip : null
                                                                            }
                                                                            key={idx}
                                                                        >









                                                                            <button
                                                                                className={
                                                                                    someItem(objDummy[cate].datas, item)
                                                                                        ? styles.selected
                                                                                        : " "
                                                                                }
                                                                                onClick={(e) => {
                                                                                    filterBtnClick(item, e);
                                                                                }}
                                                                            >
                                                                                {item.code_nm}
                                                                            </button>










                                                                            {hasToolTip && (
                                                                                <i
                                                                                    className="btnToolTip"
                                                                                    onClick={tooltipOpen}
                                                                                >
                                                                                    <img
                                                                                        src={require("assets/img/global/btn/btn_tooltip.png")}
                                                                                        alt="tooltip"
                                                                                    />
                                                                                    <Tooltip cont={toolTipCont} />
                                                                                </i>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className={styles.modalConfirm}>
                                                            <button
                                                                className={styles.submitBtn}
                                                                onClick={filterModalSubmit}
                                                            >
                                                                선택완료
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    return (
                                        <li className={styles.filterItem} key={idx}>
                                            {modalOn && modalIdx == idx && (
                                                <div className={styles.filterItemModal}>
                                                    <div className={styles.modalCont}>
                                                        <div className={styles.itemTit}>
                                                            {supportInfo[cate].name}
                                                        </div>
                                                        <ul>
                                                            {supportItem[cate].map((item, idx) => {
                                                                if(cate == 'biz_type_cd'){
                                                                    if(idx == 0 || idx == 1){
                                                                        return (
                                                                            <li key={idx}>
                                                                                <button
                                                                                    className={
                                                                                        someItem(objDummy[cate].datas, item)
                                                                                            ? styles.selected
                                                                                            : null
                                                                                    }
                                                                                    onClick={(e) => {
                                                                                        filterBtnClick(item, e);
                                                                                    }}
                                                                                >
                                                                                    {item.code_nm}
                                                                                </button>
                                                                            </li>
                                                                        );
                                                                    }
                                                                }else{
                                                                    return (
                                                                        <li key={idx}>
                                                                            <button
                                                                                className={
                                                                                    someItem(objDummy[cate].datas, item)
                                                                                        ? styles.selected
                                                                                        : null
                                                                                }
                                                                                onClick={(e) => {
                                                                                    filterBtnClick(item, e);
                                                                                }}
                                                                            >
                                                                                {item.code_nm}
                                                                            </button>
                                                                        </li>
                                                                    );
                                                                }
                                                            })}
                                                        </ul>
                                                    </div>
                                                    <div className={styles.modalConfirm}>
                                                        <button
                                                            className={styles.submitBtn}
                                                            onClick={filterModalSubmit}
                                                        >
                                                            선택완료
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className={styles.submitArea}>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        setAllSupport(e.currentTarget.checked);
                                        SubmitBtn();
                                        setModalOn(false);
                                    }}
                                >
                                    <span>조회</span>
                                </button>
                                    <button style={{marginTop: "10px"}}
                                            type="button"
                                            onClick={(e) => {
                                                setAllSupport(e.currentTarget.checked);
                                                filterReset();
                                                setModalOn(false);
                                            }}
                                    >
                                        <span>조건초기화</span>
                                    </button>
                            </div>
                        </div>
                    )
                    // 반응형 주석
                    //     : (
                    //     mobileFilterOpen && (
                    //         <div className={styles.filterBox}>
                    //             <SupportFilterMobile />
                    //             <div className={styles.submitArea}>
                    //                 {isMobile && (
                    //                     <div className={styles.chkArea}>
                    //                         <input
                    //                             type="checkbox"
                    //                             id="chkAll"
                    //                             checked={allSupport}
                    //                             onChange={(e) => {
                    //                                 if (!isLoggedIn) {
                    //                                     dispatch(setLoginCheck(true));
                    //                                     return false;
                    //                                 }
                    //                                 setAllSupport(e.currentTarget.checked);
                    //                             }}
                    //                         />
                    //                         <label htmlFor="chkAll">전체 지원사업 보기</label>
                    //                     </div>
                    //                 )}
                    //                 <button
                    //                     type="button"
                    //                     onClick={() => {
                    //                         if (!isLoggedIn) {
                    //                             dispatch(setLoginCheck(true));
                    //                         } else {
                    //                             SubmitBtn();
                    //                         }
                    //                     }}
                    //                 >
                    //                     <span>조회</span>
                    //                 </button>
                    //             </div>
                    //         </div>
                    //     )
                    // )
                }
            </div>
        </>
    );
};
export default H_Support;
