import React from "react";
import styles from "scss/components/deliver.module.scss";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as api from '../../lib/api';

const Deliver = () => {

    const { id } = useParams();
    const isMobile = useSelector((state) => state.isMobile);
    const navigate = useNavigate();

    const cancelDeliver = () => {
        api.getEmailDeliver(id).then((res) => {
            if(res.data.cancel_fl){
                alert("이미 해지된 메일입니다.");
                navigate("/");
                return false;
            }else{
                api.insertEmailDeliver({cancel_fl: true}, id).then(() => {
                    alert("정기배송이 해지되었습니다.\n언제든 정기배송을 다시 신청 하실 수 있습니다.");
                    navigate("/");
                })
            }
        });
    }

    return(
        <>
            <div className={styles.deliver}>
                <div className={styles.deliverInner}>
                    <section className={styles.sec1}>
                        <h1>엑시토 정기배송 해지</h1>
                    </section>
                    <section className={styles.sec2}>
                        <p>
                            정기배송을 취소하시면 맞춤 정보를 더 이상 배송 받을 수 없습니다.<br/>
                            정기배송을 해지 하시겠습니까?
                        </p>
                    </section>
                    <section className={styles.sec3}>
                        <button className={styles.btn1}
                            onClick={cancelDeliver}>
                            해지할게요
                        </button>
                        <button className={styles.btn2}
                            onClick={() => {
                                alert("정기배송을 계속 이용해주셔서 감사드립니다.\n더 나은 서비스로 보답하겠습니다.")
                                navigate("/");
                            }}>
                            계속 이용할게요
                        </button>
                    </section>
                </div>
            </div>
            {isMobile && (
                <style>
                    {
                        "\
                              #wrap > div[class^='Header']{\
                                display:flex;\
                              }\
                            "
                    }
                </style>
            )}
        </>
    );
};


export default Deliver;