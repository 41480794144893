import React from "react";
import styles from "../../scss/pages/Home.module.scss";
import CountUp from "react-countup";


function H_notice(props){
    return(
            <p>
                {props.title}
                <span>
                    <CountUp
                        className={styles.numb}
                        duration={props.title2 ? 0.5 : 2}
                        separator=","
                        end={props.cnt}
                    ></CountUp>
                    개
                </span>
                &nbsp;
                {props.title2}
            </p>
    )
}

export default H_notice;




