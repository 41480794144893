import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBox from "components/SearchBox";
import {modalOverflow, setLoginCheck, setMarketingCheck} from "redux/store";
import * as api from '../lib/api';




const SearchForm = ({
                      mobileSearchOpen,
                      setMobileSearchOpen,
                      styles,
                      Homeboxscss
                    }) => {


  // console.log('mobileSearchOpen>>>>>>',mobileSearchOpen)


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userInfo = useSelector((state) => state.userInfo);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const isMobile = useSelector((state) => state.isMobile);
  const isChkMarketing = useSelector((state) => state.isChkMarketing);
  const [searchVal, setSearchVal] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const [popularKeyword, setPopularKeyword] = useState([]);
  const [myKeyword, setMyKeyword] = useState([]);
  const [sessionKeyword, setSessionKeyword] = useState([]);




  const getPopluarKeyword = () => {
    api.getSearchHotKeyword().then((res) => {
      setPopularKeyword(res.data);
    })
  };


  const onChange = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      dispatch(setLoginCheck(true));
      return false;
    }

    if(isChkMarketing && isLoggedIn){
      e.preventDefault();
      dispatch(setMarketingCheck(true));
      return false;
    }

    const { target: { value } } = e;
    setSearchVal(value);
  };


  const searchSubmit = (e) => {
    e.preventDefault();
    const searchValue = e.target.searchText.value;
    if (searchValue.replaceAll(" ", "") == "") {
      alert("검색어를 입력하세요.");
      return false;
    }
    if (isLoggedIn) {
      let data = {
        "tl_page_type" : "지원사업",
        "tl_page_depth" : "2",
        "tl_page_name" : "지원사업-검색화면",
        "tl_button_name" : "검색버튼",
        "tl_type_cd" : 0,
        "tl_event" : searchVal,
        "tl_memo" : "-"
      }


      var searchValueURL = encodeURIComponent(searchValue)


      api.insertTimeline(data, userInfo.id).then(() => {
        navigate(`/support/supportList?keyword=${searchValueURL}`);
        setSearchOpen(false);
        setSearchVal("");
        getMyKeyword();
        // if(isMobile) setMobileSearchOpen(false);
        if(document.querySelector("#searchText") != null)
          document.querySelector("#searchText").blur();
      })
    } else {
      let sessionItem = sessionStorage.getItem("sessionKeyword");
      // console.log("TES >> ", searchValue , " ", sessionItem);
      setSessionKeyword([searchValue, ...JSON.parse(sessionItem)]);
      navigate(`/support/supportList?keyword=${searchValue}`);
      setSearchOpen(false);
      setSearchVal("");
      if(document.querySelector("#searchText") != null)
        document.querySelector("#searchText").blur();
    }
    // console.log("session >> ", sessionKeyword);
    // if(isMobile) setMobileSearchOpen(false);

    window.scrollTo(0, 0);
  };

  const getMyKeyword = () => {
    api.getMyRecentKeyword(userInfo.id).then((res) => {
      const data = res.data;

      data.forEach((v, i) => {
        if (i >= 10) {
          api.delMyRecentKeyword({ tl_event: v.tl_event }, userInfo.id).then(() => {
            getMyKeyword();
          })
        }
      });
      setMyKeyword(data);
    })
  };

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        setSearchOpen(false);
      }
    });
  };



  useEffect(() => {
    if (!isLoggedIn) {
      let sessionItem = sessionStorage.getItem("sessionKeyword");

      // console.log("SS >> ", sessionItem);

      if (sessionItem == null) {
        setSessionKeyword([]);
      } else {
        setSessionKeyword(JSON.parse(sessionItem));
      }
    }
  }, [isLoggedIn]);



  useEffect(() => {
    // if (!isLoggedIn) {
    let sessionItem = sessionStorage.getItem("sessionKeyword");
    if(searchOpen){
      setSessionKeyword(JSON.parse(sessionItem));
    }


    // console.log("tt >> ", sessionItem);
    //
    // if (sessionItem == null) {
    //   setSessionKeyword([]);
    // } else {

    // }
    // }
  },[searchOpen]);

  useEffect(() => {
    getPopluarKeyword();
    getMyKeyword();
  }, [userInfo , searchOpen]);



  useEffect(() => {
    // console.log("TEST  >> ", sessionKeyword);
    sessionStorage.setItem( "sessionKeyword",  JSON.stringify([...sessionKeyword].filter((x) => x != "")));
  }, [sessionKeyword, searchOpen]);



  useEffect(() => {
    setSearchVal("");
    setSearchOpen(false);
  }, [location]);

  useEffect(() => {
    if(!Homeboxscss){
      if (isMobile) dispatch(modalOverflow(true));
      return () => { dispatch(modalOverflow(false)) };
    }

  }, []);

  return (

      <>
        {!Homeboxscss &&


            <form
                className={styles.SearchForm}
                onSubmit={searchSubmit}
                onFocus={() => {
                  setSearchOpen(true);
                }}
                onBlur={handleBlur}
            >
              <div className={styles.searchFormTop}>
                {isMobile && (
                    <button
                        type="button"
                        className={styles.btnBack}
                        onClick={(e) => {
                          e.preventDefault();
                          setMobileSearchOpen(false);
                        }}
                    >
                      <img
                          src={require("assets/img/global/btn/btn_back_mobile.png")}
                          alt="모바일 서치폼 닫기"
                      />
                    </button>
                )}

                <div className={styles.iptWrap}>
                  <input
                      type="text"
                      name="searchText"
                      id="searchText"
                      placeholder="에너지,AI,제조 등 검색어로 지원사업을 검색해 보세요"
                      onChange={onChange}
                      value={searchVal}
                      autoComplete="off"
                      className={styles.boxColor2}
                  />
                  <button type="submit"

                  >
                    <img
                        src={require("assets/img/global/ico/ico_search.png")}
                        alt="SEARCH"
                    />
                  </button>
                </div>
              </div>
              {!isMobile && searchOpen && isLoggedIn && (
                  <SearchBox
                      styles={styles}
                      popularKeyword={popularKeyword}
                      myKeyword={myKeyword}
                      getMyKeyword={getMyKeyword}
                      sessionKeyword={sessionKeyword}
                      setSessionKeyword={setSessionKeyword}
                      setMobileSearchOpen={setMobileSearchOpen}
                  />
              )}
              {isMobile && (
                  <SearchBox
                      styles={styles}
                      popularKeyword={popularKeyword}
                      myKeyword={myKeyword}
                      getMyKeyword={getMyKeyword}
                      sessionKeyword={sessionKeyword}
                      setSessionKeyword={setSessionKeyword}
                      setMobileSearchOpen={setMobileSearchOpen}
                  />
              )}
            </form>
        }
        {Homeboxscss &&
            <form
                className={styles.SearchForm2}
                onSubmit={searchSubmit}
                onFocus={() => {
                  setSearchOpen(true);
                }}
                onBlur={handleBlur}
            >
              <div className={styles.searchFormTop}>
                {/*{isMobile && (*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className={styles.btnBack}*/}
                {/*        onClick={(e) => {*/}
                {/*          e.preventDefault();*/}
                {/*          setMobileSearchOpen(false);*/}
                {/*        }}*/}
                {/*    >*/}
                {/*      <img*/}
                {/*          src={require("assets/img/global/btn/btn_back_mobile.png")}*/}
                {/*          alt="모바일 서치폼 닫기"*/}
                {/*      />*/}
                {/*    </button>*/}
                {/*)}*/}


                <div className={styles.HomeboxSearch}>
                  <input
                      type="text"
                      name="searchText"
                      id="searchText"
                      placeholder=" 키워드로 지원사업을 검색해 보세요"
                      onChange={onChange}
                      value={searchVal}
                      autoComplete="off"
                      className={styles.boxColor2}
                  />


                  <button type="submit"

                  >
                    <img
                        src={require("assets/img/global/ico/ico_search.png")}
                        alt="SEARCH"
                    />
                  </button>
                </div>

              </div>
              {!isMobile && searchOpen && isLoggedIn && (
                  <SearchBox
                      styles={styles}
                      popularKeyword={popularKeyword}
                      myKeyword={myKeyword}
                      getMyKeyword={getMyKeyword}
                      sessionKeyword={sessionKeyword}
                      setSessionKeyword={setSessionKeyword}
                      setMobileSearchOpen={setMobileSearchOpen}
                  />
              )}
              {/*{isMobile && (*/}
              {/*    <SearchBox*/}
              {/*        styles={styles}*/}
              {/*        popularKeyword={popularKeyword}*/}
              {/*        myKeyword={myKeyword}*/}
              {/*        getMyKeyword={getMyKeyword}*/}
              {/*        sessionKeyword={sessionKeyword}*/}
              {/*        setSessionKeyword={setSessionKeyword}*/}
              {/*        setMobileSearchOpen={setMobileSearchOpen}*/}
              {/*    />*/}
              {/*)}*/}
            </form>
        }
      </>
  );
};
export default SearchForm;
