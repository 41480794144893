import React, { useState, useEffect } from "react";
import styles from "scss/pages/Support.module.scss";
import SupportFilter from "components/support/SupportFilter";
import SupportContent from "components/support/SupportContent";
import SupportRecent from "components/support/SupportRecent";
import { useDispatch, useSelector } from "react-redux";
import {loadingStart, loadingEnd, setLoginCheck} from "redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { setSupportData } from "redux/store";
import MobileTitle from "components/MobileTitle";
import * as api from '../../lib/api';
import searchFormStyle from "scss/components/SearchForm.module.scss";
import searchFormStyle2 from "scss/components/H_SearchForm.module.scss";
import EsearchForm from "../../components/Hyundai/E_SearchForm";
import SearchForm from "../../components/SearchForm";




let axiosCount = 0;
const SupportList = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  let keywordParam = searchParams.get("keyword");
  let targetParam = searchParams.get("target_name");


  console.log('targetParam>>>',targetParam)



  const userInfo = useSelector((state) => state.userInfo);
  const supportInfo = useSelector((state) => state.supportInfo);
  const isMobile = useSelector((state) => state.isMobile);
  const supportItem = useSelector((state) => state.supportItem);
  const supportData = useSelector((state) => state.supportData);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const supportItemReady = useSelector((state) => state.supportItemReady);
  const [total, setTotal] = useState(0);
  const [ord, setOrd] = useState("전체");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(30);
  const [savedBook, setSavedBook] = useState([]);
  const allSupportCache = isTrue(
    sessionStorage.getItem("allSupportCache") ?? true
  );
  const [allSupport, setAllSupport] = useState(allSupportCache);
  const [keyword, setKeyword] = useState("");
  const [targetName, setTargetName] = useState("");
  const [mobilePage, setMobilePage] = useState(1);
  const [supportFilterCont, setSupportFilterCont] = useState([]);
  const [isCheckingBox, setIsCheckingBox] = useState(false);

  function isTrue(target) {
    return target == "true";
  }
  const setScrollStorage = (value) => {
    sessionStorage.setItem("sOffset", value);
  };



  const checkbox = {boxSizing:"border-box",display:"inline-block",position:"absolute",right:"0px",top:"15px",fontSize:"14px"};
  const checkboxInput = {boxSizing:"border-box",marginRight:"10px",width:"15px",height:"15px",position:"relative",top:"2px"};
  const checkboxLabel = {boxSizing:"border-box"};
  const position = {position: "relative"};
  const color = {border: '2px solid #30d6c2'}

  const getSupportCont = (ord, keyword, loading = true,isCheckingBox) => {

    const thisCount = axiosCount;
    axiosCount = axiosCount + 1;
    if (loading) {
      dispatch(loadingStart());
    }
    if (!(sessionStorage.getItem("isLoggedIn") ?? false)) {
      let data = {
        ord: ord,
        keyword: encodeURIComponent(keyword),
        end_dt : (!isCheckingBox).toString()
      }
      api.getSupportList(data).then((res) => {
        if (thisCount + 1 == axiosCount)  dispatch(setSupportData(res.data));
        dispatch(loadingEnd());
      })

    } else {
      if (keyword != "") {

        let data = {
          ord: ord,
          keyword: keyword,
          end_dt : (!isCheckingBox).toString()
        }
        api.getSupportList(data,  userInfo.id ? userInfo.id : "").then((res) => {
          if (thisCount + 1 == axiosCount)  dispatch(setSupportData(res.data));
          dispatch(loadingEnd());
        })

      } else {
        if (allSupport) {
          let data = {
            ord: ord,
            end_dt : (!isCheckingBox).toString()
          }

          api.getSupportList(data, userInfo.id).then((res) => {
            if (thisCount + 1 == axiosCount)  dispatch(setSupportData(res.data));
            dispatch(loadingEnd());
          })

        } else {

          const thisData = {
            ord: ord,
            end_dt : (!isCheckingBox).toString(),
            business_type: dataToString("bizp_type_cd"),
            target_cat_name: dataToString("spt_cd"),
            // business_ctg: dataToString("biz_cd"),
            // tech_ctg: dataToString("tech_cd"),
            loc_code: dataToString("loc_cd"),
            target_name :targetParam,
          };

          if (dataToString("bizp_type_cd") != "02") {
            Object.assign(thisData, {
              start_period: dataToString("prd_cd"),
              company_type: dataToString("biz_type_cd"),
            });
          }
          const queryString = Object.entries(thisData)
            .map(([key, value]) => `${key}=${value}`)
            .join("&\n");

          api.getSupportList(thisData, userInfo.id).then((res) => {
            if (thisCount + 1 == axiosCount)  dispatch(setSupportData(res.data));
            dispatch(loadingEnd());
          })

        }
      }
    }
    setMobilePage(1);
    sessionStorage.removeItem("s_mo_page");
    function dataToString(target) {
      return supportInfo[target].datas.map((v) => v.code).toString();
    }
  };





  const getRecent = () => {
    api.getZZimRecently( {ord: "전체"}, userInfo.id).then(res => {
      setSavedBook(res.data.slice(0, 3));
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getRecent();
  }, [userInfo]);

  const [firstMount, setFirstMount] = useState(true);

  useEffect(() => {
    const searchTxt = decodeURI(location.search);
    sessionStorage.setItem("s_currentSearch", searchTxt);
    let searchObj = {};
    const searchArr = searchTxt.replace("?", "").split("&");
    let ordDummy = "";
    let pageDummy = "";
    let countDummy = "";
    let keywordDummy = "";
    let viewDummy = "";
    searchArr.forEach((v) => {
      const arrObj = v.split("=");
      searchObj[arrObj[0]] = arrObj[1];
    });
    if (searchObj.ord == undefined) {
      ordDummy = "전체";
    } else {
      ordDummy = searchObj.ord;
    }
    if (searchObj.page == undefined) {
      pageDummy = 1;
    } else {
      pageDummy = parseInt(searchObj.page);
    }
    if (searchObj.view != undefined) {
      viewDummy = searchObj.view;
      setCount(viewDummy);
    }
    if (searchObj.keyword == undefined) {
      keywordDummy = "";
    } else {
      keywordDummy = searchObj.keyword;
    }


    if (searchObj.count == undefined) {
      countDummy = 30;
    } else {
      countDummy = searchObj.count;
    }
    if (
      ordDummy != ord ||
      keywordDummy != keyword ||
      firstMount ||
      searchTxt == "" ||
        isCheckingBox != null
    ) {
      getSupportCont(ordDummy, keywordDummy, true, isCheckingBox);
    }

    setOrd(ordDummy);
    setPage(pageDummy);
    setKeyword(keywordDummy);

  }, [location,isCheckingBox]);

  useEffect(() => {
    if (isLoggedIn) {
      setAllSupport(false);
    } else {
      setAllSupport(true);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    sessionStorage.setItem("allSupportCache", allSupport);
  }, [allSupport]);
  useEffect(() => {
    setFirstMount(false);
    return () => {
      sessionStorage.removeItem("allSupportCache", allSupport);
    };
  }, []);
  useEffect(() => {
    if (keyword == "") {
      setTotal(supportFilterCont.length);
    } else {
      setTotal(supportData.length);
    }
  }, [keyword, supportData, supportFilterCont]);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(!isLoggedIn);
  useEffect(() => {
    isLoggedIn ? setMobileFilterOpen(!true) : setMobileFilterOpen(!false);
  }, [isLoggedIn]);

  const [supportCont, setSupportCont] = useState([]);


  useEffect(() => {
    setSupportCont([...supportData]);
  }, [supportData]);


  console.log('targetParam>>>>>>',targetParam)
  // console.log('keywordParam>>>>',keywordParam)

  return (
    <>
      <div className={styles.SupportList}>
        <MobileTitle title={"지원사업"} />
        <div className={`inner`}>
          {!isMobile && (
            <div className={styles.tit}>


              {targetParam == null ?
                  (<h4>신청 가능한 지원사업 찾기</h4>)
                  :
                  (<h4> 조회필터를 적용한<mark style={{margin:'0px 10px',color:'#4949db'}}>'{targetParam}'</mark>공고입니다.</h4>)
              }



              {/*{keyword ? (*/}
              {/*  <p>*/}
              {/*    <mark>'{keywordParam}'</mark> 검색 결과 입니다.*/}
              {/*  </p>*/}

              {/*) : (*/}
              {/*  <p>*/}
              {/*    좌측 <mark>필터 설정</mark> 후 내 기업에 맞는 지원사업만*/}
              {/*    확인하세요.*/}
              {/*  </p>*/}
              {/*)}*/}




              {keyword ? (
                  <p>
                    <mark>'{keywordParam}'</mark> 검색 결과 입니다.
                  </p>
              ) : (null)}


              {/*{targetParam ? (*/}
              {/*    <p>*/}
              {/*      조회필터를 적용한<mark>'{targetParam}'</mark>공고입니다.*/}
              {/*    </p>*/}
              {/*) : (null)}*/}


              {!keyword && !targetParam ? (
                  <p>
                    좌측 <mark>필터 설정</mark> 후 내 기업에 맞는 지원사업만 확인하세요.
                  </p>
              ) : (null)}





            </div>
          )}

          <div className={styles.contArea}>
            <div className={styles.filterArea}>
              {isMobile && (

                <div
                  className={styles.tit}
                  style={{ marginBottom: mobileFilterOpen ? "20px" : "0" }}
                >
                  <div className={styles.titInner}>
                    {targetParam == null ?
                        (<h4>신청 가능한 지원사업 찾기</h4>)
                        :
                        (<h4> 조회필터를 적용한<mark style={{margin:'0px 10px',color:'#4949db'}}>'{targetParam}'</mark>공고입니다.</h4>)
                    }



                    {/*{keyword ? (*/}
                    {/*  <p>*/}
                    {/*    <mark>'{keywordParam}'</mark> 검색 결과 입니다.*/}
                    {/*  </p>*/}
                    {/*) : (*/}
                    {/*  <p>*/}
                    {/*    <mark>필터 설정</mark> 후 내 기업에 맞는 지원사업만*/}
                    {/*    확인하세요.*/}

                    {/*    /!*<mark>'*/}
                    {/*    {targetParam}'</mark> 검색 결과 입니다.*!/*/}
                    {/*  </p>*/}
                    {/*)}*/}




                    {keyword ? (
                        <p>
                          <mark>'{keywordParam}'</mark> 검색 결과 입니다.
                        </p>
                    ) : (null)}


                    {/*{targetParam ? (*/}
                    {/*    <p>*/}
                    {/*      조회필터를 적용한<mark>'{targetParam}'</mark>공고입니다.*/}
                    {/*    </p>*/}

                    {/*) : (null)}*/}


                    {!keyword && !targetParam ? (
                        <p>
                          좌측 <mark>필터 설정</mark> 후 내 기업에 맞는 지원사업만 확인하세요.
                        </p>
                    ) : (null)}






                  </div>
                  {keyword == "" && targetParam == null ? (
                    <button
                      onClick={() => {
                        setMobileFilterOpen((prev) => !prev);
                      }}
                      className={
                        styles.mobileOpen +
                        " " +
                        (mobileFilterOpen ? styles.opened : "")
                      }
                    >
                      열기
                    </button>
                  ):null}




                </div>
              )}

              {targetParam ?
               null:
                  <SupportFilter
                      supportInfo={supportInfo}
                      getSupportCont={getSupportCont}
                      setScrollStorage={setScrollStorage}
                      allSupport={allSupport}
                      setAllSupport={setAllSupport}
                      mobileFilterOpen={mobileFilterOpen}
                      setMobileFilterOpen={setMobileFilterOpen}
                      keyword={keyword}
                  />
              }


            </div>



            <div className={styles.listArea}>
              {isMobile && <p className={styles.total}>전체 {total}개</p>}




              {targetParam || keywordParam ?
                null:
                  <div style={checkbox}>
                    <input type="checkbox" style={checkboxInput}
                        // id="checkboxAll"
                           checked={isCheckingBox}
                           onClick={(e) => {
                             // dispatch(setLoginCheck(true));
                             // FinishCheckBox(e.currentTarget.checked);
                             // Deadline();
                             setIsCheckingBox(e.currentTarget.checked);
                             // if(isCheckingBox){
                             //   setSupportFilterCont( [...supportCont].filter((x) => new Date(x.si_end_dt).getTime() >= new Date().getTime()) )
                             // }else if(setIsCheckingBox){
                             //   setSupportFilterCont( [...supportCont])
                             // }
                           }}/>
                    <label htmlFor="" style={checkboxLabel}>마감된 정보 포함</label>
                  </div>
              }




              {/*<div style={checkbox}>*/}
              {/*  <input*/}
              {/*      type="checkbox"*/}
              {/*      id="chkAll"*/}
              {/*      style={checkboxInput}*/}
              {/*      checked={allSupport}*/}
              {/*      onChange={(e) => {*/}
              {/*        if (!isLoggedIn) {*/}
              {/*          dispatch(setLoginCheck(true));*/}
              {/*          return false;*/}
              {/*        }*/}
              {/*        setAllSupport(e.currentTarget.checked);*/}
              {/*      }}*/}
              {/*  />*/}
              {/*  <label htmlFor="chkAll" style={checkboxLabel}>마감된 지원사업 보기</label>*/}
              {/*</div>*/}
              <SupportContent
                count={count}
                setCount={setCount}
                getSupportCont={getSupportCont}
                getRecent={getRecent}
                allSupport={allSupport}
                setScrollStorage={setScrollStorage}
                keyword={keyword}
                ord={ord}
                setKeyword={setKeyword}
                page={page}
                setPage={setPage}
                mobilePage={mobilePage}
                setMobilePage={setMobilePage}
                total={total}
                setTotal={setTotal}
                supportFilterCont={supportFilterCont}
                setSupportFilterCont={setSupportFilterCont}
                isCheckingBox={isCheckingBox}
              />

              {/*<EsearchForm*/}
              {/*    initUrl="/support/supportList?keyword="*/}
              {/*/>*/}






              {!isMobile ? (
                  <SearchForm styles={searchFormStyle2} />
              ) : (
                  mobileSearchOpen && (
                      <SearchForm
                          styles={searchFormStyle}
                          setMobileSearchOpen={setMobileSearchOpen}
                      />
                  )
              )}





            </div>
            <div className={styles.recentArea}>
              <SupportRecent
                userInfo={userInfo}
                savedBook={savedBook}
                setSavedBook={setSavedBook}
                getRecent={getRecent}
              />
            </div>
          </div>
        </div>

      </div>
      <style>
        {
          "\
          body{\
            background-color:#fff;\
          }\
        "
        }
      </style>
    </>
  );
};

export default SupportList;
