import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "scss/pages/CommonView.module.scss";
import * as api from '../../lib/api';
import {useSelector} from "react-redux";
import "../../scss/Email.scss"

const DeliverView = () => {
  const { id } = useParams();
  const [email, setEmail] = useState({});
  const [cont, setCont] = useState("");
  const userInfo = useSelector((state) => state.userInfo);


  const getDeliver = () => {
    api.getMyEmailDeliver(userInfo.id).then((res) => {
      const data = res.data;
      const target = data.find((item) => item.idx == id);
      setEmail(target);
      setCont(target.content_1 + target.content_2 + target.content_3);
    })
  };

  useEffect(() => {
    getDeliver();
  },[]);


  return (
      <>
        <div className={`${styles.DeliverView} ${styles.CommonView}`}>
          <div className={`inner ${styles.inner}`}>
            <div className={styles.contWrap}>
              <div className={styles.contTop}>
                <div className={styles.titleArea}>
                  <h3 className={styles.title}>{email.title}</h3>
                </div>
                <div className={styles.writer}>
                  <p className={styles.time}>발송일 {email.deliver_dt}</p>
                </div>
              </div>
              <div className="toastui-editor-contents">
                <div
                    className={styles.cont}
                    dangerouslySetInnerHTML={{ __html: cont }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};
export default DeliverView;
