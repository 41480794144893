import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import BannerCss from "scss/components/Banner.module.scss";
import Banner_Modal from "./Banner_Modal";
import {setLoginCheck} from "../../redux/store";
import {useDispatch, useSelector} from "react-redux";

const Banner = ({

                }) => {

    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const [Modals, setModals] = useState(true);
    const [bannerModal1, setBannerModal1] = useState(true);
    const [bannerModal2, setBannerModal2] = useState(false);
    const [bannerModal3, setBannerModal3] = useState(false);
    const [title, setTitle] = useState('B2B마케팅 2차 무료 특강');
    const dispatch = useDispatch();

    return (
        <>
        <div className={BannerCss.bannerDIV}>
            <div className={BannerCss.InnerbannerDIV}>

                <div
                    onClick={(e) => {
                        // if (!isLoggedIn) {
                        //     e.preventDefault();
                        //     dispatch(setLoginCheck(true));
                        //     return false;
                        // }
                        setModals(true)
                        setBannerModal1(true)
                        setBannerModal2(false)
                        setBannerModal3(false)
                        setTitle('잘 팔리는 판매 전략 마케팅 무료 교육')
                    }}
                    className={BannerCss.banner1}>
                    <img src={require("assets/img/banner/H_banner3.png")} alt="배너이미지1"/>
                </div>


                {/*<div*/}
                {/*    onClick={() => {*/}
                {/*        setModals(true)*/}
                {/*        setBannerModal1(false)*/}
                {/*        setBannerModal2(true)*/}
                {/*        setBannerModal3(false)*/}
                {/*    }}*/}
                {/*    className={BannerCss.banner2}>*/}
                {/*    <img src={require("assets/img/banner/H_banner1.png")} alt="배너이미지2"/>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    onClick={() => {*/}
                {/*        setModals(true)*/}
                {/*        setBannerModal1(false)*/}
                {/*        setBannerModal2(false)*/}
                {/*        setBannerModal3(true)*/}
                {/*    }}*/}
                {/*    className={BannerCss.banner2}>*/}
                {/*    <img src={require("assets/img/banner/H_banner1.png")} alt="배너이미지2"/>*/}
                {/*</div>*/}


            </div>


            <Banner_Modal
                Modals={Modals}
                setModals={setModals}
                bannerModal1={bannerModal1}
                setBannerModal1={setBannerModal1}
                bannerModal2={bannerModal2}
                setBannerModal2={setBannerModal2}
                bannerModal3={bannerModal3}
                setBannerModal3={setBannerModal3}
                title = {title}
            />

        </div>

        </>
    );
};

export default Banner;
