import React from "react";
import styles from "scss/pages/H_Support.module.scss";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import * as api from '../../lib/api';
import {useLocation} from "react-router-dom";

const block = {display:"block"}
const H_SupportItem = ({
                           item,
                           getSupportCont,
                           keyword,
                           ord,
                            cate,
                           getDoughnutList,
                           getTotalCount
                     }) => {
    const supportItem = useSelector((state) => state.supportItem);

    const cateCode = item.target_cat_name;
    const locName = item.locname;
    const targetName = item.target_name;
    const title = item.si_title;
    const cost = item.target_cost_value;
    const [cateName, setCateName] = useState("");
    const isZzim = item.mb_save_yn == "Y";
    const margin = {marginRight:"30px"}




    // console.log('item이다>>>>>',item)




    let costComma;
    if (cost > 0)  costComma = addComma(item.target_cost_value);
    let totalCost;
    totalCost = item.total_cost;

    if(totalCost == null || totalCost == 0 || totalCost == undefined) {
        totalCost = '공고확인'
    }else{
        totalCost = addComma(item.total_cost) +'원'
    }

    if(costComma == undefined || costComma == 0 || costComma == null){
        costComma = '공고확인'
    }





    //관심사업 저장
    const zzimClick = async (idx, mb_save_yn) => {
        let res = await api.getCommonCode(sessionStorage.getItem("corpNm").toLowerCase());
        let corp_cd = res.data.code;
        let headers = {user_id : sessionStorage.getItem("corpId"), corp_cd : corp_cd};
        let data = { mb_addidx: idx, mb_save_yn: mb_save_yn };
        api.getIsSavedMyBook(data, headers).then((res) => {
            getSupportCont(ord, keyword, cate);
           getDoughnutList();
       //     getTotalCount();
        }).catch(err => console.log(err));
    };


    useEffect(() => {
        if(supportItem.spt_cd ) setCateName(supportItem.spt_cd.find((x) => x.code == cateCode).code_nm);

        // console.log('>>>>>>',supportItem.spt_cd)
        // console.log('??',supportItem.spt_cd.code)

    }, [item]);




    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const readDateSource = item.tl_cret_dt;
    const readDay = week[new Date(item.tl_cret_dt).getDay()];
    const endDateSource = new Date(item.si_end_dt).getTime();
    var now = new Date();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).getTime();
    const isEnd = endDateSource - yesterday <= 0;
    const [endDate, endDay] = stringToDate(new Date(item.si_end_dt).getTime(), "MMDD");
    const viewCount = item.view_cnt;
    const isCorpLogin = useSelector((state) => state.isCorpLogIn);


    function stringToDate(stringDate) {
        const dateSource = new Date(stringDate);
        const week = ["일", "월", "화", "수", "목", "금", "토"];
        const month = ("00" + (dateSource.getMonth() + 1)).slice(-2);
        const date = ("00" + dateSource.getDate()).slice(-2);
        const day = week[dateSource.getDay()];
        return [`${month}.${date}`, day];
    }

    function addComma(numb) {
        return numb.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const openInNewTab = (url, idx) => {
        window.open(url, "_blank", "noopener,noreferrer");
        api.updateCorpViewCnt(idx).then((res) => {
            getSupportCont(ord, keyword, cate);
        });
        let data = {corp_nm : sessionStorage.getItem("corpNm"), si_idx :idx}
        api.insertUserLog(data,sessionStorage.getItem("corpId")).then((() =>{
        })).catch(err =>console.log(err));
    };



    return (
        <>
            <li className={styles.SupportItem}>
                <div className={styles.leftArea}>
                    <div className={styles.itemInfo}>
                        <button
                            type="button"
                            onClick={() => {
                                openInNewTab(item.mobile_url, item.si_idx);
                            }}
                        >
                        <h4>{title}</h4>
                        <div className={styles.itemTop} style={block}>
                            {/*{isMobile && (*/}
                            {/*    <p>{readDateSource && `${readDateSource} (${readDay}) 읽음`}</p>*/}
                            {/*)}*/}
                            <ol>
                                <li>{cateName}</li>
                                <li>{locName}</li>
                                <li>{targetName}</li>
                            </ol>
                            {/*{!isMobile && (*/}
                            {/*    <p>{readDateSource && `${readDateSource} (${readDay}) 읽음`}</p>*/}
                            {/*)}*/}

                        </div>
                        <div className={styles.costNwatch}>
                            <section>
                                {!cost && (
                                    <>
                                        {/*<span className={styles.moneyTit}>지원금</span>*/}
                                        {/*<span className={styles.moneyAmount}>공고확인</span>*/}

                                        <span className={styles.moneyTit}>총 사업비</span>
                                        <span style={margin} className={styles.moneyAmount}>{totalCost}</span>

                                        <span className={styles.moneyTit}>기업당 지원금</span>
                                        <span className={styles.moneyAmount}>{costComma}</span>

                                    </>
                                )}
                                {cost > 0 && (
                                    <>
                                        {/*<span className={styles.moneyTit}>지원금</span>*/}
                                        {/*<span className={styles.moneyAmount}>{costComma}원</span>*/}


                                        <span className={styles.moneyTit}>총 사업비</span>
                                        <span style={margin} className={styles.moneyAmount}>{totalCost}</span>

                                        <span className={styles.moneyTit}>기업당 지원금</span>
                                        <span className={styles.moneyAmount}>{costComma}원</span>

                                    </>
                                )}
                            </section>
                            <section>
                                <img
                                    className={styles.eye}
                                    priority="true"
                                    src={require("assets/img/global/ico/ico_view_black.png")}
                                    alt="조회수"
                                />
                                <span>{viewCount}</span>
                            </section>
                        </div>
                        </button>
                    </div>
                    <p style={{color:'#959292',marginTop:'10px'}}>등록일<span style={{marginLeft:'10px'}}>{item.si_cret_dt}</span></p>
                </div>
                <div className={styles.rightArea2}>
                    <ul>
                        <li className={`${styles.dueDate} ` + (isEnd ? styles.end : "")}>
                            {isEnd ? (
                                <span>마감</span>
                            ) : (
                                <>
                                    <img
                                        priority="true"
                                        src={require("assets/img/global/ico/ico_date.png")}
                                        alt="마감일"
                                    />
                                    <span>
                                    {endDate} ({endDay})
                                    </span>
                                </>
                            )}
                        </li>
                        <li
                            className={`${styles.btnZzim} ` + (isZzim && isCorpLogin? styles.isZzim : null)}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    if (!isCorpLogin) {
                                        alert("기업 아이디 정보가 필요합니다.");
                                        return false;
                                    }
                                    zzimClick(item.si_idx, item.mb_save_yn);
                                }}
                            >
                                <img
                                    priority="true"
                                    src={
                                        isZzim && isCorpLogin
                                            ? require("assets/img/global/ico/ico_zzim.png")
                                            : require("assets/img/global/ico/ico_zzim_black.png")
                                    }
                                    alt={`관심사업 저장 : ${isZzim}`}
                                />
                                <span>관심사업 저장</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    );
};
export default H_SupportItem;
