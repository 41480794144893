import React from "react";
import styles from "scss/components/Landing.module.scss";
import Header from "../../components/Header";
import {useSelector} from "react-redux";

const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
};


// const text ={border: "1px solid red" , width:"800px"}
const Landing = ({}) => {
    const isMobile = useSelector((state) => state.isMobile);

    return(
        <>
            <div className={styles.Landing}>
                <div className={styles.LandingInner}>
                    <img
                        src={require("assets/img/Landing/landingImg.png")}
                        alt="landing"
                    />
                </div>
                <div className={styles.ld_btn}>
                    <ul>
                        <li>
                            <button className={styles.goWeb}
                                    onClick={() => {
                                        openInNewTab("https://forms.office.com/r/zg58RRAhry");
                                    }}
                                    type="button"
                            >
                                신청하기
                            </button>
                        </li>
                        <li>
                            <button className={styles.promotion}
                                    onClick={() => {
                                        openInNewTab("https://weccess.com/");
                                    }}
                                    type="button"
                            >
                                위세스 둘러보기
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            {isMobile && (
                <style>
                    {
                        "\
                              #wrap > div[class^='Header']{\
                                display:flex;\
                              }\
                            "
                    }
                </style>
            )}
            </>
            );
        };

export default Landing;